import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { calculateInvoiceTotals } from '../../../utils/invoiceCalculations';
import { InvoiceData } from '../../../types';
import { getContrastColor } from '../../../utils/contrast';

const mobile = (inner: TemplateStringsArray) => css`
  @media (max-width: 768px) {
    ${inner}
  }
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  font-size: 14px;

  ${mobile`
    padding: 12px 16px;
  `}
`;

const InvoiceTotal = styled.div`
  width: fit-content;
  max-width: 302px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-feature-settings: "tnum";
  width: 100%;

  ${mobile`
    margin-top: 32px;
    min-width: 100%;
  `}
`;

const Divider = styled.div<{ $bodyTextColor: string }>`
  width: 100%;
  height: 1px;
  background-color: ${props => props.$bodyTextColor};
  margin: 8px 0;
  opacity: 0.1;
`;

const TotalItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaxLabel = styled.span<{ $isFocused: boolean; $backgroundColor: string; $isPublic?: boolean }>`
  cursor: ${props => props.$isPublic ? 'default' : 'text'};
  padding: 4px 8px;
  margin: -4px -8px;
  border-radius: 8px;
  background: ${props => props.$isFocused ? getContrastColor(props.$backgroundColor) : 'transparent'};
  box-shadow: ${props => props.$isFocused ? `inset 0 0 0 1px ${getContrastColor(props.$backgroundColor)}` : 'none'};
  
  &:hover {
    background: ${props => !props.$isPublic && getContrastColor(props.$backgroundColor)};
  }
`;

const AmountDueItem = styled(TotalItem)`
  font-size: 14px;
  font-weight: 500;
`;

const TaxInput = styled.input<{ $width: number }>`
  width: ${props => props.$width}ch;
  background: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  text-align: right;
  padding: 0;
  margin: 0;
  font-feature-settings: "tnum";
  cursor: text;
  
  &:focus {
    outline: none;
  }

  /* Hide number input spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
`;

interface InvoiceTotalsProps {
  invoice: InvoiceData;
  bodyTextColor: string;
  backgroundColor: string;
  onTaxRateChange?: (newRate: number) => void;
  isPublic?: boolean;
}

const InvoiceTotals: React.FC<InvoiceTotalsProps> = ({ invoice, bodyTextColor, backgroundColor, onTaxRateChange, isPublic = false }) => {
  const [taxRate, setTaxRate] = useState(invoice.tax_rate || 0);
  const [displayValue, setDisplayValue] = useState(invoice.tax_rate?.toString() || '0');
  const [isFocused, setIsFocused] = useState(false);
  
  const totals = useMemo(() => 
    calculateInvoiceTotals({ ...invoice, tax_rate: taxRate }),
    [invoice, taxRate]
  );
  
  const currency = invoice.currency || 'USD';
  const taxInputRef = useRef<HTMLInputElement>(null);
  
  const formatAmount = useMemo(() => (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }, [currency]);

  useEffect(() => {
    const newRate = invoice.tax_rate || 0;
    setTaxRate(newRate);
    setDisplayValue(newRate.toString());
  }, [invoice.tax_rate]);

  const handleTaxRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^\d]/g, '').slice(0, 2);
    
    // Remove leading zeros unless it's just '0'
    if (value.length > 1) {
      value = value.replace(/^0+/, '');
    }
    
    // Handle empty input
    if (value === '') {
      setDisplayValue('0');
      setTaxRate(0);
      onTaxRateChange?.(0);
      return;
    }

    const numericValue = parseInt(value, 10);
    if (numericValue > 99) {
      value = '99';
    }

    setDisplayValue(value);
    setTaxRate(parseInt(value, 10));
    onTaxRateChange?.(parseInt(value, 10));
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Format the number on blur
    if (displayValue === '') {
      setDisplayValue('0');
      setTaxRate(0);
      onTaxRateChange?.(0);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    // Select all text when focused
    taxInputRef.current?.select();
  };

  const inputWidth = displayValue.length || 1;

  const handleTaxItemClick = () => {
    taxInputRef.current?.focus();
  };

  return (
    <TotalContainer>
      <InvoiceTotal>
        <TotalItem>
          <span>Subtotal:</span> 
          <span>{formatAmount(totals.subtotal)}</span>
        </TotalItem>
        {(!isPublic || taxRate > 0) && (
          <TotalItem>
            <TaxLabel onClick={!isPublic ? handleTaxItemClick : undefined} $isFocused={isFocused} $backgroundColor={backgroundColor} $isPublic={isPublic}>Tax (
              <TaxInput
                ref={taxInputRef}
                type="text"
                inputMode="numeric"
                pattern="\d*"
                value={displayValue}
                onChange={handleTaxRateChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                $width={inputWidth}
                readOnly={isPublic}
              />
              %)</TaxLabel>
            <span>{formatAmount(totals.taxAmount)}</span>
          </TotalItem>
        )}
        <TotalItem>
          <span>Total:</span> 
          <span>{formatAmount(totals.total)}</span>
        </TotalItem>
        <TotalItem>
          <span>Total Paid:</span> 
          <span>{formatAmount(totals.totalPaid)}</span>
        </TotalItem>
        <Divider $bodyTextColor={bodyTextColor} />
        <AmountDueItem>
          <span>Amount Due:</span> 
          <span>{formatAmount(totals.amountDue)}</span>
        </AmountDueItem>
      </InvoiceTotal>
    </TotalContainer>
  );
};

export default InvoiceTotals;