import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useInteractions,
  useTransitionStyles,
  Placement,
  FloatingPortal,
} from "@floating-ui/react";
import { ChevronDown8 } from "./Icon";

const ButtonGroup = styled.div`
  display: inline-flex;
  align-items: stretch;
  border-radius: 8px;
  overflow: hidden;
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 6px rgba(0,0,0,0.05);
  transition: transform 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;

  &:hover {
    box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.05);
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 0 0 0.5px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05);
  }
`;

const PrimaryButton = styled.button`
  padding: 6px 12px;
  background-color: transparent;
  color: rgba(0,0,0,0.6);
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.1s ease;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: rgba(0,0,0,0.8);
  }
`;

const DropdownTrigger = styled.button`
  padding: 8px;
  background-color: transparent;
  color: rgba(0,0,0,0.6);
  border: none;
  border-left: 1px solid rgba(0,0,0,0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.1s ease;

  &:hover {
    color: rgba(0,0,0,0.8);
  }

  svg path {
    fill: rgba(0,0,0,0.6);
  }

  &:hover svg path {
    fill: rgba(0,0,0,0.8);
  }
`;

const PopoverContainer = styled.div`
  z-index: 3000;
  pointer-events: auto;
  position: relative;
  isolation: isolate;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 3000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 4px;
  pointer-events: auto;
`;

const Option = styled.div<{ $isHighlighted: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isHighlighted ? "rgba(0, 0, 0, 0.05)" : "transparent"};
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.1s ease;
  font-family: 'Inter', sans-serif;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.8);
  }

  svg path {
    fill: rgba(0, 0, 0, 0.6);
  }

  &:hover svg path {
    fill: rgba(0, 0, 0, 0.8);
  }
`;

interface Option {
  id: string;
  name: string;
  icon?: React.ReactNode;
}

interface SplitButtonProps {
  primaryOption: Option;
  options: Option[];
  onOptionSelect: (optionId: string) => void;
  placement?: Placement;
}

const SplitButton: React.FC<SplitButtonProps> = ({
  primaryOption,
  options,
  onOptionSelect,
  placement = "bottom-end",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      offset(4),
      flip({
        fallbackPlacements: ['top', 'bottom', 'right', 'left'],
      }),
      shift({
        padding: 8,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
        return "bottom center";
      case "top-start":
        return "bottom left";
      case "top-end":
        return "bottom right";
      case "bottom":
        return "top center";
      case "bottom-start":
        return "top left";
      case "bottom-end":
        return "top right";
      case "left":
        return "right center";
      case "left-start":
        return "right top";
      case "left-end":
        return "right bottom";
      case "right":
        return "left center";
      case "right-start":
        return "left top";
      case "right-end":
        return "left bottom";
      default:
        return "top right";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const handlePrimaryClick = useCallback(() => {
    onOptionSelect(primaryOption.id);
  }, [primaryOption.id, onOptionSelect]);

  const handleOptionClick = useCallback((optionId: string) => {
    onOptionSelect(optionId);
    setIsOpen(false);
  }, [onOptionSelect]);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => {
        setIsMounted(false);
      }, 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <ButtonGroup>
      <PrimaryButton onClick={handlePrimaryClick}>
        {primaryOption.icon}
        {primaryOption.name}
      </PrimaryButton>
      <DropdownTrigger
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ChevronDown8 />
      </DropdownTrigger>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 3000,
              position: 'fixed',
            }}
            {...getFloatingProps()}
          >
            <PopoverContent 
              style={styles} 
              $transformOrigin={transformOrigin}
              onClick={(e) => e.stopPropagation()}
            >
              {options.map((option, index) => (
                <Option
                  key={option.id}
                  $isHighlighted={index === highlightedIndex}
                  onClick={() => handleOptionClick(option.id)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  onMouseLeave={() => setHighlightedIndex(-1)}
                >
                  {option.icon}
                  {option.name}
                </Option>
              ))}
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </ButtonGroup>
  );
};

export default SplitButton; 