import React, { useMemo } from 'react';
import { InvoiceData } from '../types';
import DataTable from './DataTable';
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import { StatusBadge } from '../shared/components/StatusBadge';
import { Invoice32 } from '../shared/components/Icon';
import { formatCurrency } from '../utils/formatCurrency';
import { isThisYear, format, differenceInCalendarDays } from 'date-fns';

interface InvoiceListProps {
  invoices: InvoiceData[];
  isLoading: boolean;
  onCreateInvoice: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  onSelectionChange?: (selectedIds: string[]) => void;
  onRowClick: (invoice: InvoiceData) => void;
  selectedInvoiceId: string | null;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

const InvoiceList: React.FC<InvoiceListProps> = ({
  invoices,
  isLoading,
  onCreateInvoice,
  containerRef,
  onSelectionChange,
  onRowClick,
  selectedInvoiceId,
  sorting,
  onSortingChange,
}) => {
  const invoiceColumns: ColumnDef<InvoiceData>[] = useMemo(
    () => [
      {
        accessorKey: 'invoice_number',
        header: 'Invoice Number',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ getValue }) => (
          <StatusBadge $status={getValue() as string}>{getValue() as string}</StatusBadge>
        ),
      },
      {
        accessorKey: 'invoice_date',
        header: 'Invoice Date',
        cell: ({ getValue }) => {
          if (!getValue()) return '–';
          const date = new Date(getValue() as string);
          return format(date, isThisYear(date) ? 'MMM d' : 'MMM d, yyyy');
        },
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue, row }) => {
          if (!getValue()) return '–';
          const dueDate = new Date(getValue() as string);
          const today = new Date();
          const diffDays = differenceInCalendarDays(dueDate, today);
          const formattedDate = format(dueDate, isThisYear(dueDate) ? 'MMM d' : 'MMM d, yyyy');
          
          const status = row.original.status;
          
          let statusText = '';
          if (status !== 'paid') {
            if (diffDays < 0) {
              statusText = `(late ${Math.abs(diffDays)} day${Math.abs(diffDays) !== 1 ? 's' : ''})`;
            } else if (diffDays === 0) {
              statusText = '(today)';
            } else if (diffDays === 1) {
              statusText = '(tomorrow)';
            } else {
              statusText = `(in ${diffDays} day${diffDays !== 1 ? 's' : ''})`;
            }
          }
          
          return (
            <span>
              {formattedDate}{' '}
              {statusText && <span style={{ color: 'rgba(0,0,0,0.5)' }}>{statusText}</span>}
            </span>
          );
        },
      },
      {
        accessorKey: 'amount_due',
        header: 'Amount Due',
        cell: ({ getValue }) => formatCurrency(getValue() as number),
      },
    ],
    []
  );

  const invoiceEmptyState = useMemo(() => ({
    icon: <Invoice32 />,
    message: "No invoices found",
    subMessage: "Create a new invoice to get started",
    action: {
      label: "New Invoice",
      onClick: onCreateInvoice
    }
  }), [onCreateInvoice]);

  return (
    <DataTable<InvoiceData>
      columns={invoiceColumns}
      data={invoices}
      isLoading={isLoading}
      onRowClick={onRowClick}
      getRowKey={(invoice) => invoice.id}
      onSelectionChange={onSelectionChange || (() => {})}
      rowClassName="invoice-row"
      containerRef={containerRef}
      emptyState={invoiceEmptyState}
      selectedRows={selectedInvoiceId ? [selectedInvoiceId] : []}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  );
};

export default InvoiceList;
