import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { useOrganization } from './useOrganization';
import { OrganizationUser } from '../../../types';

interface OrganizationMemberResponse {
  user_id: string;
  role: string;
  is_owner: boolean;
  full_name: string | null;
  email: string | null;
}

export const useOrganizationUsers = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<OrganizationUser[], Error>({
    queryKey: ['organizationUsers', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('No organization ID');
      console.log('Fetching organization users for organization:', organizationId);

      const { data: members, error: membersError } = await supabase
        .rpc('get_organization_members_with_details', {
          org_id: organizationId
        })
        .returns<OrganizationMemberResponse[]>();

      console.log('Organization members query result:', {
        members,
        membersError
      });

      if (membersError) {
        console.error('Error fetching organization members:', membersError);
        throw membersError;
      }

      if (!members || members.length === 0) {
        console.warn('No members found for organization:', organizationId);
        return [];
      }

      // Map the data
      const result = members.map(member => ({
        id: member.user_id,
        full_name: member.full_name ?? 'N/A',
        email: member.email ?? 'N/A',
        role: member.role,
        is_owner: member.is_owner
      }));

      console.log('Final organization users result:', result);
      return result;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
};
