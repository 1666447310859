import React from 'react';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { Client } from '../../../types';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
`;

const Title = styled.h2`
  margin-top: 0;
`;

const ClientList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ClientItem = styled.li`
  padding: 8px 0;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

interface SelectClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  clients: Client[];
  onClientSelect: (clientId: string) => void;
}

const SelectClientModal: React.FC<SelectClientModalProps> = ({
  isOpen,
  onClose,
  clients,
  onClientSelect,
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Select Client for Invoice</Title>
        <p>The selected time entries are for different clients. Please select a single client for this invoice:</p>
        <ClientList>
          {clients.map((client) => (
            <ClientItem key={client.id} onClick={() => onClientSelect(client.id)}>
              {client.full_name}
            </ClientItem>
          ))}
        </ClientList>
        <Button buttonType="secondary" onClick={onClose}>Cancel</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SelectClientModal;