import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { useOrganization } from '../../../hooks';
import { Client } from '../../../types';

// Query key factory for consistent keys across the application
export const clientKeys = {
  all: (organizationId: string | undefined) => ['clients', organizationId] as const,
  single: (id: string) => ['client', id] as const,
};

export const useClients = () => {
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();

  const clientsQuery = useQuery<Client[], Error>({
    queryKey: clientKeys.all(organizationId),
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId)
        .order('full_name', { ascending: true });
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const createClientMutation = useMutation<Client, Error, Omit<Client, 'id' | 'organization_id'>>({
    mutationFn: async (newClient) => {
      if (!organizationId) throw new Error('Organization ID is not available');
      const { data, error } = await supabase
        .from('clients')
        .insert({ ...newClient, organization_id: organizationId })
        .select()
        .single();
      if (error) throw error;
      if (!data) throw new Error('No data returned from the server');
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clientKeys.all(organizationId) });
    },
  });

  const updateClientMutation = useMutation<Client, Error, Partial<Client> & { id: string }>({
    mutationFn: async (updatedClient) => {
      if (!organizationId) throw new Error('Organization ID is not available');
      const { data, error } = await supabase
        .from('clients')
        .update(updatedClient)
        .eq('id', updatedClient.id)
        .eq('organization_id', organizationId)
        .select()
        .single();
      if (error) throw error;
      if (!data) throw new Error('No data returned from the server');
      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: clientKeys.all(organizationId) });
      queryClient.invalidateQueries({ queryKey: clientKeys.single(data.id) });
    },
  });

  const deleteClientMutation = useMutation<void, Error, string>({
    mutationFn: async (clientId) => {
      if (!organizationId) throw new Error('Organization ID is not available');
      const { error } = await supabase
        .from('clients')
        .delete()
        .eq('id', clientId)
        .eq('organization_id', organizationId);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clientKeys.all(organizationId) });
    },
  });

  return {
    ...clientsQuery,
    createClient: createClientMutation,
    updateClient: updateClientMutation,
    deleteClient: deleteClientMutation,
  };
};

// Hook for single client operations
export const useClient = (id: string) => {
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();

  const clientQuery = useQuery<Client, Error>({
    queryKey: clientKeys.single(id),
    queryFn: async () => {
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('id', id)
        .single();
      if (error) throw error;
      return data;
    },
    initialData: () => {
      const clients = queryClient.getQueryData<Client[]>(clientKeys.all(organizationId));
      return clients?.find((c) => c.id === id);
    },
  });

  return clientQuery;
};