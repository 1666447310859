import React from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8, Client12, Project12, Service12 } from '../../../../shared/components/Icon';
import { ButtonGroup, LogTimeButton } from './TimeTrackerStyles';
import styled from 'styled-components';
import EntityPicker from '../../../../shared/components/EntityPicker';
import Switch from '../../../../shared/components/Switch';
import LayoutPicker from './LayoutPicker';
import { Client, Project, Service, Entity, TimeTrackerStyle} from '../../../../types';
import Button from '../../../../shared/components/Button';
import NumberFlow from '@number-flow/react';

const PaperWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 12px;
  height: 420px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #F8F7F6;
  background: radial-gradient(136.45% 136.45% at 29.75% 131.87%, #FF6434 0%, #FCCDA1 49.52%, #98A0C4 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledLayoutPicker = styled(LayoutPicker)`
  margin-right: auto;
  opacity: 0.7;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    padding: 16px 24px;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(51, 51, 51, 0.5);
      pointer-events: none;
      height: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  flex-direction: row;
  padding: 40px 20px 0 20px;
  box-sizing: border-box;
  justify-content: center;
  font-family: 'PP Mori';
`;

const TimeInputField = styled.input<{ isEditable: boolean }>`
  width: 72px;
  height: 56px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 8px;
  text-align: center;
  font-size: 56px;
  line-height: 56px;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'PP Mori';
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: 'tnum';
  -webkit-font-smoothing: antialiased;
  border-radius: 8px;
  font-variant-numeric: tabular-nums;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  pointer-events: ${props => props.isEditable ? 'auto' : 'none'};
`;

const NumberFlowStyled = styled(NumberFlow)`
  width: 72px;
  height: 56px;
  border: none;
  padding: 0;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  line-height: 56px;
  border-radius: 8px;
  font-size: 56px;
  font-family: 'PP Mori';
  text-align: center;
  font-variant-numeric: tabular-nums;
  outline: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.7);
  --number-flow-mask-height: 0.1em;
  --number-flow-char-height: 1em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  text-align: center;
`;

const TimeInputLabel = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 8px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
`;

const PickerLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillableToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
`;

const Colon = styled.span`
  font-size: 32px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 56px;
  padding: 0 0 28px 0;
`;

// Use the same TimeTrackerLayoutProps interface as EveningTimeTracker
interface TimeTrackerLayoutProps {
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (checked: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  handleClientChange: (id: string | null) => void;
  handleProjectChange: (id: string | null) => void;
  handleServiceChange: (id: string | null) => void;
  handleResume: () => void;
  timeTrackerStyle: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
}

const SunriseTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  isBillable,
  handleBillableToggle,
  isRunning,
  isPaused,
  handleStart,
  handlePause,
  handleLogTime,
  handleDelete,
  clients,
  projects,
  services,
  selectedClientId,
  selectedProjectId,
  selectedServiceId,
  handleClientChange,
  handleProjectChange,
  handleServiceChange,
  handleResume,
  timeTrackerStyle,
  onLayoutChange,
}) => {
  const mapToEntity = (item: Client | Project | Service): Entity => ({
    id: item.id,
    name: 'name' in item 
      ? (item.name || 'Untitled Project')
      : (item.full_name || 'Untitled Client')
  });

  const clientEntities = clients.map(mapToEntity);
  const projectEntities = projects.map(mapToEntity);
  const serviceEntities = services.map(mapToEntity);

  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds', index: number) => {
    const value = parseInt(manualTime.split(':')[index], 10);
    const isEditable = !isRunning;
    
    if (isRunning) {
      return (
        <NumberFlowStyled
          value={value}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 500, easing: 'ease-out' }}
          spinTiming={{ duration: 500, easing: 'ease-out' }}
          opacityTiming={{ duration: 500, easing: 'ease-out' }}
        />
      );
    } else {
      return (
        <TimeInputField
          type="number"
          value={value.toString().padStart(2, '0')}
          onChange={(e) => isEditable && handleManualTimeChange(field, e.target.value)}
          min={0}
          max={field === 'hours' ? 23 : 59}
          readOnly={!isEditable}
          isEditable={isEditable}
        />
      );
    }
  };

  return (
    <PaperWrapper>
      <ContentWrapper>
        <TimeInputWrapper>
          <Column>
            {renderTimeInput('hours', 0)}
            <TimeInputLabel>Hrs</TimeInputLabel>
          </Column>
          <Colon>:</Colon>
          <Column>
            {renderTimeInput('minutes', 1)}
            <TimeInputLabel>Mins</TimeInputLabel>
          </Column>
          <Colon>:</Colon>
          <Column>
            {renderTimeInput('seconds', 2)}
            <TimeInputLabel>Secs</TimeInputLabel>
          </Column>
        </TimeInputWrapper>
        <PickerContainer>
          <PickerLeft>
            <EntityPicker
              selectedId={selectedClientId}
              onChange={handleClientChange}
              entities={clientEntities}
              label="Client"
              allowUnassigned={true}
              icon={<Client12 />}
            />
            <EntityPicker
              selectedId={selectedProjectId}
              onChange={handleProjectChange}
              entities={projectEntities}
              label="Project"
              allowUnassigned={true}
              icon={<Project12 />}
            />
            <EntityPicker
              selectedId={selectedServiceId}
              onChange={handleServiceChange}
              entities={serviceEntities}
              label="Service"
              allowUnassigned={true}
              icon={<Service12 />}
            />
          </PickerLeft>
          <PickerRight>
            <BillableToggleWrapper>
              <Switch
                checked={isBillable}
                onChange={handleBillableToggle}
                label="Billable"

              />
            </BillableToggleWrapper>
          </PickerRight>
        </PickerContainer>
        <EditorWrapper>
          <EditorContent editor={editor} />
        </EditorWrapper>
        <ButtonGroup>
          {!isRunning ? (
            <LogTimeButton onClick={isPaused ? handleResume : handleStart}>
              <Play8/> {isPaused ? 'Resume' : 'Start'}
            </LogTimeButton>
          ) : (
            <LogTimeButton onClick={handlePause}>
              <Pause8/> Pause
            </LogTimeButton>
          )}
          {(isRunning || isPaused) && (
            <LogTimeButton onClick={handleLogTime}>
              Log Time
            </LogTimeButton>
          )}
          {(isRunning || isPaused) && (
            <Button buttonType='icon' onClick={handleDelete}><Trash12 /></Button>
          )}
          <StyledLayoutPicker
            selectedLayout={timeTrackerStyle}
            onLayoutChange={onLayoutChange}

          />
        </ButtonGroup>
      </ContentWrapper>
    </PaperWrapper>
  );
};

export default SunriseTimeTracker;
