import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Client, Project, Service, TimeTrackerStyle, TIME_TRACKER_TO_ENTRY_LAYOUT } from '../../../types';
import { Expand12 } from '../../../shared/components/Icon';
import { useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import DefaultTimeTracker from './TimeTrackerLayouts/DefaultTimeTracker';
import ModernTimeTracker from './TimeTrackerLayouts/ModernTimeTracker';
import EveningTimeTracker from './TimeTrackerLayouts/EveningTimeTracker';
import RetroTimeTracker from './TimeTrackerLayouts/RetroTimeTracker';
import SunriseTimeTracker from './TimeTrackerLayouts/SunriseTimeTracker';
import { useQueryClient } from '@tanstack/react-query';
import { useOrganization } from '../../../hooks';
import NumberFlow from '@number-flow/react';

const TimeTrackerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledExpandIcon = styled(Expand12)`
  width: 10px;
  height: 10px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const AnimatedTimeDisplay = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 1em;
  line-height: 1em;
  justify-content: center;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 4px 4px 4px;
  border-radius: 6px;
  color: inherit;
  user-select: none;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);

    ${StyledExpandIcon} {
      path {
        fill: rgba(0, 0, 0, 0.8);
      }
    }
  }
`;

const NumberFlowStyled = styled(NumberFlow)`
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  color: inherit;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  --number-flow-mask-height: 0.2em;
  --number-flow-char-height: 1.25em;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: top right;
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 0px;
  width: 400px;
`;

interface TimeTrackerPopoverProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  elapsedTime: number;
  startTimer: (clientId: string | null, projectId: string | null, serviceId: string | null, description: string, initialElapsedTime?: number, isBillable?: boolean) => void;
  stopTimer: () => void;
  updateTimer: (clientId: string | null, projectId: string | null, serviceId: string | null, description: string, manualSeconds?: number, newIsBillable?: boolean) => void;
  cancelTimer: () => void;
  deleteTimeEntry: () => Promise<void>;
  pauseTimer: () => void;
  resumeTimer: () => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  description: string;
  setDescription: (description: string) => void;
  isBillable: boolean;
  setIsBillable: (isBillable: boolean) => void;
  currentTimeEntryId: string | null;
  resetState: () => void; // Add this prop
}

const TimeTrackerPopover: React.FC<TimeTrackerPopoverProps> = ({
  isOpen,
  setIsOpen,
  isRunning,
  isPaused,
  elapsedTime,
  startTimer,
  stopTimer,
  updateTimer,
  cancelTimer,
  deleteTimeEntry,
  pauseTimer,
  resumeTimer,
  clients,
  projects,
  services,
  description,
  setDescription,
  isBillable,
  setIsBillable,
  currentTimeEntryId,
  resetState, // Add this
}) => {
  // Combine related state variables into a single object
  const [timeState, setTimeState] = useState({
    selectedClientId: null as string | null,
    selectedProjectId: null as string | null,
    selectedServiceId: null as string | null,
    manualTime: "00:00:00",
    localElapsedTime: elapsedTime,
  });

  const [isMounted, setIsMounted] = useState(false);
  const [localDescription, setLocalDescription] = useState(description);
  const [timeTrackerStyle, setTimeTrackerStyle] = useState<TimeTrackerStyle>(() => {
    const savedStyle = localStorage.getItem('timeTrackerStyle') as TimeTrackerStyle | null;
    return savedStyle || 'modern';
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  // Memoize the editor configuration
  const editorConfig = useMemo(() => ({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Description...',
      }),
    ],
    content: localDescription,
    onUpdate: ({ editor }: { editor: Editor }) => {
      const newDescription = editor.getText();
      setLocalDescription(newDescription);
    },
  }), [localDescription]);

  const editor = useEditor(editorConfig);

  // Memoize the floating UI configuration
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  // Memoize the transform origin calculation
  const transformOrigin = useMemo(() => getTransformOrigin(context.placement), [context.placement]);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const click = useClick(context, {
    toggle: true,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  // Optimize useEffect dependencies
  useEffect(() => {
    setTimeState(prev => ({ ...prev, localElapsedTime: elapsedTime }));
  }, [elapsedTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning && !isPaused) {
      interval = setInterval(() => {
        setTimeState(prev => ({ ...prev, localElapsedTime: prev.localElapsedTime + 1 }));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, isPaused]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning && !isPaused) {
      interval = setInterval(() => {
        updateTimer(
          timeState.selectedClientId,
          timeState.selectedProjectId,
          timeState.selectedServiceId,
          description,
          timeState.localElapsedTime
        );
      }, 5000); // Update every 5 seconds
    }
    return () => clearInterval(interval);
  }, [isRunning, isPaused, updateTimer, timeState, description]);

  useEffect(() => {
    const hours = Math.floor(timeState.localElapsedTime / 3600);
    const minutes = Math.floor((timeState.localElapsedTime % 3600) / 60);
    const seconds = timeState.localElapsedTime % 60;
    setTimeState(prev => ({
      ...prev,
      manualTime: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }));
  }, [timeState.localElapsedTime]);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150); // Delay unmounting
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  // Use useCallback for handlers
  const handleClientChange = useCallback(async (newClientId: string | null) => {
    setTimeState(prev => ({ ...prev, selectedClientId: newClientId }));
    if (isRunning) {
      await updateTimer(newClientId, timeState.selectedProjectId, timeState.selectedServiceId, description);
    }
  }, [isRunning, updateTimer, timeState, description]);

  const handleProjectChange = useCallback(async (newProjectId: string | null) => {
    setTimeState(prev => ({ ...prev, selectedProjectId: newProjectId }));
    if (isRunning) {
      await updateTimer(timeState.selectedClientId, newProjectId, timeState.selectedServiceId, description);
    }
  }, [isRunning, updateTimer, timeState, description]);

  const handleServiceChange = useCallback(async (newServiceId: string | null) => {
    setTimeState(prev => ({ ...prev, selectedServiceId: newServiceId }));
    if (isRunning && newServiceId) {
      const selectedService = services.find(service => service.id === newServiceId);
      if (selectedService) {
        await updateTimer(timeState.selectedClientId, timeState.selectedProjectId, newServiceId, selectedService.name);
        setDescription(selectedService.name);
      }
    }
  }, [isRunning, services, updateTimer, timeState, setDescription]);

  const handleManualTimeChange = useCallback((field: 'hours' | 'minutes' | 'seconds', value: string) => {
    const numValue = parseInt(value, 10);
    const [hours, minutes, seconds] = timeState.manualTime.split(':').map(Number);
    
    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    switch (field) {
      case 'hours':
        newHours = isNaN(numValue) ? 0 : Math.min(Math.max(numValue, 0), 23);
        break;
      case 'minutes':
      case 'seconds':
        {
          const newValue = isNaN(numValue) ? 0 : Math.min(Math.max(numValue, 0), 59);
          if (field === 'minutes') newMinutes = newValue;
          else newSeconds = newValue;
        }
        break;
    }

    const newManualTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
    setTimeState(prev => ({
      ...prev,
      manualTime: newManualTime,
      localElapsedTime: newHours * 3600 + newMinutes * 60 + newSeconds
    }));
    
    const totalSeconds = newHours * 3600 + newMinutes * 60 + newSeconds;
    updateTimer(timeState.selectedClientId, timeState.selectedProjectId, timeState.selectedServiceId, description, totalSeconds);
  }, [timeState, updateTimer, description]);

  const handleBillableToggle = useCallback((newIsBillable: boolean) => {
    setIsBillable(newIsBillable);
    if (isRunning) {
      updateTimer(timeState.selectedClientId, timeState.selectedProjectId, timeState.selectedServiceId, description, undefined, newIsBillable);
    }
  }, [isRunning, updateTimer, timeState, description, setIsBillable]);

  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const handleStart = useCallback(() => {
    if (isPaused && currentTimeEntryId) {
      // Resume the paused timer
      resumeTimer();
    } else {
      // Start a new timer
      const [hours, minutes, seconds] = timeState.manualTime.split(':').map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      startTimer(timeState.selectedClientId, timeState.selectedProjectId, timeState.selectedServiceId, '', totalSeconds, isBillable);
      
      // Clear the description
      setLocalDescription('');
      setDescription('');
      if (editor) {
        editor.commands.setContent('');
      }
    }
    setIsOpen(false);
    
    if (organizationId) {
      queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
    }
  }, [
    isPaused, currentTimeEntryId, resumeTimer, timeState, startTimer,
    isBillable, setIsOpen, organizationId, queryClient, setDescription,
    editor, setLocalDescription
  ]);

  const handlePause = useCallback(() => {
    pauseTimer();
  }, [pauseTimer]);

  const handleResume = useCallback(() => {
    resumeTimer();
  }, [resumeTimer]);

  const handleLogTime = useCallback(() => {
    updateTimer(timeState.selectedClientId, timeState.selectedProjectId, timeState.selectedServiceId, localDescription);
    setDescription('');
    stopTimer();
    setIsOpen(false);
    resetState();
    
    // Reset local state
    setTimeState(prev => ({
      ...prev,
      localElapsedTime: 0,
      selectedClientId: null,
      selectedProjectId: null,
      selectedServiceId: null,
      manualTime: '00:00:00'
    }));
    
    setLocalDescription('');
    if (editor) {
      editor.commands.setContent('');
    }
  }, [
    updateTimer, timeState, localDescription,
    setDescription, stopTimer, setIsOpen, resetState, editor,
    setTimeState
  ]);

  const handleDelete = useCallback(async () => {
    await deleteTimeEntry();
    cancelTimer();
    setIsOpen(false);
  }, [deleteTimeEntry, cancelTimer, setIsOpen]);

  // Memoize the renderAnimatedTime function
  const renderAnimatedTime = useCallback((time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return (
      <>
        <NumberFlowStyled
          value={hours}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 400, easing: 'ease-out' }}
          spinTiming={{ duration: 400, easing: 'ease-out' }}
          opacityTiming={{ duration: 400, easing: 'ease-out' }}
        />
        :
        <NumberFlowStyled
          value={minutes}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 400, easing: 'ease-out' }}
          spinTiming={{ duration: 400, easing: 'ease-out' }}
          opacityTiming={{ duration: 400, easing: 'ease-out' }}
        />
        :
        <NumberFlowStyled
          value={seconds}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 400, easing: 'ease-out' }}
          spinTiming={{ duration: 400, easing: 'ease-out' }}
          opacityTiming={{ duration: 400, easing: 'ease-out' }}
        />
      </>
    );
  }, []);

  const handleLayoutChange = useCallback((newLayout: TimeTrackerStyle) => {
    setTimeTrackerStyle(newLayout);
    localStorage.setItem('timeTrackerStyle', newLayout);
    // Also save the corresponding time entry layout
    const timeEntryLayout = TIME_TRACKER_TO_ENTRY_LAYOUT[newLayout];
    localStorage.setItem('timeEntryLayout', timeEntryLayout);
    // Dispatch a custom event to notify the drawer
    window.dispatchEvent(new CustomEvent('timeTrackerLayoutChange', { 
      detail: { timeEntryLayout } 
    }));
  }, []);

  // Listen for changes from the drawer
  useEffect(() => {
    const handleDrawerLayoutChange = (event: CustomEvent<{ timeTrackerStyle: TimeTrackerStyle }>) => {
      setTimeTrackerStyle(event.detail.timeTrackerStyle);
      localStorage.setItem('timeTrackerStyle', event.detail.timeTrackerStyle);
    };

    window.addEventListener('timeEntryLayoutChange', handleDrawerLayoutChange as EventListener);
    return () => {
      window.removeEventListener('timeEntryLayoutChange', handleDrawerLayoutChange as EventListener);
    };
  }, []);

  // Memoize the common props for child components
  const commonProps = useMemo(() => ({
    selectedClientId: timeState.selectedClientId,
    selectedProjectId: timeState.selectedProjectId,
    selectedServiceId: timeState.selectedServiceId,
    handleClientChange,
    handleProjectChange,
    handleServiceChange,
    clients,
    projects,
    services,
    manualTime: timeState.manualTime,
    handleManualTimeChange,
    editor,
    isBillable,
    handleBillableToggle,
    isRunning,
    isPaused,
    handleStart,
    handlePause,
    handleResume,
    handleLogTime,
    handleDelete,
    elapsedTime: timeState.localElapsedTime,
    timeTrackerStyle,
    onLayoutChange: handleLayoutChange,
  }), [
    timeState,
    handleClientChange, handleProjectChange, handleServiceChange,
    clients, projects, services, timeState.manualTime, handleManualTimeChange,
    editor, isBillable, handleBillableToggle, isRunning, isPaused,
    handleStart, handlePause, handleResume, handleLogTime, handleDelete,
    timeState.localElapsedTime, timeTrackerStyle, handleLayoutChange
  ]);

  // Memoize the renderTimeTracker function
  const renderTimeTracker = useCallback(() => {
    switch (timeTrackerStyle) {
      case 'modern':
        return <ModernTimeTracker {...commonProps} />;
      case 'evening':
        return <EveningTimeTracker {...commonProps} />;
      case 'retro':
        return <RetroTimeTracker {...commonProps} />;
      case 'sunrise':
        return <SunriseTimeTracker {...commonProps} />;
      default:
        return <DefaultTimeTracker {...commonProps} />;
    }
  }, [commonProps, timeTrackerStyle]);

  return (
    <TimeTrackerWrapper>
      <AnimatedTimeDisplay
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {renderAnimatedTime(timeState.localElapsedTime)}
        <StyledExpandIcon />
      </AnimatedTimeDisplay>
      <FloatingPortal>
        {isMounted && (
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              {renderTimeTracker()}
            </PopoverContent>
          </div>
        )}
      </FloatingPortal>
    
    </TimeTrackerWrapper>
  );
};

export default React.memo(TimeTrackerPopover);
