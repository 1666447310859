import { z } from 'zod';
import { countryEnum, getPostalCodeValidation } from './client';

export const organizationSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  business_name: z.string().nullable().optional(),
  business_email: z.string().email('Invalid email address').nullable().optional(),
  logo_url: z.string().nullable().optional(),
  address_line1: z.string().nullable().optional(),
  address_line2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  postal_code: z.string().nullable().optional(),
  country: countryEnum.nullable().optional(),
  enable_email_tracking: z.boolean().default(true).optional(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  default_payment_terms: z.string().nullable().optional(),
  stripe_account_id: z.string().nullable().optional(),
  stripe_account_verified: z.boolean().optional(),
}).superRefine((data, ctx) => {
  // Only validate postal code if both postal code and country are provided
  if (data.postal_code && data.country) {
    const validation = getPostalCodeValidation(data.country);
    
    // Make the postal code validation less strict by only validating if it's a non-empty string
    if (data.postal_code.trim() !== '') {
      if (!validation.pattern.test(data.postal_code)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: validation.message,
          path: ['postal_code']
        });
      }
    }
  }
}); 