import React from 'react';
import EntityPicker from '../../../../shared/components/EntityPicker';
import { Brush12 } from '../../../../shared/components/Icon';
import { TimeTrackerStyle } from '../../../../types';
import styled from 'styled-components';

const StyledIcon = styled.div<{ dark?: boolean }>`
  svg path {
    fill: ${props => props.dark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
  }
`;

interface LayoutPickerProps {
  selectedLayout: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
  dark?: boolean;
}

// Update the layouts array to match the TimeTrackerStyle type
const layouts: { id: TimeTrackerStyle; name: string }[] = [
  { id: 'default', name: 'Default' },
  { id: 'modern', name: 'Modern' },
  { id: 'sunrise', name: 'Sunrise' },
  { id: 'evening', name: 'Evening' },
  { id: 'retro', name: 'Retro' },
];

const LayoutPicker: React.FC<LayoutPickerProps> = ({ selectedLayout, onLayoutChange, dark }) => {
  return (
    <EntityPicker
      selectedId={selectedLayout}
      onChange={(id) => onLayoutChange(id as TimeTrackerStyle)}
      entities={layouts}
      label="Layout"
      icon={<StyledIcon dark={dark}><Brush12 /></StyledIcon>}
      placement="top-end"
      iconOnly
      enableSearch={false}
      dark={dark}
    />
  );
};

export default LayoutPicker;
