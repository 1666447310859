export interface Client {
  id: string;
  organization_id: string;
  full_name: string;
  email: string | null;
  phone: string | null;
  company: string | null;
  address_line1: string | null;
  address_line2: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
  notes: string | null;
  default_currency: string;
  is_active: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface Invoice {
  id: string;
  public_id: string;
  client_id: string;
  status: 'draft' | 'unpaid' | 'paid';
  invoice_date: string;
  due_date: string;
  amount_due: number;
  project_id?: string;
  invoice_number: string;
  subtotal: number;
  tax_rate: number;
  total: number;
  currency?: string;
  invoice_created_at: string;
  payment_terms?: string;
}

export type InvoiceTemplate = 
  | "structure" 
  | "detailed" 
  | "modern" 
  | "boxed" 
  | "gradient" 
  | "minimalist" 
  | "split" 
  | "circular" 
  | "compact";

export interface InvoiceData {
  id: string;
  invoice_number: string;
  subtotal: number;
  tax_rate: number;
  amount_due: number;
  total: number;
  invoice_date: string | null;
  due_date: string;
  payment_date?: string;
  items: InvoiceItem[];
  invoice_template: InvoiceTemplate;
  header_color: string;
  header_text_color: string;
  background_color?: string;
  body_text_color?: string;
  public_id: string;
  client_id?: string | null;
  payments: Payment[];
  font: string;
  status: InvoiceStatus;
  due_days: number | null;
  notes?: string;
  currency: string;
  project_id?: string;
  organization_id?: string;
  business_name?: string;
  logo_url?: string | null;
  invoice_created_at: string;
  payment_terms?: string;
  organization?: {
    business_name?: string;
    name?: string;
    address_line1?: string;
    address_line2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
    logo_url?: string;
  };
  client?: {
    name?: string;
    full_name?: string;
    company?: string;
    address_line1?: string;
    address_line2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
  show_logo?: boolean;
  collect_stripe_payments?: boolean;
  auto_color_text?: boolean;
}

export interface Payment {
  id: string;
  invoice_id: string;
  amount: number;
  payment_date: string;
  notes?: string;
  user_id?: string;
  organization_id: string;
  payment_method?: string;
  currency?: string;
}

export interface InvoiceItem {
  id: string;
  invoice_id: string;
  description: string;
  quantity: number;
  price: number;
  taxable: boolean;
  created_at?: string;
  order: number;
  invoice?: Invoice;
}

export interface Service {
  id: string;
  name: string;
  description?: string;
  price: number;
  taxable: boolean;
  created_at: string;
}

export interface Project {
  id: string;
  name: string | null;
  description?: string;
  client_id: string | null;
  created_at: string;
  is_active: boolean;
  user_id: string;
  status?: string | null | undefined;
  due_date: string | null | undefined;
  invoices?: Array<{ 
    id: string; 
    amount_due: number; 
    payments: Array<{ id: string; amount: number; payment_date: string }>
  }>;
  expenses?: Array<{ id: string; amount: number }>;
  organization_id: string;
}

export interface Tax {
  id?: string;
  expense_id?: string;
  name: string;
  amount: number;
}

export interface Expense {
  id: string;
  user_id: string;
  organization_id: string | null;
  client_id: string | null;
  project_id: string | null;
  invoice_id: string | null;
  date: string;
  merchant: string | null;
  description: string;
  category: string | null;
  amount: number;
  receipt_image_path: string | null;
  vendor?: string | null;
  created_at: string | null;
  updated_at: string | null;
  client?: Client;
  project?: Project;
  expense_invoice_items?: ExpenseInvoiceItem[];
  taxes?: Tax[];
}

export type ExpenseCategory = `${keyof typeof EXPENSE_CATEGORIES}:${ExpenseSubcategory[keyof ExpenseSubcategory]}`;

export interface ExpenseInvoiceItem {
  id: string;
  expense_id: string | null;
  invoice_item_id: string | null;
  amount: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  invoice_item?: {
    invoice_id: string;
    invoice?: {
      id: string;
      invoice_number: string;
    };
  };
}

export type ExpenseSubcategory = {
  'Office Expenses': 
    | 'Office Supplies'
    | 'Postage and Shipping'
    | 'Printing and Stationery'
    | 'Software and Technology'
    | 'Other Office Expenses';
  'Travel and Transportation':
    | 'Airfare'
    | 'Lodging'
    | 'Car Rental'
    | 'Mileage Reimbursement'
    | 'Parking and Tolls'
    | 'Public Transportation'
    | 'Other Travel Expenses';
  'Meals and Entertainment':
    | 'Business Meals'
    | 'Employee Meals'
    | 'Client Entertainment'
    | 'Other Meal Expenses';
  'Rent and Utilities':
    | 'Office Rent'
    | 'Storage Rent'
    | 'Electricity'
    | 'Water'
    | 'Gas'
    | 'Internet and Phone'
    | 'Other Utilities';
  'Professional Services':
    | 'Legal Fees'
    | 'Accounting Fees'
    | 'Consulting Fees'
    | 'Contractor Payments'
    | 'Other Professional Services';
  'Marketing and Advertising':
    | 'Online Advertising'
    | 'Print Advertising'
    | 'Social Media Marketing'
    | 'Trade Shows and Events'
    | 'Other Marketing Expenses';
  'Equipment and Supplies':
    | 'Computer Hardware'
    | 'Office Furniture'
    | 'Machinery and Equipment'
    | 'Vehicle Expenses'
    | 'Other Equipment Expenses';
  'Employee-Related Expenses':
    | 'Salaries and Wages'
    | 'Employee Benefits'
    | 'Payroll Taxes'
    | 'Retirement Contributions'
    | 'Workers Compensation'
    | 'Other Employee Expenses';
  'Insurance':
    | 'General Liability Insurance'
    | 'Professional Liability Insurance'
    | 'Property Insurance'
    | 'Vehicle Insurance'
    | 'Health Insurance'
    | 'Other Insurance';
  'Taxes and Licenses':
    | 'Business Licenses'
    | 'Property Taxes'
    | 'Sales Tax'
    | 'Other Taxes and Licenses';
  'Interest and Bank Charges':
    | 'Loan Interest'
    | 'Credit Card Interest'
    | 'Bank Fees'
    | 'Merchant Fees'
    | 'Other Financial Charges';
  'Maintenance and Repairs':
    | 'Building Maintenance'
    | 'Equipment Repairs'
    | 'Vehicle Maintenance'
    | 'Other Maintenance Expenses';
  'Subscriptions and Memberships':
    | 'Professional Memberships'
    | 'Publication Subscriptions'
    | 'Software Subscriptions'
    | 'Other Subscriptions';
  'Training and Education':
    | 'Conference Fees'
    | 'Course Tuition'
    | 'Books and Materials'
    | 'Other Training Expenses';
  'Miscellaneous':
    | 'Charitable Contributions'
    | 'Gifts'
    | 'Penalties and Fines'
    | 'Other Miscellaneous Expenses';
};

export const EXPENSE_CATEGORIES: Record<string, string[]> = {
  'Office Expenses': [
    'Office Supplies',
    'Postage and Shipping',
    'Printing and Stationery',
    'Software and Technology',
    'Other Office Expenses'
  ],
  'Travel and Transportation': [
    'Airfare',
    'Lodging',
    'Car Rental',
    'Mileage Reimbursement',
    'Parking and Tolls',
    'Public Transportation',
    'Other Travel Expenses'
  ],
  'Meals and Entertainment': [
    'Business Meals',
    'Employee Meals',
    'Client Entertainment',
    'Other Meal Expenses'
  ],
  'Rent and Utilities': [
    'Office Rent',
    'Storage Rent',
    'Electricity',
    'Water',
    'Gas',
    'Internet and Phone',
    'Other Utilities'
  ],
  'Professional Services': [
    'Legal Fees',
    'Accounting Fees',
    'Consulting Fees',
    'Contractor Payments',
    'Other Professional Services'
  ],
  'Marketing and Advertising': [
    'Online Advertising',
    'Print Advertising',
    'Social Media Marketing',
    'Trade Shows and Events',
    'Other Marketing Expenses'
  ],
  'Equipment and Supplies': [
    'Computer Hardware',
    'Office Furniture',
    'Machinery and Equipment',
    'Vehicle Expenses',
    'Other Equipment Expenses'
  ],
  'Employee-Related Expenses': [
    'Salaries and Wages',
    'Employee Benefits',
    'Payroll Taxes',
    'Retirement Contributions',
    'Workers Compensation',
    'Other Employee Expenses'
  ],
  'Insurance': [
    'General Liability Insurance',
    'Professional Liability Insurance',
    'Property Insurance',
    'Vehicle Insurance',
    'Health Insurance',
    'Other Insurance'
  ],
  'Taxes and Licenses': [
    'Business Licenses',
    'Property Taxes',
    'Sales Tax',
    'Other Taxes and Licenses'
  ],
  'Interest and Bank Charges': [
    'Loan Interest',
    'Credit Card Interest',
    'Bank Fees',
    'Merchant Fees',
    'Other Financial Charges'
  ],
  'Maintenance and Repairs': [
    'Building Maintenance',
    'Equipment Repairs',
    'Vehicle Maintenance',
    'Other Maintenance Expenses'
  ],
  'Subscriptions and Memberships': [
    'Professional Memberships',
    'Publication Subscriptions',
    'Software Subscriptions',
    'Other Subscriptions'
  ],
  'Training and Education': [
    'Conference Fees',
    'Course Tuition',
    'Books and Materials',
    'Other Training Expenses'
  ],
  'Miscellaneous': [
    'Charitable Contributions',
    'Gifts',
    'Penalties and Fines',
    'Other Miscellaneous Expenses'
  ]
};

export interface TaskStatus {
  id: string;
  name: string;
  color: string;
  order_number: number;
  organization_id: string;
  category: 'Todo' | 'In Progress' | 'Complete';
}

export interface Task {
  id: string;
  description: string;
  is_completed: boolean;
  due_date: string | null;
  notes: string | null;
  client_id: string | null;
  project_id: string | null;
  user_id: string | null;
  organization_id: string;
  status_id: string;
  created_at: string;
  updated_at: string;
  position: number;
  client?: {
    id: string;
    full_name: string;
  };
  project?: {
    id: string;
    name: string;
  };
  user?: {
    id: string;
    full_name: string | null;
    email: string;
  };
  status?: TaskStatus;
}

export interface GroupHeader {
  id: string;
  isGroupHeader: true;
  count: number;
}

export type TableItem = Task | GroupHeader;

export interface TimeEntry {
  id: string;
  organization_id: string;
  user_id: string;
  client_id: string | null;
  project_id: string | null;
  service_id: string | null;
  start_time: string;
  end_time: string | null;
  duration: number;
  description: string | null;
  is_billable: boolean;
  client?: Client;
  project?: Project;
  service?: Service;
  time_entry_invoice_items?: TimeEntryInvoiceItem[];
}

export interface TimeEntryInvoiceItem {
  invoice_item: InvoiceItem;
}

export interface OrganizationUser {
  id: string;
  full_name: string | null;
  email: string;
  role: string;
  is_owner: boolean;
}

export interface Entity {
  id: string;
  name: string;
}

export interface GroupHeader {
  id: string;
  isGroupHeader: true;
}

export interface Organization {
  id: string;
  name: string;
  business_name: string | null;
  business_email: string | null;
  logo_url: string | null;
  address_line1: string | null;
  address_line2: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
  enable_email_tracking: boolean;
  stripe_account_id: string | null;
  stripe_account_verified: boolean | null;
}

export interface PendingInvitation {
  id: string;
  organization_id: string;
  email: string;
  created_at: string;
}

export type TaskFilterId = 'status' | 'client' | 'project' | 'assignee' | 'date';
export type FilterId = TaskFilterId | 'user' | 'billable' | 'category';

export interface Filter {
  id: FilterId;
  label: string;
  value: string[] | { startDate: Date | null; endDate: Date | null } | string;
}

export interface FilterOption {
  id: FilterId;
  label: string;
  type: 'multipleEntity' | 'dateRange' | 'select';
  options?: string[];
}

// Add this near the other type definitions
export interface EmailTrackingData {
  open_count: number;
  open_history: string[];
  recipient_email: string;
  sent_at: string;
  opened: boolean;
  opened_at: string | null;
}

export interface BrandSettings {
  default_template: string;
  default_font: string;
  default_header_color: string;
  default_background_color: string;
  default_header_text_color: string;
  default_body_text_color: string;
  auto_color_text: boolean;
  show_logo: boolean;
  default_payment_terms?: string;
  // Add any other properties that might be part of the brand settings
}

export interface GroupedTimeEntry extends TimeEntry {
  groupDate: string;
  user_full_name: string;
  invoiceId: string | null;
  combinedEntries?: TimeEntry[];
}

export interface InvoiceTemplatesProps {
  // ... existing props ...
  organization: Organization | null;
}

// Update TimeTrackerStyle type to match layout names
export type TimeTrackerStyle = 'default' | 'modern' | 'evening' | 'sunrise' | 'retro';
export type TimeEntryLayout = 'Default' | 'Modern' | 'Evening' | 'Sunrise' | 'Retro';

// Add a mapping type
export const TIME_TRACKER_TO_ENTRY_LAYOUT: Record<TimeTrackerStyle, TimeEntryLayout> = {
  default: 'Default',
  modern: 'Modern',
  evening: 'Evening',
  sunrise: 'Sunrise',
  retro: 'Retro'
};

// Add a new type for batch updates
export interface BatchTaskUpdate {
  type: 'batch';
  updates: Partial<Task>[];
  optimisticUpdates?: Task[]; // For optimistic UI updates
}

export type InvoiceStatus = 'paid' | 'unpaid' | 'draft' | 'overdue';

export interface EmailTracking {
  id: string;
  tracking_id: string;
  invoice_id: string;
  recipient_email: string;
  sent_at: string;
  opened: boolean;
  opened_at: string | null;
  open_count: number;
  open_history: string[];
}
