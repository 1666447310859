import React, { useCallback, useMemo, useEffect, useState } from "react";
import { supabase } from "../../../supabaseClient";
import { Project, Client } from "../../../types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "../../../shared/components/Button";
import styled from "styled-components";
import { useNavigate } from '@tanstack/react-router'
import { useProjects } from "../../../hooks";
import { useOrganization } from "../../../features/organization/hooks/useOrganization";
import Tabs from "../../../shared/components/Tabs";
import { Card, CardContent, CardTitle, CardItem, CardFooter } from '../../../shared/components/Card';
import EmptyStateCard from '../../../components/EmptyStateCard';
import { usePageContext } from '../../../hooks/usePageContext';

const ProjectListWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 60px);
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 32px 40px;
`;

const formatDate = (dateString: string | null) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

const ProjectList: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const {
    data: projects,
    isLoading: isLoadingProjects,
  } = useProjects();

  const { data: clients } = useQuery({
    queryKey: ["clients", organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from("clients")
        .select("*")
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Client[];
    },
    enabled: !!organizationId,
  });

  const createProjectMutation = useMutation<
    Project,
    Error,
    Omit<Project, "id" | "created_at">
  >({
    mutationFn: async (newProject) => {
      const { data, error } = await supabase
        .from("projects")
        .insert([newProject])
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["projects", organizationId] });
      navigate({ to: '/project/$id', params: { id: data.id } });
    },
  });

  const prefetchProject = useCallback(
    (project: Project) => {
      queryClient.prefetchQuery({
        queryKey: ["project", project.id, organizationId],
        queryFn: async () => {
          const [
            { data: projectData, error: projectError },
            { data: invoicesData, error: invoicesError },
            { data: expensesData, error: expensesError },
          ] = await Promise.all([
            supabase.from("projects").select("*").eq("id", project.id).single(),
            supabase
              .from("invoices")
              .select("id, amount_due, payments (id, amount, payment_date)")
              .eq("project_id", project.id),
            supabase
              .from("expenses")
              .select("id, amount")
              .eq("project_id", project.id),
          ]);

          if (projectError) throw projectError;
          if (invoicesError) throw invoicesError;
          if (expensesError) throw expensesError;

          return {
            ...projectData,
            invoices: invoicesData || [],
            expenses: expensesData || [],
          } as Project;
        },
        staleTime: 5 * 60 * 1000, // 5 minutes
      });
    },
    [queryClient, organizationId]
  );

  const handleCreateProject = useCallback(async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) {
        console.error("No user found");
        return;
      }

      if (!organizationId) {
        console.error("Organization not found");
        return;
      }

      createProjectMutation.mutate({
        name: "",
        description: "",
        client_id: null,
        is_active: true,
        user_id: user.id,
        organization_id: organizationId,
        due_date: null,
        status: '',
      });
    } catch (error) {
      console.error("Error creating project:", error);
    }
  }, [createProjectMutation, organizationId]);

  const handleProjectClick = useCallback(
    (project: Project) => {
      prefetchProject(project);
      navigate({ to: '/project/$id', params: { id: project.id } });
    },
    [navigate, prefetchProject]
  );

  const [activeTab, setActiveTab] = useState("all");

  const filteredProjects = useMemo(() => {
    if (!projects) return [];
    let filtered = projects.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    if (activeTab === "active") {
      filtered = filtered.filter(project => project.status !== "completed");
    } else if (activeTab === "completed") {
      filtered = filtered.filter(project => project.status === "completed");
    }
    return filtered;
  }, [projects, activeTab]);

  const tabCounts = useMemo(() => {
    if (!projects) return { all: 0, active: 0, completed: 0 };
    return projects.reduce((acc, project) => {
      acc.all++;
      if (project.status === 'completed') {
        acc.completed++;
      } else {
        acc.active++;
      }
      return acc;
    }, { all: 0, active: 0, completed: 0 });
  }, [projects]);

  const tabs = useMemo(() => [
    { id: "all", label: "All", count: isLoadingProjects ? 0 : tabCounts.all, isLoading: isLoadingProjects },
    { id: "active", label: "Active", count: isLoadingProjects ? 0 : tabCounts.active, isLoading: isLoadingProjects },
    { id: "completed", label: "Completed", count: isLoadingProjects ? 0 : tabCounts.completed, isLoading: isLoadingProjects },
  ], [tabCounts, isLoadingProjects]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Projects",
      right: (
        <Button buttonType="primary" onClick={handleCreateProject}>
          New Project
        </Button>
      ),
    });
  }, [setPageHeaderProps]);

  return (
    <ProjectListWrapper>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <PageGrid>
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project: Project) => (
            <Card
              key={project.id}
              onClick={() => handleProjectClick(project)}
              onMouseEnter={() => prefetchProject(project)}
            >
              <CardContent>
                <CardTitle>{project.name || "Untitled Project"}</CardTitle>
                {project.client_id && (
                  <CardItem>
                    {clients?.find((c) => c.id === project.client_id)?.company}
                  </CardItem>
                )}
              </CardContent>
              <CardFooter>
                {project.due_date && (
                  <CardItem>Due {formatDate(project.due_date)}</CardItem>
                )}
              </CardFooter>
            </Card>
          ))
        ) : (
          <EmptyStateCard
            title="Create a new project"
            description="Add your first project to get started"
            actionText="Click here to create a new project"
            onClick={handleCreateProject}
          />
        )}
      </PageGrid>
    </ProjectListWrapper>
  );
};

export default ProjectList;
