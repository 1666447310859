import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { Project } from '../../../types';
import { useOrganization } from '../../../hooks/';

export const useProjects = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Project[]>({
    queryKey: ['projects', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization not found');

      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('organization_id', organizationId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });
};
