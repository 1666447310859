import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useOrganizationUsers, useOrganization } from '../../../hooks';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { PendingInvitation } from '../../../types';
import { Close12 } from '../../../shared/components/Icon';
import InviteUserForm from './InviteUserForm';
import { OrganizationUser } from '../../../types';

const PageWrapper = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const Section = styled.div`
`;

const UserList = styled.div`
  border-radius: 8px;
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const UserName = styled.span`
  font-weight: 500;
  flex: 1;
  color: #333;
`;

const UserEmail = styled.span`
  color: #6c757d;
  flex: 1;
`;

const PendingInviteItem = styled(UserItem)`
  background-color: rgba(0, 0, 0, 0.02);
`;

const PendingLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #f29d41;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: rgba(242, 157, 65, 0.1);
  margin-left: 8px;
`;

const RevokeButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  justify-content: center;
  margin-left: 8px;
  border-radius: 16px;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const DisabledRemoveButton = styled(RevokeButton)`
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: transparent;
  }
`;

const OwnerLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #1e7e34;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: rgba(30, 126, 52, 0.1);
  margin-left: 8px;
`;

const NoUsersMessage = styled.div`
  text-align: center;
  padding: 32px;
  color: #666;
  font-size: 14px;
`;

const OrganizationUsersTab: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: organizationUsers, error: usersError } = useOrganizationUsers();
  const [localPendingInvitations, setLocalPendingInvitations] = useState<PendingInvitation[]>([]);

  const { data: pendingInvitations, error: invitationsError } = useQuery({
    queryKey: ["pendingInvitations", organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from("pending_invitations")
        .select("*")
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as PendingInvitation[];
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (pendingInvitations) {
      setLocalPendingInvitations(pendingInvitations);
    }
  }, [pendingInvitations]);

  const revokeInvitationMutation = useMutation({
    mutationFn: async (invitationId: string) => {
      const { error } = await supabase
        .from("pending_invitations")
        .delete()
        .eq("id", invitationId);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingInvitations", organizationId],
      });
    },
  });

  const handleRevokeInvitation = (invitationId: string) => {
    if (window.confirm("Are you sure you want to revoke this invitation?")) {
      revokeInvitationMutation.mutate(invitationId);
    }
  };

  const removeUserMutation = useMutation({
    mutationFn: async (userId: string) => {
      if (!organizationId) throw new Error('No organization ID');
      const { error } = await supabase
        .from("organization_members")
        .delete()
        .match({ user_id: userId, organization_id: organizationId });
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["organizationUsers", organizationId],
      });
    },
  });

  const handleRemoveUser = (user: OrganizationUser) => {
    if (user.is_owner) {
      alert("You cannot remove the organization owner.");
      return;
    }
    if (window.confirm(`Are you sure you want to remove ${user.full_name || user.email} from the organization?`)) {
      removeUserMutation.mutate(user.id);
    }
  };

  const handleInviteSent = (newInvitation: PendingInvitation) => {
    setLocalPendingInvitations(prev => [...prev, newInvitation]);
  };

  if (usersError) {
    console.error("Error fetching users:", usersError);
    return <p>Error loading users. Please try again.</p>;
  }

  if (invitationsError) {
    console.error("Error fetching invitations:", invitationsError);
    return <p>Error loading invitations. Please try again.</p>;
  }

  const existingEmails = organizationUsers?.map(user => user.email) || [];
  const pendingEmails = localPendingInvitations.map(invite => invite.email);

  return (
    <PageWrapper>
      <PageTitle>Organization Members</PageTitle>
      
      <Section>
        <InviteUserForm 
          existingEmails={[...existingEmails, ...pendingEmails]} 
          onInviteSent={handleInviteSent}
        />
      </Section>

      {(organizationUsers && organizationUsers.length > 0) || (localPendingInvitations.length > 0) ? (
        <UserList>
          {organizationUsers && organizationUsers.length > 0 && (
            [...organizationUsers]
              .sort((a, b) => (a.is_owner ? -1 : b.is_owner ? 1 : 0))
              .map((user) => (
              <UserItem key={user.id}>
                <UserName>
                  {user.full_name || 'N/A'}
                  {user.is_owner && <OwnerLabel>Owner</OwnerLabel>}
                </UserName>
                <UserEmail>{user.email}</UserEmail>
                {user.is_owner ? (
                  <DisabledRemoveButton
                    title="Cannot remove organization owner"
                    disabled
                  >
                    <Close12 />
                  </DisabledRemoveButton>
                ) : (
                  <RevokeButton
                    onClick={() => handleRemoveUser(user)}
                    title="Remove User"
                  >
                    <Close12 />
                  </RevokeButton>
                )}
              </UserItem>
            ))
          )}
          {localPendingInvitations.length > 0 && (
            localPendingInvitations.map((invite) => (
              <PendingInviteItem key={invite.id}>
                <UserEmail>{invite.email}</UserEmail>
                <PendingLabel>Pending</PendingLabel>
                <RevokeButton
                  onClick={() => handleRevokeInvitation(invite.id)}
                  title="Revoke Invitation"
                >
                  <Close12 />
                </RevokeButton>
              </PendingInviteItem>
            ))
          )}
        </UserList>
      ) : (
        <NoUsersMessage>
          No users or pending invitations found for this organization.
        </NoUsersMessage>
      )}
    </PageWrapper>
  );
};

export default OrganizationUsersTab;