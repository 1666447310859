import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import styled from 'styled-components';
import { Payment, InvoiceData } from '../../../types';
import { Link, useNavigate } from '@tanstack/react-router';
import { useOrganization, usePageContext, useEmailTrackingData } from '../../../hooks';
import DataTable from '../../../components/DataTable';
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import DateRangePicker from '../../../components/DateRangePicker';
import { Calendar12, Payments32 } from '../../../shared/components/Icon';
import { isWithinInterval, startOfDay, endOfDay, format, isThisYear } from 'date-fns';
import Tabs from '../../../shared/components/Tabs';
import InvoiceDrawer from '../../invoices/components/InvoiceDrawer';
import InvoiceDetails from '../../invoices/components/InvoiceDetails';

const formatCurrency = (amount: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const PageContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const PaymentsPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [activeTab, setActiveTab] = useState('all');
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'payment_date', desc: true }
  ]);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<(Payment & { invoices: { id: string; invoice_number: string } }) | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);

  const fetchPayments = useCallback(async () => {
    if (!organizationId) throw new Error('No organization found');
  
    const { data, error } = await supabase
      .from('payments')
      .select('*, invoices(id, invoice_number)')
      .eq('organization_id', organizationId)
      .order('payment_date', { ascending: false });
  
    if (error) throw error;
    return data;
  }, [organizationId]);

  const { data: payments, isLoading, isError } = useQuery<(Payment & { invoices: { id: string; invoice_number: string } })[]>({
    queryKey: ['payments', organizationId],
    queryFn: fetchPayments,
    enabled: !!organizationId,
    staleTime: 30000,
    refetchOnWindowFocus: true,
  });

  const [localPayments, setLocalPayments] = useState<(Payment & { invoices: { id: string; invoice_number: string } })[]>(() => {
    const cachedPayments = queryClient.getQueryData(['payments', organizationId]) as (Payment & { invoices: { id: string; invoice_number: string } })[] | undefined;
    if (cachedPayments) {
      initializedFromCache.current = true;
      return cachedPayments;
    }
    return [];
  });

  useEffect(() => {
    if (payments) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalPayments(payments);
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [payments]);

  const { data: selectedInvoice } = useQuery<InvoiceData>({
    queryKey: ['invoice', selectedPayment?.invoices?.id],
    queryFn: async () => {
      if (!selectedPayment?.invoices?.id) throw new Error('No invoice selected');
      const { data, error } = await supabase
        .from('invoices')
        .select('*')
        .eq('id', selectedPayment.invoices.id)
        .single();
      if (error) throw error;
      return data as InvoiceData;
    },
    enabled: !!selectedPayment?.invoices?.id,
  });

  const { data: emailTrackingData } = useEmailTrackingData(selectedPayment?.invoices?.id || null);

  const columns = useMemo<ColumnDef<Payment & { invoices: { id: string; invoice_number: string } }>[]>(() => [
    {
      accessorKey: 'payment_date',
      header: 'Date',
      cell: ({ getValue }) => {
        const date = new Date(getValue() as string);
        return format(date, isThisYear(date) ? 'MMM d' : 'MMM d, yyyy');
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ row }) => formatCurrency(row.original.amount, row.original.currency || 'USD'),
    },
    {
      accessorKey: 'invoices',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const invoice = getValue() as { id: string; invoice_number: string };
        return invoice && invoice.id ? (
          <Link to="/invoice/$id" params={{ id: invoice.id }}>
            {invoice.invoice_number || 'No number'}
          </Link>
        ) : (
          'N/A'
        );
      },
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
    },
  ], []);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  const handleDateRangeChange = useCallback((newRange: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(newRange);
  }, []);

  const memoizedPayments = useMemo(() => {
    if (!localPayments) return [];
    
    let filtered = localPayments;

    // Filter by date range
    if (dateRange.startDate && dateRange.endDate) {
      const start = startOfDay(new Date(dateRange.startDate));
      const end = endOfDay(new Date(dateRange.endDate));
      filtered = filtered.filter(payment => {
        const paymentDate = new Date(payment.payment_date);
        return isWithinInterval(paymentDate, { start, end });
      });
    }

    return filtered;
  }, [localPayments, dateRange]);

  const tabs = useMemo(() => [
    { id: 'all', label: 'All', count: isLoading ? 0 : undefined, isLoading },
  ], [isLoading]);

  const handleTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
  }, []);

  const emptyState = {
    icon: <Payments32 />,
    message: "No payments recorded",
    subMessage: "Payments will appear here once they are processed."
  };

  const handleRowClick = useCallback((payment: Payment & { invoices: { id: string; invoice_number: string } }) => {
    if (payment.invoices?.id) {
      setSelectedPayment(payment);
      setIsDrawerOpen(true);
    }
  }, []);

  const handleCloseDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsDrawerOpen(false);
      setSelectedPayment(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseDrawer();
    }
  }, [handleCloseDrawer, isAnyPopoverOpen]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Payments",
    });
  }, [setPageHeaderProps]);

  if (isError) {
    return (
      <PageContainer ref={containerRef}>
        <div>Error loading payments. Please try again.</div>
      </PageContainer>
    );
  }

  return (
    <PageContainer ref={containerRef}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        actionButtons={
          <DateRangePicker
            selectedRange={dateRange}
            onChange={handleDateRangeChange}
            label="Date Range"
            id="payment-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
        }
      />
      <TableWrapper>
        <DataTable<Payment & { invoices: { id: string; invoice_number: string } }>
          columns={columns}
          data={memoizedPayments}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          getRowKey={(row) => row.id}
          onSelectionChange={() => {}}
          containerRef={containerRef}
          emptyState={emptyState}
          sorting={sorting}
          onSortingChange={handleSortingChange}
          rowClassName="payment-row"
        />
      </TableWrapper>
      <InvoiceDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title="Invoice Details"
        selectedInvoice={selectedInvoice || null}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        modal={false}
      >
        {selectedInvoice && selectedPayment && (
          <InvoiceDetails
            invoice={selectedInvoice}
            clients={[]}
            navigate={navigate}
            queryClient={queryClient}
            payments={[selectedPayment]}
            emailTrackingData={emailTrackingData}
          />
        )}
      </InvoiceDrawer>
    </PageContainer>
  );
};

export default PaymentsPage;
