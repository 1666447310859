import React, { useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from '../../../templates/types';
import { Client, InvoiceData, InvoiceItem, Organization } from '../../../types';
import { calculateInvoiceTotals } from '../../../utils/invoiceCalculations';
import { ClientSelector } from '../../../features/clients/components/ClientSelector';
import AnimatedHeader from '../../../shared/components/AnimatedHeader';

// Direct imports instead of lazy loading
import { Structure } from '../../../templates/Structure';
import { DetailedHeader } from '../../../templates/DetailedHeader';
import { ModernHeader } from '../../../templates/ModernHeader';
import { MinimalistHeader } from '../../../templates/MinimalistHeader';
import { GradientHeader } from '../../../templates/GradientHeader';
import { BoxedHeader } from '../../../templates/BoxedHeader';
import { SplitHeader } from '../../../templates/SplitHeader';
import { CircularHeader } from '../../../templates/CircularHeader';
import { CompactHeader } from '../../../templates/CompactHeader';

const PreviewContainer = styled.div.attrs<{ $backgroundColor: string; $bodyTextColor: string }>(
  props => ({
    style: {
      backgroundColor: props.$backgroundColor,
      color: props.$bodyTextColor,
    },
  })
)``;

interface HeaderPreviewProps {
  template: string;
  font: string;
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
  logoUrl: string | null;
  showLogo: boolean;
}

// Modify the getMockData function to accept logoUrl as a parameter
const getMockData = (logoUrl: string | null) => {
  const exampleInvoiceItems: InvoiceItem[] = [
    {
      id: 'example-item-1',
      invoice_id: 'preview-invoice',
      description: 'Web Design Services',
      quantity: 1,
      price: 1500,
      taxable: true,
      order: 0,
    },
    {
      id: 'example-item-2',
      invoice_id: 'preview-invoice',
      description: 'Logo Design',
      quantity: 1,
      price: 500,
      taxable: true,
      order: 1,
    },
    {
      id: 'example-item-3',
      invoice_id: 'preview-invoice',
      description: 'Hosting (per month)',
      quantity: 12,
      price: 20,
      taxable: false,
      order: 2,
    },
  ];

  const demoClient: Client = {
    id: 'demo-client-id',
    full_name: 'Demo Client',
    email: 'demo@example.com',
    phone: '(555) 123-4567',
    address_line1: '123 Demo Street',
    address_line2: 'Suite 456',
    city: 'Demo City',
    state: 'DS',
    postal_code: '12345',
    country: 'Demoland',
    organization_id: 'demo-org-id',
    created_at: new Date().toISOString(),
    is_active: true,
    company: 'Demo Company Inc.',
    notes: '',
    default_currency: 'USD'
  };

  const mockOrganization: Organization = {
    id: 'mock-org-id',
    name: 'Mock Organization',
    business_name: 'Mock Business',
    business_email: null,
    address_line1: '123 Mock Street',
    address_line2: 'Suite 456',
    city: 'Mockville',
    state: 'MK',
    postal_code: '12345',
    country: 'Mockland',
    logo_url: logoUrl,
    enable_email_tracking: false,
    stripe_account_id: null,
    stripe_account_verified: null
  };

  return { exampleInvoiceItems, demoClient, mockOrganization };
};

const HeaderPreview: React.FC<HeaderPreviewProps> = memo(({ 
  template, 
  font, 
  headerColor, 
  headerTextColor,
  backgroundColor,
  bodyTextColor,
  logoUrl, 
  showLogo
}) => {
  const { exampleInvoiceItems, demoClient, mockOrganization } = useMemo(() => getMockData(logoUrl), [logoUrl]);

  const mockInvoice: InvoiceData = useMemo(() => ({
    id: 'mock-id',
    client_id: demoClient.id,
    font,
    header_color: headerColor,
    header_text_color: headerTextColor,
    invoice_date: '2025-11-05',
    due_date: '2025-12-05',
    invoice_number: 'PREVIEW',
    tax_rate: 5,
    due_days: 30,
    currency: 'USD',
    items: exampleInvoiceItems,
    notes: '',
    invoice_template: 'minimalist',
    public_id: 'mock-public-id',
    payments: [],
    status: 'draft',
    logo_url: logoUrl,
    show_logo: showLogo,
    invoice_created_at: '2025-12-05',
    background_color: '#ffffff',
    body_text_color: '#000000',
    subtotal: 2240,
    total: 2340,
    amount_due: 2340,
  }), [demoClient.id, font, headerColor, headerTextColor, exampleInvoiceItems, logoUrl, showLogo]);

  const totals = useMemo(() => calculateInvoiceTotals(mockInvoice), [mockInvoice]);

  const mockInvoiceWithTotals: InvoiceData = useMemo(() => ({
    ...mockInvoice,
    subtotal: totals.subtotal,
    total: totals.total,
    amount_due: totals.amountDue,
  }), [mockInvoice, totals]);

  const mockUpdateInvoice: InvoiceTemplatesProps['updateInvoice'] = useCallback(() => {}, []);
  const mockClients: Client[] = useMemo(() => [demoClient], [demoClient]);
  const mockSelectedClient: Client = demoClient;

  const renderHeader = useCallback(() => {
    const commonProps: Omit<InvoiceTemplatesProps, 'onCreateNewClient'> = {
      invoice: mockInvoiceWithTotals,
      updateInvoice: mockUpdateInvoice,
      isReadOnly: true,
      isPublic: true,
      clients: mockClients,
      selectedClient: mockSelectedClient,
      onOpenOrganizationDrawer: () => {},
      ClientSelectorComponent: ({ clients, selectedClientId, onSelectClient, onCreateNewClient, headerTextColor }) => (
        <ClientSelector
          clients={clients}
          selectedClientId={selectedClientId}
          onSelectClient={onSelectClient}
          onCreateNewClient={onCreateNewClient}
          headerTextColor={headerTextColor}
          isPreview={true}
          onEditClient={() => {}}
          disabled={true}
        />
      ),
      organization: mockOrganization,
      showLogo,
    };

    const onCreateNewClient = () => {};

    const HeaderComponent = (() => {
      switch (template) {
        case 'structure': return Structure;
        case 'detailed': return DetailedHeader;
        case 'modern': return ModernHeader;
        case 'minimalist': return MinimalistHeader;
        case 'gradient': return GradientHeader;
        case 'boxed': return BoxedHeader;
        case 'split': return SplitHeader;
        case 'circular': return CircularHeader;
        case 'compact': return CompactHeader;
        default: return Structure;
      }
    })();

    return (
      <HeaderComponent 
        {...commonProps} 
        onCreateNewClient={onCreateNewClient}
      />
    );
  }, [
    template, 
    mockInvoiceWithTotals, 
    mockUpdateInvoice, 
    mockClients, 
    mockSelectedClient, 
    mockOrganization,
    showLogo
  ]);

  return (
    <PreviewContainer 
      $backgroundColor={backgroundColor}
      $bodyTextColor={bodyTextColor}
    >
      <AnimatedHeader>
        {renderHeader()}
      </AnimatedHeader>
    </PreviewContainer>
  );
});

export default HeaderPreview;
