import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import SidebarLeft from "../components/SidebarLeft";
import { supabase } from "../supabaseClient";
import Auth from "../Auth";
import { useOrganization } from "../features/organization/hooks";
import PendingInvitationsPage from '../PendingInvitationsPage';
import CreateOrganizationPage from '../features/organization/pages/CreateOrganizationPage';
import LoadingScreen from '../components/LoadingScreen';
import OnboardingPage from '../features/onboarding/pages/OnboardingPage';
import { debounce } from 'lodash';
import { useAuth } from "../hooks";
import PageHeader, { PageHeaderProps } from '../shared/components/PageHeader'; // Import PageHeader
import { PageContext } from '../contexts/PageHeaderContext';
import { User } from '@supabase/supabase-js';

// Define the UserProfile interface here
interface UserProfile {
  full_name: string | null;
  onboarding_completed: boolean;
}

// At the top level
const CACHE_TIMES = {
  HOUR: 60 * 60 * 1000,
  HALF_HOUR: 30 * 60 * 1000,
  FIVE_MINUTES: 5 * 60 * 1000,
} as const;

const ROUTE_CONFIGS = {
  '/': { table: 'invoices', limit: 20 },
  '/time': { table: 'time_entries', limit: 20, orderBy: { column: 'start_time', ascending: false } },
  '/clients': { table: 'clients', limit: 50, orderBy: { column: 'created_at', ascending: false } },
  '/projects': { table: 'projects', limit: 20, orderBy: { column: 'created_at', ascending: false } },
} as const;

const Layout: React.FC = () => {
  const { data: user, isLoading: authLoading, error: authError } = useAuth();
  const matches = useMatches();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: organizationId, isLoading: orgLoading, error: orgError } = useOrganization();
  const isPublicRoute = matches.some(match => match.pathname.startsWith('/share/'));

  const fetchRouteData = useCallback(async (route: string) => {
    if (!organizationId) {
      console.log('No organizationId available, skipping prefetch');
      return null;
    }

    const commonOptions = {
      eq: 'organization_id',
      value: organizationId,
      orderBy: { column: 'updated_at', ascending: false },
    };

    const config = ROUTE_CONFIGS[route as keyof typeof ROUTE_CONFIGS];
    if (!config) return null;

    try {
      let query = supabase
        .from(config.table)
        .select('*')
        .eq(commonOptions.eq, commonOptions.value)
        .limit(config.limit);

      // Use the route-specific orderBy if available, otherwise use the common orderBy
      const orderBy = 'orderBy' in config ? config.orderBy : commonOptions.orderBy;
      query = query.order(orderBy.column, { ascending: orderBy.ascending });

      const { data, error } = await query;

      if (error) throw error;
      if (route === '/time') {
        queryClient.setQueryData(['timeEntries', organizationId], data);
      } else {
        queryClient.setQueryData(['route', route, organizationId], data);
      }
      return data;
    } catch (error) {
      console.error(`Error fetching data for ${route}:`, error);
      return null;
    }
  }, [organizationId]);

  const debouncedPrefetchRoute = useCallback(
    debounce((route: string) => {
      if (organizationId) {
        console.log(`Prefetching data for route: ${route}`);
        queryClient.prefetchQuery({
          queryKey: ['route', route, organizationId],
          queryFn: () => fetchRouteData(route),
          staleTime: CACHE_TIMES.FIVE_MINUTES,
        });
      }
    }, 300),
    [queryClient, organizationId, fetchRouteData]
  );

  const usePrefetchQueries = (user: User | null | undefined, organizationId: string | null) => {
    useEffect(() => {
      const prefetchQueries = async () => {
        if (!user || !organizationId) return;

        // Organization query
        queryClient.prefetchQuery({
          queryKey: ['organization', organizationId],
          queryFn: async () => {
            const { data, error } = await supabase
              .from('organizations')
              .select('*')
              .eq('id', organizationId)
              .single();
            if (error) throw error;
            return data;
          },
          staleTime: CACHE_TIMES.HOUR,
          gcTime: CACHE_TIMES.HOUR,
        });

        // Organization members query
        queryClient.prefetchQuery({
          queryKey: ['organizationMembers', organizationId],
          queryFn: async () => {
            const { data: memberData, error: memberError } = await supabase
              .rpc('get_organization_members_with_details', {
                org_id: organizationId
              });

            if (memberError) throw memberError;
            return memberData || [];
          },
          staleTime: CACHE_TIMES.HALF_HOUR,
          gcTime: CACHE_TIMES.HALF_HOUR,
        });

        // Prefetch routes
        const routesToPrefetch = ['/', '/clients', '/projects'];
        routesToPrefetch.forEach(debouncedPrefetchRoute);
      };

      prefetchQueries();
    }, [user, organizationId, queryClient, debouncedPrefetchRoute]);
  };

  // Before calling usePrefetchQueries, add a null check
  usePrefetchQueries(user ?? null, organizationId);

  const { data: userProfile, isLoading: isProfileLoading } = useQuery<UserProfile | null, Error>({
    queryKey: ['userProfile', user?.id],
    queryFn: async () => {
      if (!user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('full_name, onboarding_completed')
        .eq('id', user.id)
        .maybeSingle();
      
      if (error) throw error;
      
      // If no profile exists, return null
      if (!data) return null;
      
      return data as UserProfile;
    },
    enabled: !!user?.id,
    staleTime: 2000,
    placeholderData: (previousData) => previousData
  });

  const authStateCallback = useCallback(
    async (event: string) => {
      if (event === 'SIGNED_OUT') {
        queryClient.clear();
        navigate({ to: '/' });
      }
    },
    [navigate, queryClient]
  );

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(authStateCallback);

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [authStateCallback]);

  const [pageHeaderProps, setPageHeaderProps] = useState<PageHeaderProps>({});

  const renderContent = useMemo(() => {
    // Show loading screen only for initial auth loading
    if (authLoading) {
      return <LoadingScreen />;
    }

    if (isPublicRoute) {
      return <Outlet />;
    }

    if (authError || !user) {
      if (authError) {
        console.error('Authentication error:', authError);
      }
      return <Auth />;
    }

    // Wait for initial profile data before proceeding
    if (isProfileLoading && userProfile === undefined) {
      return <LoadingScreen />;
    }

    // Show onboarding only if:
    // 1. Profile doesn't exist (null) OR
    // 2. Profile exists but onboarding is not completed
    if (!isProfileLoading && (!userProfile || userProfile.onboarding_completed === false)) {
      return <OnboardingPage />;
    }

    // Wait for organization data before proceeding
    if (orgLoading) {
      return <LoadingScreen />;
    }

    if (organizationId === 'pending_invitations') {
      return <PendingInvitationsPage />;
    }

    // Only show CreateOrganizationPage if:
    // 1. Organization ID is null AND
    // 2. Onboarding is completed AND
    // 3. Not in a loading state
    if (organizationId === null && userProfile?.onboarding_completed && !orgLoading && !isProfileLoading) {
      if (orgError) {
        console.error('Error fetching organization:', orgError);
      }
      return <CreateOrganizationPage />;
    }

    // Show loading during transitions
    if (!organizationId) {
      return <LoadingScreen />;
    }

    return (
      <div className="app">
        <div className="sidebar">
          <SidebarLeft />
        </div>
        <div className="main-content">
          <PageHeader {...pageHeaderProps} />
          <Outlet />
        </div>
      </div>
    );
  }, [user, authLoading, authError, orgLoading, isProfileLoading, isPublicRoute, userProfile, organizationId, orgError, pageHeaderProps]);

  return (
    <PageContext.Provider value={{ setPageHeaderProps }}>
      {renderContent}
    </PageContext.Provider>
  );
};

export default Layout;
