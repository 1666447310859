import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { format, isWithinInterval, startOfDay, endOfDay, addMonths, startOfMonth } from "date-fns";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { ChevronLeft12, ChevronRight12 } from "../shared/components/Icon";

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &, & * {
    class-name: date-picker-element;
  }
`;

const BaseDateDisplay = css`
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
`;

type StyledDateDisplayProps = {
  $variant?: string;
  $noDatePicked?: boolean;
  $noBorderRadius?: boolean;
};

const StyledDateDisplay = styled.div<StyledDateDisplayProps>`
  ${BaseDateDisplay}
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  font-feature-settings: "tnum";
  user-select: none;
  
  ${props => props.$variant === 'preview' && css`
    padding: 4px 8px;
    border-radius: ${props => ((props as unknown) as StyledDateDisplayProps).$noBorderRadius ? '0' : '8px'};
    line-height: 20px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.8);
      svg path {
        fill: rgba(0, 0, 0, 0.8);
      }
    }
    color: ${props.$noDatePicked ? 'rgba(0,0,0,0.5)' : 'inherit'};
    svg path {
      fill: ${props.$noDatePicked ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.8)'};
    }
  `}

  ${props => props.$variant === 'input' && css`
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
  `}

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: top right;
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const CalendarWrapper = styled.div``;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
`;

const MonthHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  text-align: center;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
  color: #333;
  transition: background-color 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 4px;
  padding: 8px;
`;

const DayCell = styled.button<{
  $isSelected: boolean;
  $isToday: boolean;
  $isPreviousMonth: boolean;
  $isInRange: boolean;
  $isRangeStart: boolean;
  $isRangeEnd: boolean;
}>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  background: ${(props) => {
    if (props.$isSelected) return "black";
    if (props.$isInRange) return "rgba(0, 0, 0, 0.1)";
    return "transparent";
  }};
  color: ${(props) =>
    props.$isSelected ? "white" : props.$isToday ? "blue" : "inherit"};
  border-radius: ${(props) => {
    if (props.$isRangeStart) return '8px 0 0 8px';
    if (props.$isRangeEnd) return '0 8px 8px 0';
    if (props.$isSelected) return '50%';
    return '0';
  }};
  cursor: ${(props) =>
    props.$isPreviousMonth && !props.$isSelected ? "default" : "pointer"};
  opacity: ${(props) =>
    props.$isPreviousMonth && !props.$isSelected ? 0.2 : 1};

  &:hover {
    background: ${(props) => {
      if (props.$isSelected) return "black";
      if (props.$isPreviousMonth) return "transparent";
      return "rgba(0, 0, 0, 0.1)";
    }};
  }
`;

const WeekdayHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 8px 8px 8px;
`;

const WeekdayCell = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
`;

const CalendarContainer = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  user-select: none;
`;

const MonthContainer = styled.div`
  //width: 240px; // Adjust this value as needed
  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface DateRangePickerProps {
  selectedRange: DateRange;
  onChange: (range: DateRange) => void;
  label: string;
  id: string;
  disabled?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  variant?: 'preview' | 'input';
  placeholder?: string;
  icon?: React.ReactNode;
  placement?: Placement;
  noBorderRadius?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedRange,
  onChange,
  disabled = false,
  onOpenChange,
  variant = 'preview',
  placeholder = "Date",
  icon,
  placement = "top-end",
  noBorderRadius = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonths, setCurrentMonths] = useState<Date[]>([
    selectedRange.startDate || new Date(),
    addMonths(selectedRange.startDate || new Date(), 1),
  ]);
  const [isMounted, setIsMounted] = useState(false);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open);
      if (open && selectedRange.startDate) {
        setCurrentMonths([
          startOfMonth(selectedRange.startDate),
          addMonths(startOfMonth(selectedRange.startDate), 1),
        ]);
      }
      if (onOpenChange) {
        onOpenChange(open);
      }
    },
    placement,
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const handleDateClick = (date: Date) => {
    if (!selectedRange.startDate || (selectedRange.startDate && selectedRange.endDate)) {
      onChange({ startDate: date, endDate: null });
    } else {
      const startDate = selectedRange.startDate;
      const endDate = date;
      if (startDate > endDate) {
        onChange({ startDate: endDate, endDate: startDate });
      } else {
        onChange({ startDate, endDate });
      }
      setIsOpen(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
    }
  };

  const isInRange = (date: Date) => {
    if (selectedRange.startDate && !selectedRange.endDate && hoverDate) {
      return isWithinInterval(date, {
        start: startOfDay(selectedRange.startDate < hoverDate ? selectedRange.startDate : hoverDate),
        end: endOfDay(selectedRange.startDate > hoverDate ? selectedRange.startDate : hoverDate),
      });
    }
    if (selectedRange.startDate && selectedRange.endDate) {
      return isWithinInterval(date, {
        start: startOfDay(selectedRange.startDate),
        end: endOfDay(selectedRange.endDate),
      });
    }
    return false;
  };

  const generateCalendarDays = (month: Date) => {
    const days = [];
    const start = new Date(month.getFullYear(), month.getMonth(), 1);
    const end = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const startDay = start.getDay();
    const totalDays = end.getDate();

    for (let i = 0; i < startDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= totalDays; i++) {
      days.push(new Date(month.getFullYear(), month.getMonth(), i));
    }

    while (days.length % 7 !== 0) {
      days.push(null);
    }

    return days;
  };

  const formatDisplayDateRange = (range: DateRange) => {
    if (range.startDate && range.endDate) {
      return `${format(range.startDate, "MMM d")} - ${format(range.endDate, "MMM d, yyyy")}`;
    } else if (range.startDate) {
      return `${format(range.startDate, "MMM d, yyyy")} - ...`;
    } else {
      return placeholder;
    }
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handlePrevMonth = () => {
    setCurrentMonths(prevMonths => prevMonths.map(month => addMonths(month, -1)));
  };

  const handleNextMonth = () => {
    setCurrentMonths(prevMonths => prevMonths.map(month => addMonths(month, 1)));
  };

  const isRangeStart = (date: Date) => {
    return selectedRange.startDate && date.toDateString() === selectedRange.startDate.toDateString();
  };

  const isRangeEnd = (date: Date) => {
    return selectedRange.endDate && date.toDateString() === selectedRange.endDate.toDateString();
  };

  const noDatePicked = !selectedRange.startDate && !selectedRange.endDate;

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <DatePickerWrapper>
      <StyledDateDisplay
        ref={refs.setReference}
        {...getReferenceProps()}
        $variant={variant}
        $noDatePicked={noDatePicked && variant === 'preview'}
        $noBorderRadius={noBorderRadius}
      >
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {formatDisplayDateRange(selectedRange)}
      </StyledDateDisplay>
      <FloatingPortal>
        {isMounted && (
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              {!disabled && (
                <CalendarContainer>
                  {currentMonths.map((month, index) => (
                    <MonthContainer key={index}>
                      <CalendarWrapper>
                        <CalendarHeader>
                          {index === 0 ? (
                            <NavButton onClick={handlePrevMonth}>
                              <ChevronLeft12 />
                            </NavButton>
                          ) : <div style={{ width: '28px' }} />}
                          <MonthHeader>
                            {format(month, "MMMM yyyy")}
                          </MonthHeader>
                          {index === 1 ? (
                            <NavButton onClick={handleNextMonth}>
                              <ChevronRight12 />
                            </NavButton>
                          ) : <div style={{ width: '28px' }} />}
                        </CalendarHeader>
                        <WeekdayHeader>
                          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                            <WeekdayCell key={index}>{day}</WeekdayCell>
                          ))}
                        </WeekdayHeader>
                        <CalendarGrid>
                          {generateCalendarDays(month).map((day, dayIndex) => (
                            <DayCell
                              key={dayIndex}
                              onClick={() => day && handleDateClick(day)}
                              onMouseEnter={() => setHoverDate(day)}
                              onMouseLeave={() => setHoverDate(null)}
                              $isSelected={
                                !!(day && (
                                  (selectedRange.startDate && day.toDateString() === selectedRange.startDate.toDateString()) ||
                                  (selectedRange.endDate && day.toDateString() === selectedRange.endDate.toDateString())
                                ))
                              }
                              $isInRange={!!(day && isInRange(day))}
                              $isToday={!!(day && isToday(day))}
                              $isPreviousMonth={!day || day.getMonth() !== month.getMonth()}
                              $isRangeStart={!!(day && isRangeStart(day))}
                              $isRangeEnd={!!(day && isRangeEnd(day))}
                            >
                              {day ? day.getDate() : ""}
                            </DayCell>
                          ))}
                        </CalendarGrid>
                      </CalendarWrapper>
                    </MonthContainer>
                  ))}
                </CalendarContainer>
              )}
            </PopoverContent>
          </div>
        )}
      </FloatingPortal>
    </DatePickerWrapper>
  );
};

export default DateRangePicker;