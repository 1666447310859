import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { Task, BatchTaskUpdate } from '../../../types';
import { useOrganization } from '../../../hooks';

export const useTaskMutations = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const updateTaskMutation = useMutation({
    mutationFn: async (data: Partial<Task> | BatchTaskUpdate) => {
      if ('type' in data && data.type === 'batch') {
        // Use Supabase RPC call instead of REST API
        const { data: result, error } = await supabase.rpc('batch_update_tasks', {
          task_updates: data.updates
        });
        
        if (error) throw error;
        return result;
      } else {
        // Handle single task update
        const { data: result, error } = await supabase
          .from('tasks')
          .update(data)
          .eq('id', (data as Partial<Task>).id)
          .select()
          .single();
        
        if (error) throw error;
        return result;
      }
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false 
      });

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', organizationId]);

      if ('type' in data && data.type === 'batch' && data.optimisticUpdates) {
        // Apply optimistic updates
        queryClient.setQueryData<Task[]>(['tasks', organizationId], old => {
          if (!old) return old;
          const updated = [...old];
          data.optimisticUpdates!.forEach(update => {
            const index = updated.findIndex(t => t.id === update.id);
            if (index !== -1) {
              updated[index] = { ...updated[index], ...update };
            }
          });
          return updated;
        });
      }

      return { previousTasks };
    },
    onError: (_err, _newTask, context) => {
      // Revert to previous state on error
      if (context?.previousTasks) {
        queryClient.setQueryData(['tasks', organizationId], context.previousTasks);
      }
    },
    onSettled: () => {
      // Invalidate all related queries
      queryClient.invalidateQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false
      });
    },
  });

  const createTaskMutation = useMutation({
    mutationFn: async (newTask: Omit<Task, 'id'>) => {
      const { data, error } = await supabase
        .from('tasks')
        .insert(newTask)
        .select()
        .single();

      if (error) throw error;
      return data as Task;
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false 
      });

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', organizationId]);

      return { previousTasks };
    },
    onError: (_err, _newTask, context) => {
      if (context?.previousTasks) {
        queryClient.setQueryData(['tasks', organizationId], context.previousTasks);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false
      });
    },
  });

  const deleteTaskMutation = useMutation({
    mutationFn: async (taskId: string) => {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;
      return taskId;
    },
    onMutate: async (taskId) => {
      await queryClient.cancelQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false 
      });

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', organizationId]);

      // Optimistically remove the task
      if (previousTasks) {
        queryClient.setQueryData<Task[]>(
          ['tasks', organizationId],
          previousTasks.filter(task => task.id !== taskId)
        );
      }

      return { previousTasks };
    },
    onError: (_err, _taskId, context) => {
      if (context?.previousTasks) {
        queryClient.setQueryData(['tasks', organizationId], context.previousTasks);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false 
      });
    },
  });

  return {
    updateTaskMutation,
    createTaskMutation,
    deleteTaskMutation,
  };
};
