import React from "react";
import { Outlet, useNavigate, useMatches } from "@tanstack/react-router";
import { useSubscription } from "../hooks";
import { useOrganization } from "../features/organization/hooks/useOrganization";

const AuthWrapper: React.FC = () => {
  const navigate = useNavigate();
  const matches = useMatches();
  const { data: organizationId } = useOrganization();
  const { isActive, subscription, error: subscriptionError } = useSubscription(organizationId || undefined);

  // List of routes that should be accessible without an active subscription
  const allowedRoutes = [
    '/settings/subscription',
    '/settings/organization',
    '/settings/profile',
    '/create-organization',
    '/accept-invite',
    '/pending-invitations',
    '/onboarding',
  ];

  // Check if current route is in allowed routes
  const isAllowedRoute = allowedRoutes.some(route => 
    matches.some(match => match.pathname.startsWith(route))
  );

  React.useEffect(() => {
    // Only redirect if we have loaded the subscription data and there's no error
    if (subscription && !subscriptionError && !isActive && !isAllowedRoute && organizationId && 
        !matches.some(match => match.pathname === '/settings/subscription')) {
      navigate({ to: '/settings/subscription' });
    }
  }, [isActive, isAllowedRoute, organizationId, navigate, matches, subscription, subscriptionError]);

  return <Outlet />;
};

export default AuthWrapper;