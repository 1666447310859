import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { useOrganization } from '../../../hooks';
import { InvoiceData, Client, BrandSettings, Organization, EmailTracking } from '../../../types';

export const useInvoice = (id?: string, options?: { 
  initialData?: InvoiceData,
  organization?: Organization 
}) => {
  const { data: organizationId } = useOrganization();

  return useQuery({
    queryKey: ['invoice', id, organizationId],
    queryFn: async () => {
      if (!id) return null;
      if (!options?.organization) throw new Error('Organization data is required');
      
      try {
        // Fetch main invoice data, items, and payments in parallel
        const [
          invoiceResponse,
          itemsResponse,
          paymentsResponse
        ] = await Promise.all([
          supabase
            .from('invoices')
            .select('*')
            .eq('id', id)
            .single(),
          supabase
            .from('invoice_items')
            .select('*')
            .eq('invoice_id', id)
            .order('order'),
          supabase
            .from('payments')
            .select('*')
            .eq('invoice_id', id)
        ]);

        const { data: invoiceData, error: invoiceError } = invoiceResponse;
        const { data: itemsData, error: itemsError } = itemsResponse;
        const { data: paymentsData, error: paymentsError } = paymentsResponse;

        if (invoiceError) throw invoiceError;
        if (itemsError) throw itemsError;
        if (paymentsError) throw paymentsError;

        // Attempt to fetch email tracking data separately with error handling
        let emailTrackingData: EmailTracking[] = [];
        try {
          const { data: trackingData, error: trackingError } = await supabase
            .from('email_tracking')
            .select(`
              id,
              tracking_id,
              invoice_id,
              recipient_email,
              sent_at,
              opened,
              opened_at,
              open_count,
              open_history
            `)
            .eq('invoice_id', id)
            .order('sent_at', { ascending: false });

          if (!trackingError && trackingData) {
            emailTrackingData = trackingData;
          } else {
            console.warn('Failed to fetch email tracking data:', trackingError);
          }
        } catch (error) {
          console.warn('Error fetching email tracking data:', error);
        }

        return {
          ...invoiceData,
          items: itemsData || [],
          payments: paymentsData || [],
          email_tracking: emailTrackingData,
          logo_url: options.organization.logo_url,
        };
      } catch (error) {
        console.error('Error fetching invoice data:', error);
        throw error;
      }
    },
    enabled: !!id && !!organizationId && !!options?.organization,
    initialData: options?.initialData,
    staleTime: 30000,
    gcTime: 5 * 60 * 1000,
  });
};

export const useClients = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Client[]>({
    queryKey: ['clients', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId)
        .order('full_name', { ascending: true });
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
};

export const useBrandSettings = () => {
  const { data: organizationId } = useOrganization();
  const { data: orgDetails } = useOrganizationDetails(organizationId);

  return useQuery<BrandSettings>({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      return orgDetails?.brand_settings || {
        default_template: 'minimalist',
        default_font: 'Inter',
        default_header_color: '#ffffff',
        default_header_text_color: '#000000',
        default_background_color: '#ffffff',
        default_body_text_color: '#000000',
      };
    },
    enabled: !!orgDetails,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
};

export const useOrganizationDetails = (organizationId: string | undefined) => {
  return useQuery({
    queryKey: ['organizationDetails', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');
      
      const { data, error } = await supabase
        .from('organizations')
        .select(`
          *,
          stripe_account_id,
          stripe_account_verified,
          brand_settings (*)
        `)
        .eq('id', organizationId)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
};
