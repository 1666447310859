import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { supabase } from '../../../supabaseClient';
import { Task } from '../../../types';
import { useOrganization } from '../../../hooks';

export const useTasks = (projectId?: string) => {
  const { data: organizationId } = useOrganization();
  const localUpdatesRef = useRef(new Map<string, boolean>());

  const query = useQuery<Task[], Error>({
    queryKey: ['tasks', organizationId, projectId],
    queryFn: async () => {
      if (!organizationId) return [];
      
      let query = supabase
        .from('tasks')
        .select('*')
        .eq('organization_id', organizationId);

      if (projectId) {
        query = query.eq('project_id', projectId);
      }

      const { data, error } = await query.order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  return {
    ...query,
    localUpdatesRef,
  };
}; 