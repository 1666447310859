import React, { useState } from 'react';
import { Link, useLocation } from '@tanstack/react-router';
import styled from 'styled-components';
import { Invoice12, Check12, Client12, Draft12, LeftSidebar16, Clock12, Project12 } from '../shared/components/Icon';
import OrganizationSelector from '../features/organization/components/OrganizationSelector';

const Overlay = styled.div<{ $isOpen: boolean }>`
  display: none;
  
  @media (max-width: 768px) {
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
`;

const StyledSidebar = styled.div<{ $isOpen: boolean }>`
  padding: 16px;
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0,0,0,0.05);
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    background-color: #F8F7F6;
    z-index: 9999;
    transform: ${({ $isOpen }) => ($isOpen ? 'translateX(0)' : 'translateX(-100%)')};
    box-shadow: ${({ $isOpen }) => ($isOpen ? '0 0 10px rgba(0,0,0,0.1)' : 'none')};
  }
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const StyledLink = styled(Link)<{ $active: boolean }>`
  padding: 4px 12px;
  border-radius: 8px;
  background-color: ${({ $active }) => ($active ? 'rgba(0,0,0,0.05)' : 'transparent')};
  color: ${({ $active }) => ($active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)')};
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
  user-select: none;

  svg path {
    fill: ${({ $active }) => ($active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)')};
  }

  &:hover {
    background-color: rgba(0,0,0,0.1);
    color: rgba(0,0,0,0.8);

    svg path {
      fill: rgba(0,0,0,0.8);
    }
  }

  &:active {
    transform: scale(0.99);
  }  
`;

const SidebarBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 32px;
  left: 8px;
  z-index: 1001;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SidebarLeft: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Overlay $isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <ToggleButton onClick={toggleSidebar}>
        <LeftSidebar16 />
      </ToggleButton>
      <StyledSidebar $isOpen={isOpen}>
        <LinkList>
          <OrganizationSelector />
          <StyledLink to="/" $active={isActive('/')} onClick={() => setIsOpen(false)}>
            <Invoice12 />Invoices
          </StyledLink>
          <StyledLink to="/time" $active={isActive('/time')} onClick={() => setIsOpen(false)}><Clock12 />Time</StyledLink>
          <StyledLink to="/clients" $active={isActive('/clients')} onClick={() => setIsOpen(false)}><Client12 />Clients</StyledLink>
          <StyledLink to="/projects" $active={isActive('/projects')} onClick={() => setIsOpen(false)}><Project12 />Projects</StyledLink>
          <StyledLink to="/expenses" $active={isActive('/expenses')} onClick={() => setIsOpen(false)}><Draft12 />Expenses</StyledLink>
          <StyledLink to="/tasks" $active={isActive('/tasks')} onClick={() => setIsOpen(false)}><Check12 />Tasks</StyledLink>
        </LinkList>
        <SidebarBottom>
          <StyledLink to="/brand" $active={isActive('/brand')} onClick={() => setIsOpen(false)}>Brand</StyledLink>
          <StyledLink to="/payments" $active={isActive('/payments')} onClick={() => setIsOpen(false)}>Payments</StyledLink>
          <StyledLink to="/settings" $active={isActive('/settings')} onClick={() => setIsOpen(false)}>Settings</StyledLink>
        </SidebarBottom>
      </StyledSidebar>
    </>
  );
};

export default SidebarLeft;