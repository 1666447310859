import React, { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import axios from 'axios';
import { supabase } from '../../../supabaseClient';
import StripeDrawer from './StripeDrawer';

const StripeConnectSuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          throw new Error('No active session');
        }

        console.log('Verifying Stripe account...');
        const response = await axios.post('/api/verify-stripe-account', {}, {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        });

        console.log('Verification response:', response.data);

        if (response.status === 200) {
          console.log('Stripe account verified successfully');
          navigate({ to: '/' });
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error verifying Stripe account:', error);
        if (axios.isAxiosError(error)) {
          console.error('Response data:', error.response?.data);
        }
      }
    };

    verifyAccount();
  }, [navigate]);

  return <StripeDrawer isOpen={true} setIsOpen={() => navigate({ to: '/' })} verificationInProgress={true} />;
};

export default StripeConnectSuccess;
