import React from 'react';
import BasePromptModal from '../../../shared/components/BasePromptModal';

interface AIInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGenerate: (prompt: string) => Promise<void>;
  isLoading: boolean;
}

const AIInvoiceModal: React.FC<AIInvoiceModalProps> = ({
  isOpen,
  onClose,
  onGenerate,
  isLoading
}) => {
  return (
    <BasePromptModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onGenerate}
      isLoading={isLoading}
      placeholder="Describe the invoice you want to generate..."
      loadingText="Creating invoice..."
    />
  );
};

export default AIInvoiceModal;
