import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { useAuth } from '../../../hooks';

export const useOrganization = () => {
  const { data: user } = useAuth();

  return useQuery({
    queryKey: ['organization', user?.id],
    queryFn: async () => {
      if (!user) return null;
      console.log('Fetching organization for user:', user.id);

      // Get current user with metadata
      const { data: { user: fetchedUser }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!fetchedUser) return null;

      // Try to get organization from metadata first
      const currentOrganizationId = fetchedUser.user_metadata.current_organization_id;
      console.log('Current organization ID from metadata:', currentOrganizationId);
      
      if (currentOrganizationId) {
        // First verify the organization exists
        const { data: org, error: orgError } = await supabase
          .from('organizations')
          .select('id')
          .eq('id', currentOrganizationId)
          .single();

        console.log('Organization check:', { org, orgError });

        if (!orgError && org) {
          // Then verify user has access using organization_access table
          const { data: access, error: accessError } = await supabase
            .from('organization_access')
            .select('organization_id')
            .eq('user_id', fetchedUser.id)
            .eq('organization_id', currentOrganizationId)
            .single();

          console.log('Access check:', { access, accessError });

          if (!accessError && access) {
            console.log('Found organization from metadata:', access.organization_id);
            return currentOrganizationId;
          }
        }
        
        console.log('Organization from metadata not found or access denied, clearing metadata');
        // Clear invalid organization ID
        await supabase.auth.updateUser({
          data: { current_organization_id: null }
        });
      }

      // Get user's profile to check onboarding status
      const { data: profile } = await supabase
        .from('profiles')
        .select('onboarding_completed')
        .eq('id', fetchedUser.id)
        .single();

      // Try to find any organization access
      const { data: access, error: accessError } = await supabase
        .from('organization_access')
        .select('organization_id')
        .eq('user_id', fetchedUser.id)
        .limit(1)
        .single();

      console.log('Fallback access check:', { access, accessError });

      if (!accessError && access) {
        console.log('Found organization from access:', access.organization_id);
        // Update their current organization
        await supabase.auth.updateUser({
          data: { current_organization_id: access.organization_id }
        });
        return access.organization_id;
      }

      // If no organization access found and onboarding is not complete, return null
      if (!profile?.onboarding_completed) {
        console.log('No organization found and onboarding not completed');
        return null;
      }

      // Check for pending invitations only if no organization access exists
      const { data: pendingInvitations } = await supabase
        .from('pending_invitations')
        .select('id')
        .eq('email', fetchedUser.email)
        .maybeSingle();

      if (pendingInvitations) {
        console.log('Found pending invitations');
        return 'pending_invitations';
      }

      console.log('No organization found');
      return null;
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
  });
};