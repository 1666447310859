import React, { useCallback, useEffect, useRef } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import Button from '../shared/components/Button';
import { Close12 } from '../shared/components/Icon';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  outline: none;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #F8F7F6;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const CloseButton = styled(Button)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const ApplyButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

interface FilterDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onApply: () => void;
  children: React.ReactNode;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ isOpen, setIsOpen, onApply, children }) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);

      if (!isClickInsideDrawer) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleOpenChange]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <h2>Filters</h2>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <div className="filter-content">
              {children}
            </div>
            <ApplyButton onClick={onApply}>Apply Filters</ApplyButton>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default FilterDrawer;