import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './Button';
import TextArea from '../../components/TextArea';
import { ArrowRightLine12 } from './Icon';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const AnimatedOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled(motion.div)<{ $size?: 'small' | 'medium' | 'large'; $isLoading?: boolean }>`
  background: white;
  border-radius: 16px;
  max-width: 90vw;
  height: 56px;
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`;

const overlayVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    }
  }
};

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    y: 20
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30,
      mass: 1
    }
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: 10,
    transition: {
      duration: 0.2
    }
  }
};

const contentVariants = {
  loading: {
    width: '256px',
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 35,
      mass: 1
    }
  },
  input: {
    width: '600px',
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 35,
      mass: 1
    }
  }
};

const StyledTextArea = styled(TextArea)`
  height: 56px;
  box-sizing: border-box;
  border: 0;
  resize: none;
  padding: 16px 24px;
  font-size: 16px;
  background-color: transparent;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding-right: 16px;
`;

const StyledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
`;

interface BasePromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (prompt: string) => Promise<void>;
  isLoading: boolean;
  placeholder?: string;
  loadingText?: string;
}

export default function BasePromptModal({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  placeholder = "Type your prompt...",
  loadingText = "Processing..."
}: BasePromptModalProps) {
  const [prompt, setPrompt] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (isOpen && !isLoading && textAreaRef.current) {
      setTimeout(() => {
        textAreaRef.current?.focus();
      }, 100);
    }
  }, [isOpen, isLoading]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim() || isLoading) return;
    await onSubmit(prompt);
    setPrompt('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <AnimatedOverlay
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            onClick={handleOverlayClick}
          >
            <ModalContent
              $size="medium"
              $isLoading={isLoading}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
            >
              <motion.div
                initial={false}
                animate={isLoading ? "loading" : "input"}
                variants={contentVariants}
                style={{ 
                  width: '100%',
                  maxWidth: '90vw'
                }}
              >
                {isLoading ? (
                  <LoadingContainer>
                    <AnimatePresence mode="wait">
                      <motion.div
                        key="loading-text"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {loadingText}
                      </motion.div>
                    </AnimatePresence>
                  </LoadingContainer>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <StyledTextArea
                      ref={textAreaRef}
                      value={prompt}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPrompt(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder={placeholder}
                      disabled={isLoading}
                    />
                    <StyledButton
                      buttonType="icon"
                      type="submit"
                      disabled={!prompt.trim() || isLoading}
                    >
                      <ArrowRightLine12 />
                    </StyledButton>
                  </Form>
                )}
              </motion.div>
            </ModalContent>
          </AnimatedOverlay>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
} 