import React, { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useCreateOrganization } from '../../../hooks';
import Button from '../../../shared/components/Button';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CreateOrganizationPage: React.FC = () => {
  const [name, setName] = useState('');
  const createOrganization = useCreateOrganization();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createOrganization.mutate({ name }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['organization'] });
        navigate({ to: '/' });
      },
    });
  };

  return (
    <PageContainer>
      <h1>Create Organization</h1>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Organization Name"
          required
        />
        <Button type="submit">Create Organization</Button>
      </Form>
    </PageContainer>
  );
};

export default CreateOrganizationPage;