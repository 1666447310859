import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { useOrganization } from '../../../hooks';
import { TimeEntry, Expense, InvoiceItem, Service, Invoice } from '../../../types';

interface CreateInvoiceParams {
  client_id?: string | null;
  currency?: string;
  project_id?: string;
  organization_id: string;
  timeEntries?: TimeEntry[];
  expenses?: Expense[];
  invoice_number?: string;
}

type PartialInvoiceItem = Omit<InvoiceItem, 'id'> & { id?: string };

// Memoized helper functions
const createInvoiceItems = (timeEntries: TimeEntry[], services: Service[], newInvoiceId: string): PartialInvoiceItem[] =>
  timeEntries.map((entry, index) => ({
    invoice_id: newInvoiceId,
    description: `${entry.service?.name || 'Service'} - ${entry.project?.name || 'Project'}`,
    quantity: entry.duration / 3600,
    price: services.find(s => s.id === entry.service_id)?.price || 0,
    taxable: false,
    order: index,
  }));

const createExpenseItems = (expenses: Expense[], newInvoiceId: string): PartialInvoiceItem[] =>
  expenses.map((expense, index) => ({
    invoice_id: newInvoiceId,
    description: `${expense.merchant || 'Unknown Merchant'}${expense.description ? `: ${expense.description}` : ''}`,
    quantity: 1,
    price: expense.amount,
    taxable: false,
    order: index,
  }));

const calculateInvoiceTotals = (invoiceItems: InvoiceItem[], taxRate: number) => {
  const subtotal = invoiceItems.reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxableAmount = invoiceItems
    .filter(item => item.taxable)
    .reduce((sum, item) => sum + item.quantity * item.price, 0);
  return {
    subtotal,
    total: subtotal + ((taxableAmount * taxRate) / 100)
  };
};

export const useCreateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId, error: organizationError } = useOrganization();

  return useMutation({
    mutationFn: async (params: CreateInvoiceParams) => {
      if (!organizationId) throw new Error('User is not associated with an organization');
      if (organizationError) throw new Error('Failed to fetch organization');

      // Fetch all required data in parallel
      const [{ data: { user } }, { data: brandSettings }] = await Promise.all([
        supabase.auth.getUser(),
        supabase
          .from('brand_settings')
          .select('*')
          .eq('organization_id', organizationId)
          .single()
      ]);

      if (!user) throw new Error('User not authenticated');
      if (!brandSettings) throw new Error('Brand settings not found');

      const newInvoice = {
        subtotal: 0,
        amount_due: 0,
        invoice_date: new Date().toISOString(),
        due_date: new Date(Date.now() + (brandSettings.default_due_days || 30) * 86400000).toISOString(),
        invoice_created_at: new Date().toISOString(),
        invoice_number: params.invoice_number,
        user_id: user.id,
        organization_id: organizationId,
        client_id: params.client_id || null,
        project_id: params.project_id || null,
        invoice_template: brandSettings.default_template || 'minimalist',
        font: brandSettings.default_font || 'Inter',
        header_color: brandSettings.default_header_color || '#ffffff',
        header_text_color: brandSettings.default_header_text_color || '#000000',
        background_color: brandSettings.default_background_color || '#ffffff',
        body_text_color: brandSettings.default_body_text_color || '#000000',
        tax_rate: brandSettings.default_tax_rate || 0,
        status: 'draft',
        due_days: brandSettings.default_due_days || 30,
        currency: params.currency || brandSettings.default_currency || 'USD',
        payment_terms: brandSettings.default_payment_terms || '',
      };

      const hasItems = params.timeEntries?.length || params.expenses?.length;
      
      if (!hasItems) {
        const { data: invoiceData } = await supabase
          .from('invoices')
          .insert([newInvoice])
          .select()
          .single();

        if (!invoiceData) throw new Error('Failed to create invoice');

        await supabase
          .from('invoice_items')
          .insert([{
            invoice_id: invoiceData.id,
            description: '',
            quantity: 1,
            price: 0,
            taxable: false,
            order: 0,
          }]);

        return invoiceData;
      }

      // Process items in parallel
      const [{ data: invoiceData }, { data: services }] = await Promise.all([
        supabase
          .from('invoices')
          .insert([newInvoice])
          .select()
          .single(),
        supabase
          .from('services')
          .select('*')
          .eq('organization_id', organizationId)
      ]);

      if (!invoiceData) throw new Error('Failed to create invoice');

      const invoiceItems = [
        ...(params.timeEntries ? createInvoiceItems(params.timeEntries, services || [], invoiceData.id) : []),
        ...(params.expenses ? createExpenseItems(params.expenses, invoiceData.id) : [])
      ];

      const { data: insertedItems } = await supabase
        .from('invoice_items')
        .insert(invoiceItems)
        .select();

      if (!insertedItems) throw new Error('Failed to create invoice items');

      // Create junction entries in parallel if needed
      await Promise.all([
        ...(params.timeEntries?.length ? [
          supabase
            .from('time_entry_invoice_items')
            .insert(params.timeEntries.map((entry, index) => ({
              time_entry_id: entry.id,
              invoice_item_id: insertedItems[index].id,
            })))
        ] : []),
        ...(params.expenses?.length ? [
          supabase
            .from('expense_invoice_items')
            .insert(params.expenses.map((expense, index) => ({
              expense_id: expense.id,
              invoice_item_id: insertedItems[params.timeEntries?.length || 0 + index].id,
              amount: expense.amount,
            })))
        ] : [])
      ]);

      const { subtotal, total } = calculateInvoiceTotals(insertedItems as InvoiceItem[], invoiceData.tax_rate);
      const { data: updatedInvoice } = await supabase
        .from('invoices')
        .update({ subtotal, amount_due: total })
        .eq('id', invoiceData.id)
        .select()
        .single();

      return updatedInvoice;
    },
    onSuccess: (newInvoice) => {
      // Batch updates to reduce re-renders
      queryClient.setQueriesData({ queryKey: ['invoices'] }, (old: Invoice[] | undefined) => 
        old ? [newInvoice, ...old] : [newInvoice]
      );
      
      if (newInvoice.project_id) {
        queryClient.setQueriesData(
          { queryKey: ['projectInvoices', newInvoice.project_id] },
          (old: Invoice[] | undefined) => old ? [newInvoice, ...old] : [newInvoice]
        );
      }
      
      // Invalidate affected queries in batch
      queryClient.invalidateQueries({
        queryKey: [
          ['invoices'],
          ['projectInvoices', newInvoice.project_id],
          ['expenses']
        ]
      });
    },
  });
};
