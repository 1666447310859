import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { Expense, Tax } from '../../../types';
import { useOrganization } from '../../../hooks';

export const useExpenseMutations = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  // Helper function to invalidate related queries
  const invalidateRelatedQueries = (expense?: Partial<Expense>) => {
    // Always invalidate organization expenses
    queryClient.invalidateQueries({ 
      queryKey: ['expenses', organizationId],
      exact: false
    });

    // Invalidate taxes
    queryClient.invalidateQueries({ 
      queryKey: ['taxes'],
      exact: false
    });

    // If we have an expense, invalidate related project and client queries
    if (expense) {
      if (expense.project_id) {
        queryClient.invalidateQueries({ 
          queryKey: ['projectExpenses', expense.project_id],
          exact: false
        });
      }
      if (expense.client_id) {
        queryClient.invalidateQueries({ 
          queryKey: ['clientExpenses', expense.client_id],
          exact: false
        });
      }
    }
  };

  // Helper function to handle tax operations
  const handleTaxOperations = async (expenseId: string, taxes: Tax[] = []) => {
    if (!taxes.length) return;

    // Delete existing taxes
    const { error: deleteError } = await supabase
      .from('taxes')
      .delete()
      .eq('expense_id', expenseId);

    if (deleteError) throw deleteError;

    // Insert new taxes
    const { error: insertError } = await supabase
      .from('taxes')
      .insert(taxes.map(tax => ({
        expense_id: expenseId,
        name: tax.name,
        amount: tax.amount
      })));

    if (insertError) throw insertError;
  };

  const updateExpenseMutation = useMutation({
    mutationFn: async (data: Partial<Expense>) => {
      console.log('Mutation function called with data:', data);
      if (!data.id || !data.organization_id) {
        throw new Error('Missing required fields for update');
      }

      const { taxes, ...expenseData } = data;
      console.log('Sending to supabase:', expenseData);

      // Update expense
      const { data: result, error } = await supabase
        .from('expenses')
        .update(expenseData)
        .eq('id', data.id)
        .select(`
          *,
          client:clients!expenses_client_id_fkey(id, full_name),
          project:projects!expenses_project_id_fkey(id, name),
          expense_invoice_items(id, invoice_item_id, amount, invoice_item:invoice_items(invoice_id)),
          taxes(id, name, amount)
        `)
        .single();
      
      if (error) {
        console.error('Supabase update error:', error);
        throw error;
      }
      
      console.log('Received result from supabase:', result);

      // Handle taxes if provided
      if (taxes) {
        await handleTaxOperations(data.id, taxes);
      }

      return result;
    },
    onMutate: async (data) => {
      console.log('onMutate called with data:', data);
      await queryClient.cancelQueries({ 
        queryKey: ['expenses', organizationId],
        exact: false 
      });

      const previousExpenses = queryClient.getQueryData<Expense[]>(['expenses', organizationId]);

      if (previousExpenses) {
        // Create a new array with the same order, just update the matching expense
        const updatedExpenses = previousExpenses.map(expense => {
          if (expense.id === data.id) {
            return {
              ...expense,
              ...data,
              // Preserve any fields that might be undefined in the update data
              merchant: data.merchant ?? expense.merchant,
              amount: data.amount ?? expense.amount,
              date: data.date ?? expense.date,
              description: data.description ?? expense.description,
              client_id: data.client_id ?? expense.client_id,
              project_id: data.project_id ?? expense.project_id,
              category: data.category ?? expense.category,
              receipt_image_path: data.receipt_image_path ?? expense.receipt_image_path,
              taxes: data.taxes ?? expense.taxes
            };
          }
          return expense;
        });

        console.log('Updating query data with:', updatedExpenses);
        queryClient.setQueryData<Expense[]>(['expenses', organizationId], updatedExpenses);
      }

      return { previousExpenses };
    },
    onError: (_error, _data, context) => {
      if (context?.previousExpenses) {
        queryClient.setQueryData(['expenses', organizationId], context.previousExpenses);
      }
    },
    onSettled: () => {
      invalidateRelatedQueries();
    },
  });

  const createExpenseMutation = useMutation({
    mutationFn: async (newExpense: Omit<Expense, 'id'>) => {
      if (!newExpense.organization_id) throw new Error("No organization found");

      const { taxes, ...expenseData } = newExpense;

      // Create expense
      const { data, error } = await supabase
        .from('expenses')
        .insert(expenseData)
        .select(`
          *,
          client:clients!expenses_client_id_fkey(id, full_name),
          project:projects!expenses_project_id_fkey(id, name),
          expense_invoice_items(id, invoice_item_id, amount, invoice_item:invoice_items(invoice_id))
        `)
        .single();

      if (error) throw error;

      // Handle taxes if provided
      if (taxes && taxes.length > 0 && data.id) {
        await handleTaxOperations(data.id, taxes);
      }

      return data as Expense;
    },
    onMutate: async (newExpense) => {
      await queryClient.cancelQueries({ 
        queryKey: ['expenses', newExpense.organization_id],
        exact: false 
      });

      const previousExpenses = queryClient.getQueryData<Expense[]>(['expenses', newExpense.organization_id]);

      if (previousExpenses) {
        const optimisticExpense: Expense = {
          ...newExpense,
          id: 'temp-id-' + Date.now(),
          client: undefined,
          project: undefined,
          expense_invoice_items: [],
          taxes: [],
          date: newExpense.date || new Date().toISOString().split('T')[0],
        } as Expense;

        queryClient.setQueryData<Expense[]>(['expenses', newExpense.organization_id], 
          [optimisticExpense, ...previousExpenses]
        );
      }

      return { previousExpenses };
    },
    onError: (_error, newExpense, context) => {
      if (context?.previousExpenses) {
        queryClient.setQueryData(['expenses', newExpense.organization_id], context.previousExpenses);
      }
    },
    onSettled: (data) => {
      if (data) {
        invalidateRelatedQueries(data);
      }
    },
  });

  const deleteExpenseMutation = useMutation({
    mutationFn: async (expenseId: string) => {
      const { error } = await supabase
        .from('expenses')
        .delete()
        .eq('id', expenseId);

      if (error) throw error;
      return expenseId;
    },
    onMutate: async (expenseId) => {
      await queryClient.cancelQueries({ 
        queryKey: ['expenses', organizationId],
        exact: false 
      });

      const previousExpenses = queryClient.getQueryData<Expense[]>(['expenses', organizationId]);

      if (previousExpenses) {
        const expenseToDelete = previousExpenses.find(e => e.id === expenseId);
        queryClient.setQueryData<Expense[]>(
          ['expenses', organizationId],
          previousExpenses.filter(e => e.id !== expenseId)
        );
        return { previousExpenses, expenseToDelete };
      }
      return { previousExpenses };
    },
    onError: (_error, _expenseId, context) => {
      if (context?.previousExpenses) {
        queryClient.setQueryData(['expenses', organizationId], context.previousExpenses);
      }
    },
    onSettled: () => {
      invalidateRelatedQueries();
    },
  });

  const bulkDeleteExpensesMutation = useMutation({
    mutationFn: async (expenseIds: string[]) => {
      const { error } = await supabase
        .from('expenses')
        .delete()
        .in('id', expenseIds);

      if (error) throw error;
      return expenseIds;
    },
    onMutate: async (expenseIds) => {
      await queryClient.cancelQueries({ 
        queryKey: ['expenses', organizationId],
        exact: false 
      });

      const previousExpenses = queryClient.getQueryData<Expense[]>(['expenses', organizationId]);

      if (previousExpenses) {
        queryClient.setQueryData<Expense[]>(
          ['expenses', organizationId],
          previousExpenses.filter(e => !expenseIds.includes(e.id))
        );
      }

      return { previousExpenses };
    },
    onError: (_error, _expenseIds, context) => {
      if (context?.previousExpenses) {
        queryClient.setQueryData(['expenses', organizationId], context.previousExpenses);
      }
    },
    onSettled: () => {
      invalidateRelatedQueries();
    },
  });

  return {
    updateExpenseMutation,
    createExpenseMutation,
    deleteExpenseMutation,
    bulkDeleteExpensesMutation,
  };
}; 