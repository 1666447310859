import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { Invoice, InvoiceData, Filter, FilterOption, FilterId } from '../../../types';
import DataTable from '../../../components/DataTable';
import { StatusBadge } from '../../../shared/components/StatusBadge';
import Tabs from '../../../shared/components/Tabs';
import DateRangePicker from '../../../components/DateRangePicker';
import { Calendar12, Client12, Invoice32 } from '../../../shared/components/Icon';
import { StatusUnpaid14, StatusPaid14, StatusOverdue14 } from '../../../shared/components/Icon';
import { isWithinInterval, startOfDay, endOfDay, format, isThisYear, subDays, startOfMonth, endOfMonth, subMonths, subYears, differenceInCalendarDays } from 'date-fns';
import MultipleEntityPicker from '../../../components/MultipleEntityPicker';
import { useQueryClient } from '@tanstack/react-query';
import FilterDrawer from '../../../components/FilterDrawer';
import FilterBox from '../../../components/FilterBox';
import FilterPicker from '../../../components/FilterPicker';
import SummaryCards from '../../../shared/components/SummaryCards';
import AIInvoiceModal from '../components/AIInvoiceModal';
import SelectedInvoicesOverlay from '../components/SelectedInvoicesOverlay';
import { exportInvoicesToCSV, downloadCSV } from '../../../utils/csvExport';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import InvoiceDrawer from '../components/InvoiceDrawer';
import InvoiceDetails from '../components/InvoiceDetails';
import { 
  useCreateInvoiceMutation,
  useInvoices,
  useClients,
  useOrganization,
  usePayments,
  useMediaQuery,
  usePageContext,
  useEmailTrackingData,
  useAIInvoiceMutation
} from '../../../hooks';
import { useBulkDeleteInvoicesMutation } from '../../../hooks';

const InvoiceListContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const InvoiceList: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const navigate = useNavigate();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationId } = useOrganization();
  
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();
  const { data: payments } = usePayments();
  const { data: clients } = useClients();
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState('all');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'due_date', desc: false }
  ]);
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const aiInvoiceMutation = useAIInvoiceMutation();

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const columnVisibilityConfig = useMemo(() => [
    { breakpoint: 1200, hidden: ['invoice_date', 'due_date'] },
    { breakpoint: 900, hidden: ['invoice_date', 'due_date', 'amount_due'] },
    { breakpoint: 600, hidden: ['invoice_date', 'due_date', 'amount_due', 'status'] },
    { breakpoint: 400, hidden: ['invoice_date', 'due_date', 'amount_due', 'status'] },
  ], []);

  const handleCreateInvoice = useCallback(async () => {
    try {
      if (!organizationId) {
        console.error('No organization selected');
        return;
      }
      const newInvoice = await createInvoiceMutation.mutateAsync({ organization_id: organizationId });
      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  }, [organizationId, createInvoiceMutation, navigate]);

  const formatCurrency = useCallback((amount: number, currency?: string) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: currency || 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }, []);

  const getClientName = useCallback((clientId?: string) => {
    const client = clients?.find(client => client.id === clientId);
    return client ? client.full_name : 'No Client';
  }, [clients]);

  const getInvoiceStatus = useCallback((invoice: Invoice) => {
    if (invoice.status === 'paid') return 'paid';
    if (invoice.status === 'draft') return 'draft';
    
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    
    // Set both dates to the start of the day for accurate comparison
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    if (dueDate.getTime() === today.getTime()) {
      return 'unpaid';  // Due today is considered unpaid
    } else if (dueDate.getTime() < today.getTime()) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  const columns: ColumnDef<Invoice>[] = useMemo(
    () => [
      {
        accessorKey: 'client_id',
        header: 'Client',
        cell: ({ getValue }) => getClientName(getValue() as string),
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice #',
        cell: ({ getValue }) => getValue() ? getValue() as string : '–',
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue, row }) => {
          const dueDate = new Date(getValue() as string);
          const today = new Date();
          const diffDays = differenceInCalendarDays(dueDate, today);
          const formattedDate = format(dueDate, isThisYear(dueDate) ? 'MMM d' : 'MMM d, yyyy');
          
          const invoice = row.original;
          const status = getInvoiceStatus(invoice);
          
          let statusText = '';
          if (status !== 'paid') {
            if (diffDays < 0) {
              statusText = `(late ${Math.abs(diffDays)} day${Math.abs(diffDays) !== 1 ? 's' : ''})`;
            } else if (diffDays === 0) {
              statusText = '(today)';
            } else if (diffDays === 1) {
              statusText = '(tomorrow)';
            } else {
              statusText = `(in ${diffDays} day${diffDays !== 1 ? 's' : ''})`;
            }
          }
          
          return (
            <span>
              {formattedDate}{' '}
              {statusText && <span style={{ color: 'rgba(0,0,0,0.5)' }}>{statusText}</span>}
            </span>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          const invoice = row.original;
          const status = getInvoiceStatus(invoice);
          return <StatusBadge $status={status}>{status}</StatusBadge>;
        },
      },
      {
        accessorKey: 'invoice_date',
        header: 'Invoice Date',
        cell: ({ getValue }) => {
          const date = new Date(getValue() as string);
          return format(date, isThisYear(date) ? 'MMM d' : 'MMM d, yyyy');
        },
      },
      {
        accessorKey: 'total',
        header: 'Total',
        cell: ({ getValue, row }) => formatCurrency(getValue() as number, row.original.currency),
        align: 'right',
      },
      {
        accessorKey: 'amount_due',
        header: 'Amount Due',
        cell: ({ getValue, row }) => formatCurrency(getValue() as number, row.original.currency),
        align: 'right',
      },
    ],
    [getClientName, formatCurrency, getInvoiceStatus]
  );

  const filterOptions = useMemo<FilterOption[]>(() => [
    { id: 'status', label: 'Status', type: 'select', options: ['draft', 'unpaid', 'paid', 'overdue'] },
    { id: 'client', label: 'Client', type: 'multipleEntity' },
    { 
      id: 'date', 
      label: 'Date', 
      type: 'dateRange',
      options: [
        'Last Week',
        'Last 30 Days',
        'This Month',
        'Last Month',
        'Last 6 Months',
        'Last Year'
      ]
    },
  ], []); // Empty dependency array as these options don't change

  const getFilterOptions = (filterId: string) => {
    const filter = filterOptions.find(f => f.id === filterId);
    return filter?.options;
  };

  const getDateRange = useCallback((option: string): { start: Date, end: Date } => {
    const now = new Date();
    switch (option) {
      case 'Last Week':
        return { start: startOfDay(subDays(now, 7)), end: endOfDay(now) };
      case 'Last 30 Days':
        return { start: startOfDay(subDays(now, 30)), end: endOfDay(now) };
      case 'This Month':
        return { start: startOfMonth(now), end: endOfDay(now) };
      case 'Last Month': {
        const lastMonth = subMonths(now, 1);
        return { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) };
      }
      case 'Last 6 Months':
        return { start: startOfDay(subMonths(now, 6)), end: endOfDay(now) };
      case 'Last Year':
        return { start: startOfDay(subYears(now, 1)), end: endOfDay(now) };
      default:
        return { start: startOfDay(now), end: endOfDay(now) };
    }
  }, []);

  const handleAddFilter = useCallback((filterId: FilterId) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: filterId === 'client' ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: FilterId) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: FilterId, value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const processedInvoices = useMemo(() => {
    if (!invoices) return [];
    
    let processed = invoices;

    // Apply filters in a single pass
    if (filters.length > 0 || activeTab !== 'all') {
      processed = processed.filter(invoice => {
        // Check active tab filter
        if (activeTab !== 'all') {
          const status = getInvoiceStatus(invoice);
          if (status !== activeTab) return false;
        }

        // Check all other filters
        return filters.every(filter => {
          switch (filter.id) {
            case 'status':
              return getInvoiceStatus(invoice) === filter.value;
            case 'client':
              return (filter.value as string[]).includes(invoice.client_id);
            case 'date': {
              const dateFilter = filter.value as { startDate: Date | null; endDate: Date | null } | string;
              if (typeof dateFilter === 'object' && 'startDate' in dateFilter) {
                const { startDate, endDate } = dateFilter;
                if (startDate && endDate) {
                  const invoiceDate = new Date(invoice.invoice_date);
                  return isWithinInterval(invoiceDate, { 
                    start: startOfDay(startDate), 
                    end: endOfDay(endDate) 
                  });
                }
              } else if (typeof dateFilter === 'string') {
                const { start, end } = getDateRange(dateFilter);
                const invoiceDate = new Date(invoice.invoice_date);
                return isWithinInterval(invoiceDate, { start, end });
              }
              return true;
            }
            default:
              return true;
          }
        });
      });
    }

    // Apply sorting in the same pass if needed
    if (sorting.length > 0) {
      const { id, desc } = sorting[0];
      processed.sort((a, b) => {
        if (id === 'due_date') {
          const dateA = new Date(a.due_date);
          const dateB = new Date(b.due_date);
          return desc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
        }
        // Add more sorting logic for other columns if needed
        return 0;
      });
    }

    return processed;
  }, [invoices, activeTab, filters, sorting, getDateRange]);

  const handleRowClick = useCallback((invoice: Invoice) => {
    // Simply set the selected invoice and open the drawer
    setSelectedInvoiceId(invoice.id);
    setIsDrawerOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsDrawerOpen(false);
      setSelectedInvoiceId(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseDrawer();
    }
  }, [handleCloseDrawer, isAnyPopoverOpen]);

  const calculateSummaries = useCallback(() => {
    if (!invoices || !payments) return { totalUnpaid: {}, totalPaidLast30Days: {}, totalOverdue: {} };

    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

    const paymentsByInvoice = payments.reduce((acc, payment) => {
      if (!acc[payment.invoice_id]) {
        acc[payment.invoice_id] = 0;
      }
      acc[payment.invoice_id] += payment.amount;
      return acc;
    }, {} as Record<string, number>);

    return invoices.reduce((acc, invoice) => {
      const currency = invoice.currency || 'USD';
      const paymentAmount = paymentsByInvoice[invoice.id] || 0;
      const remainingAmount = invoice.amount_due - paymentAmount;
      const status = getInvoiceStatus(invoice);

      // Initialize currency totals if they don't exist
      if (!acc.totalUnpaid[currency]) acc.totalUnpaid[currency] = 0;
      if (!acc.totalPaidLast30Days[currency]) acc.totalPaidLast30Days[currency] = 0;
      if (!acc.totalOverdue[currency]) acc.totalOverdue[currency] = 0;

      if (status === 'unpaid' || status === 'overdue') {
        acc.totalUnpaid[currency] += remainingAmount;
      }

      const invoicePayments = payments.filter(payment => payment.invoice_id === invoice.id);
      const paidInLast30Days = invoicePayments.reduce((sum, payment) => {
        return new Date(payment.payment_date) >= thirtyDaysAgo ? sum + payment.amount : sum;
      }, 0);
      acc.totalPaidLast30Days[currency] += paidInLast30Days;

      if (status === 'overdue') {
        acc.totalOverdue[currency] += remainingAmount;
      }

      return acc;
    }, { totalUnpaid: {}, totalPaidLast30Days: {}, totalOverdue: {} } as {
      totalUnpaid: Record<string, number>,
      totalPaidLast30Days: Record<string, number>,
      totalOverdue: Record<string, number>
    });
  }, [invoices, payments, getInvoiceStatus]);

  const formatMultiCurrencyAmount = useCallback((amounts: Record<string, number>) => {
    const entries = Object.entries(amounts);
    if (entries.length === 0) {
      return formatCurrency(0, 'USD'); // Show default currency if no amounts
    }

    // Sort entries by amount in descending order
    const sortedEntries = entries.sort(([, amountA], [, amountB]) => amountB - amountA);
    
    if (sortedEntries.every(([, amount]) => amount === 0)) {
      // If all amounts are zero, show the first currency with zero
      const [firstCurrency] = sortedEntries[0];
      return formatCurrency(0, firstCurrency);
    }

    // Get non-zero amounts
    const nonZeroEntries = sortedEntries.filter(([, amount]) => amount > 0);
    if (nonZeroEntries.length === 0) {
      return formatCurrency(0, sortedEntries[0][0]);
    }

    // Format the largest amount normally
    const [primaryCurrency, primaryAmount] = nonZeroEntries[0];
    const primaryFormatted = formatCurrency(primaryAmount, primaryCurrency);

    // Format other amounts in smaller text
    const otherAmounts = nonZeroEntries.slice(1);
    if (otherAmounts.length === 0) {
      return primaryFormatted;
    }

    const otherAmountsFormatted = otherAmounts
      .map(([currency, amount]) => formatCurrency(amount, currency))
      .join(', ');

    return `${primaryFormatted}\n<span style="font-size: 0.75em; opacity: 0.7">${otherAmountsFormatted}</span>`;
  }, [formatCurrency]);

  const summaries = useMemo(() => calculateSummaries(), [calculateSummaries]);

  const transformInvoiceToInvoiceData = useCallback((invoice: Invoice | undefined): InvoiceData | null => {
    if (!invoice) return null;
    
    const invoiceData: InvoiceData = {
      id: invoice.id,
      invoice_number: invoice.invoice_number,
      subtotal: invoice.subtotal || 0, // Assume subtotal is already calculated
      tax_rate: 0, // You might need to add this to the Invoice type or fetch it separately
      amount_due: invoice.amount_due,
      invoice_date: invoice.invoice_date,
      due_date: invoice.due_date,
      items: [], // You might need to fetch invoice items separately
      invoice_template: 'minimalist', // You might need to add this to the Invoice type or set a default
      header_color: '#ffffff', // Set a default or fetch from brand settings
      header_text_color: '#000000', // Set a default or fetch from brand settings
      public_id: invoice.public_id, // Use the correct public_id
      client_id: invoice.client_id,
      payments: [], // You might need to fetch payments separately
      font: 'Inter', // Set a default or fetch from brand settings
      status: invoice.status,
      due_days: 30, // Set a default or calculate based on invoice_date and due_date
      currency: 'USD', // Set a default or add to Invoice type
      project_id: invoice.project_id,
      organization_id: organizationId || '',
      total: invoice.total || invoice.amount_due, // Use existing total or amount_due
      invoice_created_at: invoice.invoice_created_at,
      payment_terms: invoice.payment_terms || '', // Add payment terms with default value
    };

    return invoiceData;
  }, [organizationId]);

  const clientEntities = useMemo(() => {
    return clients?.map(client => ({
      id: client.id,
      name: client.full_name
    })) || [];
  }, [clients]);

  const handleApplyFilters = () => {
    setIsFilterDrawerOpen(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleSummaryCardClick = useCallback((tabId: string) => {
    setActiveTab(prevTab => prevTab === tabId ? 'all' : tabId);
  }, []);

  const tabCounts = useMemo(() => {
    if (!invoices) return {};
    return invoices.reduce((acc, invoice) => {
      const status = getInvoiceStatus(invoice);
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }, [invoices, getInvoiceStatus]);

  const tabs = useMemo(() => [
    { id: 'all', label: 'All', count: isLoadingInvoices ? 0 : undefined, isLoading: isLoadingInvoices },
    { id: 'draft', label: 'Draft', count: isLoadingInvoices ? 0 : (tabCounts['draft'] || 0), isLoading: isLoadingInvoices },
    { id: 'unpaid', label: 'Unpaid', count: isLoadingInvoices ? 0 : (tabCounts['unpaid'] || 0), isLoading: isLoadingInvoices },
    { id: 'overdue', label: 'Overdue', count: isLoadingInvoices ? 0 : (tabCounts['overdue'] || 0), isLoading: isLoadingInvoices },
    { id: 'paid', label: 'Paid', count: isLoadingInvoices ? 0 : (tabCounts['paid'] || 0), isLoading: isLoadingInvoices },
  ], [tabCounts, isLoadingInvoices]);

  const emptyState = useMemo(() => ({
    icon: <Invoice32 />,
    message: "No invoices found",
    subMessage: "Create a new invoice to get started",
    action: {
      label: "Create Invoice",
      onClick: handleCreateInvoice
    }
  }), [handleCreateInvoice]);

  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoiceId);

  const summaryCards = useMemo(() => [
    {
      title: "Unpaid",
      amount: isLoadingInvoices ? formatCurrency(0) : formatMultiCurrencyAmount(summaries.totalUnpaid),
      onClick: () => handleSummaryCardClick('unpaid'),
      isSelected: activeTab === 'unpaid',
      selectedBackgroundColor: '#FFC759',
      selectedForegroundColor: '#000000',
      icon: <StatusUnpaid14 />,
      isLoading: isLoadingInvoices
    },
    {
      title: "Overdue",
      amount: isLoadingInvoices ? formatCurrency(0) : formatMultiCurrencyAmount(summaries.totalOverdue),
      onClick: () => handleSummaryCardClick('overdue'),
      isSelected: activeTab === 'overdue',
      selectedBackgroundColor: '#CA4B39',
      selectedForegroundColor: '#FFFFFF',
      icon: <StatusOverdue14 />,
      isLoading: isLoadingInvoices
    },
    {
      title: "Paid (Last 30 Days)",
      amount: isLoadingInvoices ? formatCurrency(0) : formatMultiCurrencyAmount(summaries.totalPaidLast30Days),
      onClick: () => handleSummaryCardClick('paid'),
      isSelected: activeTab === 'paid',
      selectedBackgroundColor: '#4C7159',
      selectedForegroundColor: '#FFFFFF',
      icon: <StatusPaid14 />,
      isLoading: isLoadingInvoices
    }
  ], [summaries, formatMultiCurrencyAmount, handleSummaryCardClick, activeTab, isLoadingInvoices, formatCurrency]);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  const handleAIGenerate = useCallback(async (prompt: string) => {
    try {
      await aiInvoiceMutation.mutateAsync(prompt);
      setIsAIModalOpen(false);
    } catch (error) {
      console.error('Error generating invoice:', error);
      // Handle error (show toast/notification)
    }
  }, [aiInvoiceMutation]);

  const headerButtons = useMemo(() => (
    <>
      <Button 
        buttonType="secondary" 
        onClick={() => setIsAIModalOpen(true)}
      >
        Generate
      </Button>
      <Button 
        buttonType="primary" 
        onClick={handleCreateInvoice}
      >
        New Invoice
      </Button>
    </>
  ), [handleCreateInvoice]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Invoices",
      right: headerButtons,
    });
  }, [setPageHeaderProps]);

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  // Add this callback for handling selection changes
  const handleSelectionChange = useCallback((selectedIds: string[]) => {
    setSelectedInvoices(selectedIds);
  }, []);

  // Add this callback for handling bulk delete
  const bulkDeleteMutation = useBulkDeleteInvoicesMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleBulkDelete = useCallback(async () => {
    setIsDeleteModalOpen(true);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      await bulkDeleteMutation.mutateAsync(selectedInvoices);
      setSelectedInvoices([]);
      setIsDeleteModalOpen(false);
    } catch (error) {
      alert('Failed to delete invoices. Please try again.');
    }
  }, [selectedInvoices, bulkDeleteMutation]);

  const handleExportCSV = useCallback(() => {
    if (!invoices || !clients || !payments) return;
    
    const selectedInvoiceData = invoices.filter(invoice => selectedInvoices.includes(invoice.id));
    const csvContent = exportInvoicesToCSV(selectedInvoiceData, clients, payments);
    const timestamp = format(new Date(), 'yyyy-MM-dd_HH-mm');
    downloadCSV(csvContent, `invoices_export_${timestamp}.csv`);
  }, [selectedInvoices, invoices, clients, payments]);

  const selectedInvoice = invoices?.find(i => i.id === selectedInvoiceId);
  const transformedInvoice = selectedInvoice ? transformInvoiceToInvoiceData(selectedInvoice) : null;

  return (
    <InvoiceListContainer ref={containerRef}>
      <SummaryCards cards={summaryCards} />
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        actionButtons={
          <FilterContainer>
            <FilterBoxesWrapper>
              {filters.map(filter => (
                <FilterBox
                  key={filter.id}
                  filter={filter}
                  onRemove={() => handleRemoveFilter(filter.id)}
                  onChange={(value) => handleFilterChange(filter.id, value)}
                  options={getFilterOptions(filter.id)}
                  entities={filter.id === 'client' ? clientEntities : undefined}
                />
              ))}
            </FilterBoxesWrapper>
            <FilterPicker
              options={filterOptions}
              onAddFilter={handleAddFilter}
              activeFilters={filters.map(filter => filter.id)}
            />
          </FilterContainer>
        }
      />
      <TableWrapper>
        <DataTable<Invoice>
          columns={columns}
          data={processedInvoices}
          isLoading={isLoadingInvoices}
          onRowClick={handleRowClick}
          getRowKey={(invoice) => invoice.id || 'unknown'}
          onSelectionChange={handleSelectionChange}
          selectedRows={selectedInvoices}
          rowClassName="invoice-row"
          columnVisibility={columnVisibilityConfig}
          containerRef={containerRef}
          emptyState={emptyState}
          filters={filters}
          sorting={sorting}
          onSortingChange={handleSortingChange}
        />
      </TableWrapper>
      <InvoiceDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title="Invoice Details"
        selectedInvoice={transformedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
      >
        {transformedInvoice && (
          <InvoiceDetails
            key={selectedInvoiceId}
            invoice={transformedInvoice}
            clients={clients || []}
            navigate={navigate}
            queryClient={queryClient}
            payments={payments || []}
            emailTrackingData={emailTrackingData}
          />
        )}
      </InvoiceDrawer>
      {isSmallScreen && (
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          setIsOpen={setIsFilterDrawerOpen}
          onApply={handleApplyFilters}
        >
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
            onChange={(value) => handleFilterChange('client', value)}
            entities={clientEntities}
            label="Clients"
            icon={<Client12 />}
            placement="bottom-start"
          />
          <DateRangePicker
            selectedRange={dateRange}
            onChange={(newRange) => {
              setDateRange(newRange);
              handleFilterChange('date', newRange);
            }}
            label="Date Range"
            id="invoice-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
        </FilterDrawer>
      )}
      <AIInvoiceModal
        isOpen={isAIModalOpen}
        onClose={() => setIsAIModalOpen(false)}
        onGenerate={handleAIGenerate}
        isLoading={aiInvoiceMutation.isPending}
      />
      <SelectedInvoicesOverlay
        selectedCount={selectedInvoices.length}
        onDelete={handleBulkDelete}
        onClearSelection={() => setSelectedInvoices([])}
        onExportCSV={handleExportCSV}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete Invoices"
        message={`Are you sure you want to delete ${selectedInvoices.length} invoice${selectedInvoices.length === 1 ? '' : 's'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        isDestructive
      />
    </InvoiceListContainer>
  );
};

export default InvoiceList;
