import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { Organization } from '../../../types';
import { useOrganization } from '../../../hooks/';
import Button from '../../../shared/components/Button';
import { Close12 } from '../../../shared/components/Icon';
import { supabase } from '../../../supabaseClient';
import { useQueryClient } from "@tanstack/react-query";
import { getCountryOptions, getPostalCodeLabel, countries, CountryCode } from '../../../schemas/client';
import { organizationSchema } from '../../../schemas/organization';
import { z } from 'zod';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
  padding: 40px 40px 120px 40px;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  margin-bottom: 12px;
`;

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputField = styled.div<{ fullWidth?: boolean; hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.fullWidth ? '1 / -1' : 'auto'};

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input, select {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${props => props.hasError ? '#ff4d4f' : 'rgba(0,0,0,0.5)'};
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
  }
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #F8F7F6;
  border-radius: 0 0 8px 8px;
  gap: 8px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

interface OrganizationDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onOrganizationUpdated: (updatedOrganization: Organization) => void;
}

const validateOrganization = (organizationData: Partial<Organization>) => {
  try {
    organizationSchema.parse(organizationData);
    return {};
  } catch (error) {
    if (error instanceof z.ZodError) {
      const newErrors: Record<string, string> = {};
      error.errors.forEach((err) => {
        const field = err.path[0];
        if (typeof field === 'string') {
          newErrors[field] = err.message;
        }
      });
      return newErrors;
    }
    return {};
  }
};

const nullToEmpty = (value: string | null): string => value ?? '';

const OrganizationDrawer: React.FC<OrganizationDrawerProps> = ({ isOpen, setIsOpen, onOrganizationUpdated }) => {
  const { data: organizationId } = useOrganization();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchOrganization = async () => {
      if (organizationId) {
        const { data, error } = await supabase
          .from('organizations')
          .select('*')
          .eq('id', organizationId)
          .single();

        if (error) {
          console.error('Error fetching organization:', error);
        } else {
          setOrganization(data);
        }
      }
    };

    fetchOrganization();
  }, [organizationId]);

  const handleInputChange = (field: keyof Organization, value: string) => {
    setOrganization(prev => {
      if (!prev) return prev;

      let updatedOrg;
      if (field === 'country') {
        const countryValue = value === '' ? null : value;
        updatedOrg = { 
          ...prev, 
          [field]: countryValue,
          postal_code: null // Reset postal code when country changes
        };
      } else {
        updatedOrg = { ...prev, [field]: value || null };
      }

      // Validate and set errors
      const validationErrors = validateOrganization(updatedOrg);
      setErrors(validationErrors);

      return updatedOrg;
    });
  };

  const handleUpdateOrganization = async () => {
    if (organization) {
      const validationErrors = validateOrganization(organization);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        try {
          const { data, error } = await supabase
            .from('organizations')
            .update(organization)
            .eq('id', organization.id)
            .select()
            .single();

          if (error) throw error;

          // Invalidate and refetch relevant queries
          queryClient.invalidateQueries({ queryKey: ['organization', organization.id] });
          queryClient.invalidateQueries({ queryKey: ['organizationDetails', organization.id] });
          
          onOrganizationUpdated(data);
          setIsOpen(false);
        } catch (error) {
          console.error('Error updating organization:', error);
        }
      }
    }
  };

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const drawerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!organization) return null;

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction={isMobile ? "bottom" : "right"}
      modal={true}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <DrawerTitle>Update Organization</DrawerTitle>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <InputFieldsContainer>
              <InputField fullWidth>
                <label htmlFor="business_name">Business Name</label>
                <input
                  id="business_name"
                  type="text"
                  value={nullToEmpty(organization.business_name)}
                  onChange={(e) => handleInputChange("business_name", e.target.value)}
                  placeholder="Business Name"
                />
                {errors.business_name && <span className="error-message">{errors.business_name}</span>}
              </InputField>
              <InputField fullWidth>
                <label htmlFor="address_line1">Address Line 1</label>
                <input
                  id="address_line1"
                  type="text"
                  value={nullToEmpty(organization.address_line1)}
                  onChange={(e) => handleInputChange("address_line1", e.target.value)}
                  placeholder="Address Line 1"
                />
                {errors.address_line1 && <span className="error-message">{errors.address_line1}</span>}
              </InputField>
              <InputField fullWidth>
                <label htmlFor="address_line2">Address Line 2</label>
                <input
                  id="address_line2"
                  type="text"
                  value={nullToEmpty(organization.address_line2)}
                  onChange={(e) => handleInputChange("address_line2", e.target.value)}
                  placeholder="Address Line 2"
                />
                {errors.address_line2 && <span className="error-message">{errors.address_line2}</span>}
              </InputField>
              <InputField>
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  value={nullToEmpty(organization.city)}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  placeholder="City"
                />
                {errors.city && <span className="error-message">{errors.city}</span>}
              </InputField>
              <InputField>
                <label htmlFor="state">State/Province</label>
                <input
                  id="state"
                  type="text"
                  value={nullToEmpty(organization.state)}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  placeholder="State/Province"
                />
                {errors.state && <span className="error-message">{errors.state}</span>}
              </InputField>
              <InputField>
                <label htmlFor="country">Country</label>
                <select
                  id="country"
                  value={nullToEmpty(organization.country)}
                  onChange={(e) => handleInputChange("country", e.target.value)}
                >
                  <option value="">Select a country</option>
                  {getCountryOptions().map((option: { value: string; label: string }) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.country && <span className="error-message">{errors.country}</span>}
              </InputField>
              <InputField>
                <label htmlFor="postal_code">{getPostalCodeLabel(organization.country)}</label>
                <input
                  id="postal_code"
                  type="text"
                  value={nullToEmpty(organization.postal_code)}
                  onChange={(e) => handleInputChange("postal_code", e.target.value)}
                  placeholder={organization.country ? countries[organization.country as CountryCode]?.example : "Enter postal code"}
                />
                {errors.postal_code && <span className="error-message">{errors.postal_code}</span>}
              </InputField>
            </InputFieldsContainer>
            <ButtonFooter>
              <Button onClick={handleUpdateOrganization}>Update Organization</Button>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </ButtonFooter>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default OrganizationDrawer;
