import React from 'react';
import styled from 'styled-components';
import { Checkmark12 } from '../shared/components/Icon';
import AnimatedNumber from '../shared/components/AnimatedNumber';
import AnimatedAmount from '../shared/components/AnimatedAmount';

const Card = styled.div<{ selected?: boolean }>`
  background: transparent;
  border: ${props => props.selected ? '2px solid #2563eb' : '1px solid rgba(0, 0, 0, 0.1)'};
  border-radius: 8px;
  padding: 20px 24px 24px 24px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: ${props => props.selected ? '#eff6ff' : 'transparent'};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  transition: all 0.2s;

  &:hover {
    border-color: ${props => props.onClick ? '#2563eb' : props.selected ? '#2563eb' : 'rgba(0, 0, 0, 0.1)'};
  }
`;

const IntervalToggle = styled.div`
  display: flex;
  background: rgba(0,0,0,0.05);
  padding: 2px;
  border-radius: 8px;
  position: absolute;
  right: 24px;
  top: 24px;
  gap: 2px;
  width: 128px;
  user-select: none;
`;

const ToggleBackground = styled.div<{ isYearly: boolean }>`
  position: absolute;
  width: calc(50% - 2px);
  height: calc(100% - 4px);
  background: white;
  border-radius: 6px;
  transition: transform 0.2s ease;
  transform: translateX(${props => props.isYearly ? '100%' : '0%'});
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  top: 2px;
  left: 2px;
`;

const ToggleButton = styled.button<{ isSelected: boolean }>`
  flex: 1;
  padding: 4px 8px;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  color: ${props => props.isSelected ? '#111827' : '#6b7280'};
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
`;

const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  user-select: none;
`;

const PlanTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
  margin: 0;
`;

const PlanPrice = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  display: flex;
  align-items: baseline;
  gap: 4px;
  user-select: none;
  position: relative;
`;

const PerMonth = styled.span`
  font-size: 16px;
  color: #6b7280;
  font-weight: 500;
`;

const DiscountLabel = styled.span<{ visible: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: #009664;
  background-color: rgba(20, 217, 151, 0.2);
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 8px;
  opacity: ${props => props.visible ? 1 : 0};
  //transform: translateY(${props => props.visible ? '0' : '10px'});
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
  white-space: nowrap;
  right: -8px;
  top: 0;
`;

const PlanDescription = styled.p`
  color: rgba(0,0,0,0.5);
  font-size: 14px;
  margin: 0 0 24px 0;
  font-weight: 500;
  text-align: left;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 24px 0;
  border-top: 1px solid #e5e7eb;
  padding-top: 24px;
`;

const FeatureItem = styled.li`
  padding: 4px 0;
  color: #374151;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;

  svg {
    color: #111827;
    flex-shrink: 0;
  }
`;

const DEFAULT_FEATURES = [
  'Unlimited Invoices',
  'Unlimited Clients',
  'Unlimited Expenses',
  'Unlimited Projects',
  'Task Management',
  'Invoice Tracking',
  'Payment Links',
  'Credit Card Payments',
  'Premium Templates'
];

interface PlanCardProps {
  title: string;
  price: number;
  description?: string;
  features?: string[];
  showDiscount?: boolean;
  discountPercentage?: number;
  selected?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  showIntervalToggle?: boolean;
  selectedInterval?: 'month' | 'year';
  onIntervalChange?: (interval: 'month' | 'year') => void;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  price,
  description,
  features = DEFAULT_FEATURES,
  showDiscount = false,
  discountPercentage = 0,
  selected = false,
  onClick,
  children,
  showIntervalToggle = false,
  selectedInterval = 'month',
  onIntervalChange
}) => {
  const allFeatures = [...features];
  if (showDiscount && discountPercentage > 0 && selectedInterval === 'year') {
    allFeatures.push(`${discountPercentage}% discount with annual billing`);
  }

  return (
    <Card selected={selected} onClick={onClick}>
      <PlanHeader>
        <PlanTitle>{title}</PlanTitle>
        <PlanPrice>
          <AnimatedAmount value={price} />
          <PerMonth>/m</PerMonth>
          <DiscountLabel visible={showDiscount && discountPercentage > 0}>
            <AnimatedNumber value={discountPercentage} duration={800} />% Off
          </DiscountLabel>
        </PlanPrice>
      </PlanHeader>

      {showIntervalToggle && onIntervalChange && (
        <IntervalToggle>
          <ToggleBackground isYearly={selectedInterval === 'year'} />
          <ToggleButton
            isSelected={selectedInterval === 'month'}
            onClick={() => onIntervalChange('month')}
          >
            Monthly
          </ToggleButton>
          <ToggleButton
            isSelected={selectedInterval === 'year'}
            onClick={() => onIntervalChange('year')}
          >
            Yearly
          </ToggleButton>
        </IntervalToggle>
      )}

      {description && <PlanDescription>{description}</PlanDescription>}

      <FeatureList>
        {allFeatures.map((feature, index) => (
          <FeatureItem key={index}>
            <Checkmark12 />
            {feature}
          </FeatureItem>
        ))}
      </FeatureList>

      {children}
    </Card>
  );
};

export default PlanCard; 