import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUser } from './features/organization/hooks/useUser';
import { supabase } from './supabaseClient';
import styled, { keyframes } from 'styled-components';
import Button from './shared/components/Button';
import PageSkeleton from './shared/components/PageSkeleton';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Profile12 } from './shared/components/Icon';
import Compressor from 'compressorjs';
import { useNavigate } from '@tanstack/react-router';
import { debounce } from 'lodash';
import { toast } from 'sonner';
import { profileSchema } from './schemas/profile';
import { z } from 'zod';

interface UserMetadata {
  full_name?: string;
  avatar_url?: string;
  [key: string]: string | undefined;
}

interface UserData {
  id: string;
  email: string;
  full_name: string;
  avatar_url: string | null;
  updated_at: string | null;
  user_metadata: UserMetadata | null;
}

const PageWrapper = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputField = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: ${props => props.hasError ? '0 0 0 2px rgba(255, 77, 79, 0.2)' : '0 0 0 1px rgba(0,0,0,0.5)'};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
`;

const ErrorMessage = styled.span`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfilePicPlaceholder = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  color: #333;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ShimmerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 8%, rgba(255, 255, 255, 0.5) 18%, rgba(255, 255, 255, 0.1) 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const SignOutButton = styled(Button)`
  margin-top: 20px;
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const UserProfilePage: React.FC = () => {
  const { data: user, isLoading, error } = useUser();
  const [localUser, setLocalUser] = useState<UserData | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditingRef = useRef(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  // Initialize state from user data
  useEffect(() => {
    if (user && !isEditingRef.current) {
      const userData: UserData = {
        id: user.id,
        email: user.email,
        full_name: user.user_metadata?.full_name || user.full_name || '',
        avatar_url: user.user_metadata?.avatar_url || user.avatar_url || null,
        updated_at: user.updated_at,
        user_metadata: user.user_metadata
      };
      setLocalUser(userData);
    }
  }, [user]);

  const validateProfile = (profileData: z.infer<typeof profileSchema>) => {
    try {
      profileSchema.parse(profileData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const updateProfileMutation = useMutation({
    mutationFn: async (updatedProfile: { full_name: string, avatar_url?: string | null }) => {
      if (!user?.id) throw new Error('No user found');

      // Convert null to undefined for metadata
      const metadataAvatarUrl = updatedProfile.avatar_url === null ? undefined : updatedProfile.avatar_url;

      const profileData = {
        id: user.id,
        full_name: updatedProfile.full_name,
        avatar_url: updatedProfile.avatar_url ?? null,
        updated_at: new Date().toISOString(),
        user_metadata: {
          full_name: updatedProfile.full_name,
          avatar_url: metadataAvatarUrl
        }
      };

      if (!validateProfile(profileData)) {
        throw new Error('Invalid profile data');
      }

      // Update both profile and metadata in parallel
      const [profileUpdate, metadataUpdate] = await Promise.all([
        supabase
          .from('profiles')
          .update({ 
            full_name: updatedProfile.full_name,
            avatar_url: updatedProfile.avatar_url ?? null,
            updated_at: new Date().toISOString()
          })
          .eq('id', user.id)
          .select()
          .single(),
        
        supabase.auth.updateUser({
          data: {
            full_name: updatedProfile.full_name,
            avatar_url: metadataAvatarUrl
          }
        })
      ]);

      if (profileUpdate.error) throw profileUpdate.error;
      if (metadataUpdate.error) throw metadataUpdate.error;

      // Force a refresh of the session to get updated metadata
      const { data: session } = await supabase.auth.getSession();
      if (!session) throw new Error('Failed to get updated session');

      return {
        ...profileUpdate.data,
        user_metadata: {
          full_name: updatedProfile.full_name,
          avatar_url: metadataAvatarUrl
        }
      };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: UserData | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          full_name: data.full_name,
          avatar_url: data.avatar_url,
          updated_at: data.updated_at,
          user_metadata: {
            ...oldData.user_metadata,
            full_name: data.full_name,
            avatar_url: data.avatar_url,
          },
        };
      });

      queryClient.invalidateQueries({ queryKey: ['organizationUsers'] });
      queryClient.invalidateQueries({ queryKey: ['user'] });
      
      if (!isEditingRef.current) {
        toast.success('Profile updated successfully');
      }
    },
    onError: (error) => {
      toast.error('Failed to update profile: ' + error.message);
    }
  });

  const debouncedUpdateProfile = useRef(
    debounce(
      (changes: { full_name: string, avatar_url?: string | null }, isEditing: boolean) => {
        const toastId = toast.loading('Saving changes...');
        updateProfileMutation.mutate(changes, {
          onSuccess: () => {
            toast.success('Changes saved', { id: toastId });
          },
          onError: (error) => {
            toast.error('Failed to save changes: ' + error.message, { id: toastId });
          },
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (!localUser) return;

    isEditingRef.current = true;
    const updatedUser = { ...localUser, full_name: newName };

    // Validate before updating local state
    try {
      const validationResult = profileSchema.safeParse(updatedUser);
      if (!validationResult.success) {
        setErrors(Object.fromEntries(
          validationResult.error.errors.map(err => [err.path[0], err.message])
        ));
        return;
      }
      setErrors({});
    } catch (error) {
      console.error('Validation error:', error);
      return;
    }

    // Update local state immediately
    setLocalUser(updatedUser);
    
    debouncedUpdateProfile({ 
      full_name: newName,
      avatar_url: localUser.avatar_url 
    }, isEditingRef.current);
  };

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localUser]);

  // Add debug logs
  useEffect(() => {
    console.log('Current state:', { localUser });
  }, [localUser]);

  // Memoize the image compression function
  const compressImage = useCallback((file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        success(result) {
          resolve(result as File);
        },
        error(err) {
          console.error('Error compressing image:', err);
          reject(err);
        },
      });
    });
  }, []);

  // Memoize the file change handler
  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user?.id || !localUser) return;

    try {
      setIsUploading(true);
      const toastId = toast.loading('Uploading image...');

      // Create a local preview immediately
      const localPreview = URL.createObjectURL(file);
      const updatedUser: UserData = {
        ...localUser,
        avatar_url: localPreview
      };
      setLocalUser(updatedUser);

      const compressedFile = await compressImage(file);
      const fileExt = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(2, 8)}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
        throw new Error('Unsupported file type. Please use JPG, PNG, or GIF.');
      }

      // Delete old avatar file if it exists
      if (localUser.avatar_url) {
        try {
          const url = new URL(localUser.avatar_url);
          const pathParts = url.pathname.split('/');
          const oldFilePath = pathParts.slice(pathParts.indexOf('avatars') + 1).join('/').split('?')[0];
          
          if (oldFilePath) {
            console.log('Removing old file:', oldFilePath);
            await supabase.storage
              .from('avatars')
              .remove([oldFilePath]);
          }
        } catch (error) {
          console.error('Error removing old avatar:', error);
          // Continue with upload even if delete fails
        }
      }

      // Convert the compressed file to Uint8Array for upload
      const arrayBuffer = await compressedFile.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, uint8Array, {
          cacheControl: '3600',
          upsert: true,
          contentType: `image/${fileExt === 'jpg' ? 'jpeg' : fileExt}`
        });

      if (uploadError) throw uploadError;

      // Get the public URL with a timestamp to prevent caching
      const timestamp = Date.now();
      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(`${filePath}?t=${timestamp}`);

      // Verify the uploaded file is accessible
      const response = await fetch(publicUrl);
      if (!response.ok) {
        throw new Error(`Failed to verify upload: ${response.status} ${response.statusText}`);
      }

      // Clean up the local preview
      URL.revokeObjectURL(localPreview);

      // Update the profile with the new avatar URL
      await updateProfileMutation.mutateAsync({ 
        full_name: localUser.full_name, 
        avatar_url: publicUrl
      });

      // Force a refresh of the user data to ensure everything is in sync
      await queryClient.invalidateQueries({ queryKey: ['user'] });

      toast.success('Profile picture updated successfully', { id: toastId });
    } catch (error) {
      console.error('Error uploading avatar:', error);
      const resetUser: UserData = {
        ...localUser,
        avatar_url: null
      };
      setLocalUser(resetUser);
      toast.error('Failed to upload profile picture: ' + (error instanceof Error ? error.message : 'Unknown error'));
    } finally {
      setIsUploading(false);
    }
  }, [user?.id, localUser, compressImage, updateProfileMutation, queryClient]);

  // Memoize the submit handler
  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!localUser) return;

    try {
      const toastId = toast.loading('Saving profile...');
      await updateProfileMutation.mutateAsync({ 
        full_name: localUser.full_name || '', 
        avatar_url: localUser.avatar_url
      });
      toast.success('Profile saved successfully', { id: toastId });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to save profile');
    }
  }, [localUser, updateProfileMutation]);

  const removeAvatar = useCallback(async () => {
    if (!localUser || !user?.id) return;

    try {
      const toastId = toast.loading('Removing profile picture...');
      
      // Don't clear local state until the update is successful
      const currentAvatarUrl = localUser.avatar_url;
      
      if (currentAvatarUrl) {
        try {
          // Parse the URL to get the correct file path
          const url = new URL(currentAvatarUrl);
          const pathParts = url.pathname.split('/');
          const filePath = pathParts.slice(pathParts.indexOf('avatars') + 1).join('/').split('?')[0];
          
          if (filePath) {
            console.log('Removing file:', filePath);
            const { error: removeError } = await supabase.storage
              .from('avatars')
              .remove([filePath]);
              
            if (removeError) {
              console.error('Error removing file from storage:', removeError);
            }
          }
        } catch (error: unknown) {
          console.error('Error parsing avatar URL:', error);
        }
      }

      // Update profile and metadata atomically
      const [profileUpdate, metadataUpdate] = await Promise.all([
        supabase
          .from('profiles')
          .update({ 
            avatar_url: null,
            updated_at: new Date().toISOString()
          })
          .eq('id', user.id)
          .select()
          .single(),
        
        supabase.auth.updateUser({
          data: {
            avatar_url: undefined
          }
        })
      ]);

      if (profileUpdate.error) throw profileUpdate.error;
      if (metadataUpdate.error) throw metadataUpdate.error;

      // Clear local state only after successful update
      const updatedUser: UserData = {
        ...localUser,
        avatar_url: null
      };
      setLocalUser(updatedUser);

      // Force a complete refresh of the session and data
      await supabase.auth.refreshSession();
      
      // Remove queries before refetching to ensure fresh data
      queryClient.removeQueries({ queryKey: ['user'] });
      
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['user'] }),
        queryClient.invalidateQueries({ queryKey: ['organizationUsers'] })
      ]);

      toast.success('Profile picture removed successfully', { id: toastId });
    } catch (error: unknown) {
      console.error('Error removing avatar:', error);
      toast.error('Failed to remove profile picture: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  }, [localUser, user?.id, queryClient]);

  const getInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  const handleSignOut = async () => {
    try {
      const toastId = toast.loading('Signing out...');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear the React Query cache
      queryClient.clear();
      
      toast.success('Signed out successfully', { id: toastId });
      // Navigate to the home page or login page
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out');
    }
  };

  if (isLoading) return <PageSkeleton title="User Profile" />;
  if (error) return <div>Error loading user profile: {error.message}</div>;
  if (!user) {
    navigate({ to: '/' });
    return null;
  }

  // Remove this check since we ensure profile exists in useUser
  // if (!user.profile) {
  //   return <div>No user data available</div>;
  // }

  return (
    <PageWrapper>
      <PageTitle>User Profile</PageTitle>
      <Form onSubmit={handleSubmit}>
        <ProfilePicContainer>
          {localUser?.avatar_url ? (
            <div style={{ position: 'relative' }}>
              <ProfilePic src={localUser.avatar_url} alt="Profile" />
              {isUploading && (
                <ShimmerContainer>
                  <ShimmerEffect />
                </ShimmerContainer>
              )}
            </div>
          ) : (
            <ProfilePicPlaceholder>
              {localUser?.full_name ? getInitial(localUser.full_name) : <Profile12 />}
            </ProfilePicPlaceholder>
          )}
          <ButtonGroup>
            <FileInput
              type="file"
              id="avatar"
              accept="image/*"
              onChange={handleFileChange}
              disabled={isUploading}
            />
            <FileInputLabel htmlFor="avatar">
              <Button as="span" buttonType="secondary" disabled={isUploading}>
                {localUser?.avatar_url ? 'Change Picture' : 'Upload Picture'}
              </Button>
            </FileInputLabel>
            {localUser?.avatar_url && (
              <Button type="button" onClick={removeAvatar} disabled={isUploading}>
                Remove Picture
              </Button>
            )}
          </ButtonGroup>
        </ProfilePicContainer>
        <InputField hasError={!!errors.full_name}>
          <label htmlFor="fullName">Full Name</label>
          <input
            id="fullName"
            type="text"
            value={localUser?.full_name || ''}
            onChange={handleNameChange}
            placeholder="Full Name"
          />
          {errors.full_name && <ErrorMessage>{errors.full_name}</ErrorMessage>}
        </InputField>
      </Form>
      <SignOutButton onClick={handleSignOut}>
        Sign Out
      </SignOutButton>
    </PageWrapper>
  );
};

export default UserProfilePage;
