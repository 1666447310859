import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../../../supabaseClient";
import { Project } from "../../../types";

export const useCreateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<Project, Error, Partial<Project>>({
    mutationFn: async (newProject) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error("No user found. Please log in and try again.");
      }

      const projectData = {
        name: "",
        description: "",
        is_active: true,
        status: 'planning',
        user_id: user.id,
        ...newProject,
      };

      const { data, error } = await supabase
        .from("projects")
        .insert(projectData)
        .select()
        .single();

      if (error) throw error;
      return data as Project;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      if (data.client_id) {
        queryClient.invalidateQueries({ queryKey: ["clientProjects", data.client_id] });
      }
    },
  });
};