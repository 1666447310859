import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format, isThisYear } from 'date-fns';
import { InvoiceData, EmailTrackingData } from '../../../types';
import { Eye12, Invoice12, Money12, Mail12 } from '../../../shared/components/Icon';

const HistoryContainer = styled.div`
  width: 100%;
  max-width: 880px;
  padding: 40px;
`;

const HistoryTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const HistoryItem = styled.div`
  margin-bottom: 8px;
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  margin-top: 4px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin-left: 6px;
  color: rgba(0,0,0,0.5);
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

interface InvoiceHistoryProps {
  invoice: InvoiceData;
  emailTrackingData: EmailTrackingData[] | undefined;
}

const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({ invoice, emailTrackingData }) => {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice.currency || 'USD' }).format(amount);
  };

  const historyItems = useMemo(() => {
    const creationItem = {
      date: new Date(invoice.invoice_created_at || invoice.invoice_date || new Date()),
      event: 'Invoice created',
      type: 'creation'
    };

    const paymentItems = (invoice.payments || []).map(payment => ({
      date: new Date(payment.payment_date),
      event: `Payment received: ${formatCurrency(payment.amount)}`,
      type: 'payment'
    }));

    const emailItems = (emailTrackingData || []).flatMap(tracking => [
      {
        date: new Date(tracking.sent_at),
        event: `Invoice sent to ${tracking.recipient_email}`,
        type: 'email_sent'
      },
      ...(tracking.open_history || []).map(openEvent => ({
        date: new Date(openEvent),
        event: 'Invoice viewed',
        type: 'view'
      }))
    ]);

    const allItems = [creationItem, ...paymentItems, ...emailItems];

    // Sort all items by timestamp (oldest first)
    return allItems.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [invoice, emailTrackingData, formatCurrency]);

  const getIconForType = (type: string) => {
    switch (type) {
      case 'creation':
        return <Invoice12 />;
      case 'payment':
        return <Money12 />;
      case 'view':
        return <Eye12 />;
      case 'email_sent':
        return <Mail12 />;
      default:
        return null;
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const isCurrentYear = isThisYear(date);
    return isCurrentYear
      ? format(date, 'MMM d \'at\' h:mma')
      : format(date, 'MMM d, yyyy \'at\' h:mma');
  };

  return (
    <HistoryContainer>
      <HistoryTitle>Invoice History</HistoryTitle>
      {historyItems.map((item, index) => (
        <HistoryItem key={index}>
          <HistoryEvent>{getIconForType(item.type)}{item.event}</HistoryEvent>
          <HistoryDate $isLast={index === historyItems.length - 1}>
            {formatDate(item.date.toISOString())}
          </HistoryDate>
        </HistoryItem>
      ))}
      {historyItems.length === 0 && (
        <HistoryItem>No history available</HistoryItem>
      )}
    </HistoryContainer>
  );
};

export default InvoiceHistory;
