import React from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8, Client12, Project12, Service12 } from '../../../../shared/components/Icon';
import { ButtonGroup, LogTimeButton } from './TimeTrackerStyles';
import styled from 'styled-components';
import EntityPicker from '../../../../shared/components/EntityPicker';
import Switch from '../../../../shared/components/Switch';
import LayoutPicker from './LayoutPicker';
import { Client, Project, Service, Entity, TimeTrackerStyle} from '../../../../types';
import NumberFlow from '@number-flow/react';
import Button from '../../../../shared/components/Button';

const EveningWrapper = styled.div`
  width: 100%;
  position: relative;
  background: radial-gradient(circle at 90% 110%, #FF9F69 -50%, #091024 80%);
  border-radius: 12px;
  height: 420px;
  display: flex;
  flex-direction: column;
`;

const StyledLayoutPicker = styled(LayoutPicker)`
  margin-right: auto;
  opacity: 0.5;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    padding: 16px 24px;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: #F0F4F8;
    
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(240, 244, 248, 0.5);
      pointer-events: none;
      height: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  flex-direction: column;
  padding: 24px 20px;
  box-sizing: border-box;
  height: 100%;
`;
const TimeInputField = styled.input`
  width: 72px;
  height: 56px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 56px;
  line-height: 56px;
  color: #FFFFFF;
  font-family: 'EB Garamond', serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-feature-settings: 'tnum';
  -webkit-font-smoothing: antialiased;
  border-radius: 8px;
  font-variant-numeric: tabular-nums;
  -webkit-appearance: none;
`;

const NumberFlowStyled = styled(NumberFlow)`
  width: 72px;
  height: 56px;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-font-smoothing: antialiased;
  line-height: 56px;
  border-radius: 8px;
  font-size: 56px;
  font-family: 'EB Garamond', serif;
  text-align: left;
  font-variant-numeric: tabular-nums;
  outline: none;
  background-color: transparent;
  color: #FFFFFF;
  --number-flow-mask-height: 0.1em; // Add this line to make the mask smaller
  --number-flow-char-height: 0.8em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  flex: 1;
  width: 100%;
`;

const TimeInputLabel = styled.div`
  font-size: 12px;
  color: rgba(240, 244, 248, 0.75);
  bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 0 16px;
`;

const PickerLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillableToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
`;

const Stars = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

// Use the same TimeTrackerLayoutProps interface as ModernTimeTracker
interface TimeTrackerLayoutProps {
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (checked: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  handleClientChange: (id: string | null) => void;
  handleProjectChange: (id: string | null) => void;
  handleServiceChange: (id: string | null) => void;
  handleResume: () => void;
  timeTrackerStyle: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
}

const EveningTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  isBillable,
  handleBillableToggle,
  isRunning,
  isPaused,
  handleStart,
  handlePause,
  handleLogTime,
  handleDelete,
  clients,
  projects,
  services,
  selectedClientId,
  selectedProjectId,
  selectedServiceId,
  handleClientChange,
  handleProjectChange,
  handleServiceChange,
  handleResume,
  timeTrackerStyle,
  onLayoutChange,
}) => {
  const mapToEntity = (item: Client | Project | Service): Entity => ({
    id: item.id,
    name: 'name' in item 
      ? (item.name || 'Untitled Project')
      : (item.full_name || 'Untitled Client')
  });

  const clientEntities = clients.map(mapToEntity);
  const projectEntities = projects.map(mapToEntity);
  const serviceEntities = services.map(mapToEntity);

  // Add this renderTimeInput function
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds', index: number) => {
    const value = parseInt(manualTime.split(':')[index], 10);
    
    if (isRunning) {
      return (
        <NumberFlowStyled
          value={value}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 500, easing: 'ease-out' }}
          spinTiming={{ duration: 500, easing: 'ease-out' }}
          opacityTiming={{ duration: 500, easing: 'ease-out' }}
        />
      );
    } else {
      return (
        <TimeInputField
          type="number"
          value={value.toString().padStart(2, '0')}
          onChange={(e) => handleManualTimeChange(field, e.target.value)}
          min={0}
          max={field === 'hours' ? 23 : 59}
        />
      );
    }
  };

  return (
    <EveningWrapper>
      <Stars>
        <svg width="430" height="199" viewBox="0 0 430 199" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="184.5" cy="15.5" r="0.5" fill="#D9D9D9"/>
<circle cx="216.5" cy="47.5" r="0.5" fill="#D9D9D9"/>
<circle cx="256.5" cy="23.5" r="0.5" fill="#D9D9D9"/>
<circle cx="312.5" cy="31.5" r="0.5" fill="#D9D9D9"/>
<circle cx="304.5" cy="55.5" r="0.5" fill="#D9D9D9"/>
<circle cx="385.5" cy="0.5" r="0.5" fill="#D9D9D9"/>
<circle cx="209.5" cy="112.5" r="0.5" fill="#D9D9D9"/>
<circle cx="346.5" cy="128.5" r="0.5" fill="#D9D9D9"/>
<circle cx="218.5" cy="192.5" r="0.5" fill="#D9D9D9"/>
<circle cx="145.5" cy="135.5" r="0.5" fill="#D9D9D9"/>
<circle cx="104.5" cy="78.5" r="0.5" fill="#D9D9D9"/>
<circle cx="40.5" cy="14.5" r="0.5" fill="#D9D9D9"/>
<circle cx="0.5" cy="94.5" r="0.5" fill="#D9D9D9"/>
<circle cx="88.5" cy="198.5" r="0.5" fill="#D9D9D9"/>
<circle cx="280.5" cy="118.5" r="0.5" fill="#D9D9D9"/>
<circle cx="352.5" cy="70.5" r="0.5" fill="#D9D9D9"/>
<circle cx="424.5" cy="38.5" r="0.5" fill="#D9D9D9"/>
<circle cx="429.5" cy="60.5" r="0.5" fill="#D9D9D9"/>
</svg>

      </Stars>
      <TimeInputWrapper>
        <Column>
          {renderTimeInput('hours', 0)}
          <TimeInputLabel>Hrs</TimeInputLabel>
        </Column>
        <Column>
          {renderTimeInput('minutes', 1)}
          <TimeInputLabel>Mins</TimeInputLabel>
        </Column>
        <Column>
          {renderTimeInput('seconds', 2)}
          <TimeInputLabel>Secs</TimeInputLabel>
        </Column>
      </TimeInputWrapper>
      <PickerContainer>
        <PickerLeft>
          <EntityPicker
            selectedId={selectedClientId}
            onChange={handleClientChange}
            entities={clientEntities}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
            dark={true}
          />
          <EntityPicker
            selectedId={selectedProjectId}
            onChange={handleProjectChange}
            entities={projectEntities}
            label="Project"
            allowUnassigned={true}
            icon={<Project12 />}
            dark={true}
          />
          <EntityPicker
            selectedId={selectedServiceId}
            onChange={handleServiceChange}
            entities={serviceEntities}
            label="Service"
            allowUnassigned={true}
            icon={<Service12 />}
            dark={true}
          />
        </PickerLeft>
        <PickerRight>
          <BillableToggleWrapper>
            <Switch
              checked={isBillable}
              onChange={handleBillableToggle}
              label="Billable"
              dark={true}
              sliderColor='rgba(0,0,0,0.5)'
              sliderUncheckedColor='rgba(255,255,255,0.1)'
              knobColor='white'
            />
          </BillableToggleWrapper>
        </PickerRight>
      </PickerContainer>
    
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
      <ButtonGroup>
        {!isRunning ? (
          <LogTimeButton dark={true} onClick={isPaused ? handleResume : handleStart}>
            <Play8/> {isPaused ? 'Resume' : 'Start'}
          </LogTimeButton>
        ) : (
          <LogTimeButton dark={true} onClick={handlePause}>
            <Pause8/> Pause
          </LogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <LogTimeButton dark={true} onClick={handleLogTime}>
            Log Time
          </LogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <Button buttonType='icon' dark={true} onClick={handleDelete}><Trash12 /></Button>
        )}
        <StyledLayoutPicker
          selectedLayout={timeTrackerStyle}
          onLayoutChange={onLayoutChange}
          dark={true}
        />
      </ButtonGroup>
    </EveningWrapper>
  );
};

export default EveningTimeTracker;
