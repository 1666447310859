import React, { useState, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useCreateOrganization } from '../../../hooks';
import { supabase } from '../../../supabaseClient';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { useUser } from '../../organization/hooks/useUser';
import { STRIPE_CONFIG } from '../../../config/stripe';
import { loadStripe } from '@stripe/stripe-js';
import PlanCard from '../../../components/PlanCard';
import { z } from 'zod';

// Zod schemas for form validation
const onboardingSchema = z.object({
  fullName: z.string()
    .min(1, 'Full name is required')
    .max(100, 'Full name is too long'),
  organizationName: z.string()
    .min(1, 'Organization name is required')
    .max(100, 'Organization name is too long'),
});

type OnboardingFormData = z.infer<typeof onboardingSchema>;

const OnboardingContainer = styled.div`
  max-width: 560px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  text-align: center;
`;

const WelcomeTitle = styled.h1`
  font-family: 'PP Mori', sans-serif;
  font-size: 48px;
  margin-bottom: 24px;
  color: #333333;
`;

const WelcomeMessage = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  margin: 0 auto 4px auto;
  max-width: 200px;
  text-align: center;
`;

const FounderSignature = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #666666;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StepContainer = styled.div`
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: ${props => props.hasError ? '1px solid #dc3545' : '1px solid rgba(0, 0, 0, 0.1)'};
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: ${props => props.hasError ? '1px solid #dc3545' : '1px solid rgba(0, 0, 0, 0.5)'};
    box-shadow: ${props => props.hasError ? '0 0 0 1px #dc3545' : 'none'};
  }
`;

const LogoutButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f0f0f0;
  }
`;

const fadeVariants = {
  hidden: { 
    opacity: 0, 
    filter: "blur(2px)",
    y: -20
  },
  visible: { 
    opacity: 1, 
    filter: "blur(0px)",
    scale: 1,
    y: 0,
    transition: {
      duration: 1.5,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0, 
    filter: "blur(2px)",
    y: 20,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const welcomeFadeVariants = {
  hidden: { 
    opacity: 0,
    filter: "blur(8px)",
    scale: 0.95
  },
  visible: { 
    opacity: 1,
    filter: "blur(0px)",
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0,
    filter: "blur(8px)",
    scale: 0.95,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const AnimatedTitle = styled(motion.div)`
  width: 100%;
` as unknown as typeof motion.div;

const AnimatedContent = styled(motion.div)`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
` as unknown as typeof motion.div;

const AnimatedContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  transform-style: preserve-3d;
`;

interface Organization {
  name: string;
  logo_url: string | null;
}

interface PendingInvitation {
  id: string;
  organization_id: string;
  organization: Organization;
}

const InvitationCard = styled.div`
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const OrganizationName = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
`;

const OrganizationLogo = styled.img`
  max-width: 32px;
  max-height: 32px;

`;

const LogoPlaceholder = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
`;

const InvitationActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InviteLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

const PendingInvitesContainer = styled.div`
  margin: 40px 0;
  user-select: none;
`;

const NameContainer = styled.div`
  margin: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
`;

const OrganizationContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  margin-bottom: 16px;
`;

const TrialMessage = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: -8px 0 16px 0;
`;

const OnboardingFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  margin-top: auto;
  padding-top: 20px;
`;

const BackButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.05);
  color: #666;
  margin-right: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const EmailDisplay = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

const OnboardingContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const OnboardingPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [fullName, setFullName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [formErrors, setFormErrors] = useState<Partial<OnboardingFormData>>({});
  const [selectedInterval, setSelectedInterval] = useState<'month' | 'year'>('month');
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false);
  const [isProcessingSubscription, setIsProcessingSubscription] = useState(false);
  const [isProcessingTrial, setIsProcessingTrial] = useState(false);
  const [createdOrganizationId, setCreatedOrganizationId] = useState<string | null>(null);
  const navigate = useNavigate();
  const createOrganization = useCreateOrganization();
  const queryClient = useQueryClient();
  const [processingInvitation, setProcessingInvitation] = useState<string | null>(null);
  const { data: userData } = useUser();
  const fullNameInputRef = React.useRef<HTMLInputElement>(null);
  const organizationNameInputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Handle redirect from Stripe checkout and cancellation
    const handleStripeRedirect = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      const onboardingCompleted = urlParams.get('onboarding_completed');
      const canceled = urlParams.get('canceled');
      const step = urlParams.get('step');

      // If this is a cancellation redirect, just set the step to 4 (subscription step)
      if (canceled === 'true' && step === '4') {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          // Get the user's current organization from user metadata
          const { data: { user: currentUser } } = await supabase.auth.getUser();
          if (currentUser?.user_metadata?.current_organization_id) {
            setCreatedOrganizationId(currentUser.user_metadata.current_organization_id);
            setStep(4);
            // Clear the URL parameters
            window.history.replaceState({}, '', '/onboarding');
            return;
          }
        }
      }

      if (sessionId && onboardingCompleted === 'true') {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          // Update profile to mark onboarding as completed
          await supabase.from('profiles').upsert({ 
            id: session.user.id,
            onboarding_completed: true
          });
          
          // Clear the URL parameters and navigate to dashboard
          window.history.replaceState({}, '', '/');
          navigate({ to: '/' });
        }
      }
    };

    handleStripeRedirect();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      queryClient.clear(); // Clear the query cache
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error signing out:', error);
      alert('An error occurred while signing out. Please try again.');
    }
  };

  const { data: pendingInvitations } = useQuery<PendingInvitation[], Error>({
    queryKey: ['pendingInvitations'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');
      
      const { data, error } = await supabase
        .from('pending_invitations')
        .select('id, organization_id, organizations(name, logo_url)')
        .eq('email', user.email);
      
      if (error) throw error;
      
      return data.map((invite): PendingInvitation => ({
        id: invite.id,
        organization_id: invite.organization_id,
        organization: Array.isArray(invite.organizations) 
          ? invite.organizations[0] 
          : invite.organizations
      }));
    },
  });

  const validateStep = (currentStep: number): boolean => {
    try {
      if (currentStep === 1) {
        // Validate full name
        onboardingSchema.pick({ fullName: true }).parse({ fullName });
        setFormErrors({});
        return true;
      } else if (currentStep === 3) {
        // Validate organization name
        const result = onboardingSchema.pick({ organizationName: true }).safeParse({ organizationName });
        if (!result.success) {
          const errors: Partial<OnboardingFormData> = {};
          result.error.errors.forEach((err) => {
            errors.organizationName = err.message;
          });
          setFormErrors(errors);
          return false;
        }
        setFormErrors({});
        return true;
      }
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors: Partial<OnboardingFormData> = {};
        error.errors.forEach((err) => {
          if (err.path[0] === 'fullName') {
            errors.fullName = err.message;
          }
        });
        setFormErrors(errors);
      }
      return false;
    }
  };

  const handleNextStep = () => {
    if (!validateStep(step)) return;

    if (step === 0) {
      setStep(1);
    } else if (step === 1 && pendingInvitations && pendingInvitations.length > 0) {
      setStep(2);
    } else if (step === 1) {
      setStep(3);
    } else {
      setStep(step + 1);
    }
  };

  const handleCreateOrganization = async () => {
    if (isCreatingOrganization) return;
    
    // Validate organization name
    const result = onboardingSchema.pick({ organizationName: true }).safeParse({ organizationName });
    if (!result.success) {
      const errors: Partial<OnboardingFormData> = {};
      result.error.errors.forEach((err) => {
        errors.organizationName = err.message;
      });
      setFormErrors(errors);
      return;
    }

    setIsCreatingOrganization(true);

    try {
      // Create the organization
      const newOrganization = await createOrganization.mutateAsync({ name: organizationName });
      
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        // Run these operations in parallel
        const [profileResult, memberResult, userResult] = await Promise.all([
          // Update profile with full name and other fields
          supabase.from('profiles').upsert({ 
            id: user.id,
            full_name: fullName,
            updated_at: new Date().toISOString()
          }),
          // Create organization membership
          supabase.from('organization_members').upsert({ 
            user_id: user.id, 
            organization_id: newOrganization.id,
            role: 'owner'
          }, { onConflict: 'user_id,organization_id' }),
          // Update current organization and user metadata
          supabase.auth.updateUser({
            data: { 
              current_organization_id: newOrganization.id,
              full_name: fullName
            }
          })
        ]);

        // Check for errors
        if (profileResult.error) throw new Error('Failed to update profile');
        if (memberResult.error) throw new Error('Failed to create organization membership');
        if (userResult.error) throw new Error('Failed to update user organization');
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['pendingInvitations'] }),
        queryClient.invalidateQueries({ queryKey: ['organization'] }),
        queryClient.invalidateQueries({ queryKey: ['user'] })
      ]);
      setCreatedOrganizationId(newOrganization.id);
      setStep(step + 1); // Move to subscription step
    } catch (error) {
      console.error('Error during organization creation:', error);
      alert('An error occurred while creating your organization. Please try again.');
    } finally {
      setIsCreatingOrganization(false);
    }
  };

  const handleSubscriptionSelection = async (shouldStartTrial: boolean) => {
    console.log('handleSubscriptionSelection called with shouldStartTrial:', shouldStartTrial);
    if (!createdOrganizationId || (shouldStartTrial ? isProcessingTrial : isProcessingSubscription)) {
      return;
    }

    try {
      if (shouldStartTrial) {
        setIsProcessingTrial(true);
      } else {
        setIsProcessingSubscription(true);
      }

      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError || !session) {
        throw new Error('No active session found');
      }

      if (!shouldStartTrial) {
        // Redirect to Stripe checkout
        const response = await fetch('/api/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            priceId: selectedInterval === 'month' 
              ? STRIPE_CONFIG.MONTHLY_PRICE_ID 
              : STRIPE_CONFIG.ANNUAL_PRICE_ID,
            successUrl: `${window.location.origin}/`,
            cancelUrl: `${window.location.origin}/create-organization?step=4&canceled=true`,
            organizationId: createdOrganizationId,
            trial_period_days: 0
          }),
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Failed to create checkout session');
        }

        const { id: sessionId } = await response.json();
        const stripe = await loadStripe(STRIPE_CONFIG.PUBLIC_KEY);
        if (!stripe) {
          throw new Error('Failed to load Stripe');
        }
        
        const result = await stripe.redirectToCheckout({ sessionId });
        if (result.error) {
          throw new Error(result.error.message || 'Failed to redirect to checkout');
        }
        return;
      }

      // Handle trial flow
      const { data: existingSubscription, error: checkError } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('organization_id', createdOrganizationId)
        .maybeSingle();

      if (checkError) {
        throw new Error('Failed to check existing subscription');
      }

      if (!existingSubscription) {
        const { error: subscriptionError } = await supabase
          .from('subscriptions')
          .insert({
            organization_id: createdOrganizationId,
            status: 'trialing',
            trial_end: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            trial_start: new Date().toISOString(),
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });
        
        if (subscriptionError) {
          throw new Error('Failed to create trial subscription');
        }

        // Verify the subscription was created
        const { data: verifySubscription, error: verifyError } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('organization_id', createdOrganizationId)
          .single();

        if (verifyError || !verifySubscription) {
          throw new Error('Failed to verify subscription creation');
        }
      }

      // First update the user metadata
      const { error: updateError } = await supabase.auth.updateUser({
        data: { 
          current_organization_id: createdOrganizationId,
          onboarding_completed: true
        }
      });

      if (updateError) {
        throw new Error('Failed to update current organization');
      }

      // Then update the profile
      const { error: profileError } = await supabase
        .from('profiles')
        .update({ 
          onboarding_completed: true,
          updated_at: new Date().toISOString()
        })
        .eq('id', session.user.id);

      if (profileError) {
        throw new Error('Failed to update profile');
      }

      // Verify profile update
      const { data: verifyProfile, error: verifyProfileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (verifyProfileError || !verifyProfile?.onboarding_completed) {
        throw new Error('Failed to verify profile update');
      }

      // Create organization member record if it doesn't exist
      const { error: memberError } = await supabase
        .from('organization_members')
        .upsert({
          user_id: session.user.id,
          organization_id: createdOrganizationId,
          role: 'owner',
          is_owner: true
        }, {
          onConflict: 'user_id,organization_id'
        });

      if (memberError) {
        throw new Error('Failed to create organization membership');
      }

      // Invalidate all relevant queries
      await queryClient.invalidateQueries();

      // Add a small delay to ensure all database operations have propagated
      await new Promise(resolve => setTimeout(resolve, 500));

      // Use React Router navigation
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error:', error);
      alert(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      if (shouldStartTrial) {
        setIsProcessingTrial(false);
      } else {
        setIsProcessingSubscription(false);
      }
    }
  };

  const handleAcceptInvitation = async (invitation: PendingInvitation) => {
    setProcessingInvitation(invitation.id);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // Add the user to the organization
      const { error: memberError } = await supabase
        .from('organization_members')
        .insert({ user_id: user.id, organization_id: invitation.organization_id });

      if (memberError) throw memberError;

      // Delete the pending invitation
      const { error: deleteError } = await supabase
        .from('pending_invitations')
        .delete()
        .eq('id', invitation.id);

      if (deleteError) throw deleteError;

      // Update user profile
      const { error: profileError } = await supabase.from('profiles').upsert({ 
        id: user.id, 
        full_name: fullName,
        onboarding_completed: true
      });

      if (profileError) throw profileError;

      // Update user's current organization
      const { error: updateError } = await supabase.auth.updateUser({
        data: { current_organization_id: invitation.organization_id }
      });

      if (updateError) throw updateError;

      console.log('Invitation accepted successfully');

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['pendingInvitations'] }),
        queryClient.invalidateQueries({ queryKey: ['organization'] }),
        queryClient.invalidateQueries({ queryKey: ['user'] })
      ]);
      
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error accepting invitation:', error);
      alert('An error occurred while accepting the invitation. Please try again.');
      setProcessingInvitation(null);
    }
  };

  const handleCreateNewOrganization = () => {
    setStep(3); // Move to organization creation step
  };

  const renderOrganizationLogo = (organization: Organization) => {
    if (organization.logo_url) {
      return <OrganizationLogo src={organization.logo_url} alt="Organization Logo" />;
    } else {
      const firstLetter = organization.name.charAt(0).toUpperCase();
      return <LogoPlaceholder>{firstLetter}</LogoPlaceholder>;
    }
  };

  const handlePreviousStep = () => {
    if (step === 3 && (!pendingInvitations || pendingInvitations.length === 0)) {
      setStep(1); // Skip step 2 if there are no pending invitations
    } else {
      setStep(step - 1);
    }
  };

  // Add auto-focus effect
  useEffect(() => {
    // Add a longer delay to ensure the animation has started and element is mounted
    const focusTimer = setTimeout(() => {
      if (step === 1 && fullNameInputRef.current) {
        fullNameInputRef.current.focus();
      } else if (step === 3 && organizationNameInputRef.current) {
        organizationNameInputRef.current.focus();
      }
    }, 300); // Increased delay to match animation duration

    return () => clearTimeout(focusTimer);
  }, [step]);

  // Add keyboard navigation handler
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, currentStep: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentStep === 1 && fullName.trim()) {
        handleNextStep();
      } else if (currentStep === 3 && organizationName.trim()) {
        handleCreateOrganization();
      }
    }
  };

  // Focus handler for after animation completes
  const handleAnimationComplete = (currentStep: number) => {
    requestAnimationFrame(() => {
      if (currentStep === 1 && fullNameInputRef.current) {
        fullNameInputRef.current.focus();
      } else if (currentStep === 3 && organizationNameInputRef.current) {
        organizationNameInputRef.current.focus();
      }
    });
  };

  return (
    <PageWrapper>
      <OnboardingContainer>
        <OnboardingContent>
          <AnimatePresence mode="wait">
            {step === 0 && (
              <AnimatedContainer
                key="step0"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={welcomeFadeVariants}
                transition={{ duration: 0.3 }}
              >
                <StepContainer>
                  <AnimatedTitle
                    initial={{ 
                      opacity: 0,
                      scale: 2.5,
                      filter: "blur(10px)",
                    }}
                    animate={{ 
                      opacity: 1,
                      scale: 1,
                      filter: "blur(0px)",
                    }}
                    transition={{ 
                      duration: 2,
                      ease: [0.16, 1, 0.3, 1],
                      opacity: { duration: 1.2 },
                    }}
                  >
                    <WelcomeTitle>Welcome to Due</WelcomeTitle>
                  </AnimatedTitle>
                  
                  <AnimatedContent
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ 
                      duration: 1.2, 
                      delay: 0.8
                    }}
                  >
                    <WelcomeMessage>
                      Thanks for joining Due – I'm excited to have you here!
                    </WelcomeMessage>
                    <FounderSignature>Kyle, Founder</FounderSignature>
                  </AnimatedContent>
                  
                  <AnimatedContent
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ 
                      duration: 1.2, 
                      delay: 1.2
                    }}
                  >
                    <ButtonWrapper>
                      <Button buttonType="primary" onClick={handleNextStep}>Get Started</Button>
                    </ButtonWrapper>
                  </AnimatedContent>
                </StepContainer>
              </AnimatedContainer>
            )}
            {step === 1 && (
              <AnimatedContainer
                key="step1"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeVariants}
                transition={{ duration: 0.3 }}
                onAnimationComplete={() => handleAnimationComplete(1)}
              >
                <NameContainer>
                  <Subtitle>What's your name?</Subtitle>
                  <Input
                    ref={fullNameInputRef}
                    type="text"
                    placeholder="Your full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, 1)}
                    aria-invalid={!!formErrors.fullName}
                    hasError={!!formErrors.fullName}
                    autoFocus
                  />
                  {formErrors.fullName && (
                    <ErrorMessage>{formErrors.fullName}</ErrorMessage>
                  )}
                  <ButtonContainer>
                    <BackButton onClick={handlePreviousStep}>
                      Back
                    </BackButton>
                    <Button buttonType="primary" onClick={handleNextStep}>Next</Button>
                  </ButtonContainer>
                </NameContainer>
              </AnimatedContainer>
            )}
            {step === 2 && pendingInvitations && pendingInvitations.length > 0 && (
              <AnimatedContainer
                key="step2"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeVariants}
                transition={{ duration: 0.3 }}
              >
                <PendingInvitesContainer>
                  <Subtitle>You have pending invitations</Subtitle>
                  {pendingInvitations.map((invitation: PendingInvitation) => (
                    <InvitationCard key={invitation.id}>
                      <InviteLeft>
                        {renderOrganizationLogo(invitation.organization)}
                        <OrganizationName>{invitation.organization.name}</OrganizationName>
                      </InviteLeft>
                      <InvitationActions>
                        <Button 
                          onClick={() => handleAcceptInvitation(invitation)}
                          disabled={processingInvitation === invitation.id}
                        >
                          {processingInvitation === invitation.id ? 'Joining...' : 'Accept Invite'}
                        </Button>
                      </InvitationActions>
                    </InvitationCard>
                  ))}
                </PendingInvitesContainer>
                <ButtonContainer>
                  <BackButton onClick={handlePreviousStep}>
                    Back
                  </BackButton>
                  <Button onClick={handleCreateNewOrganization}>Create New Organization Instead</Button>
                </ButtonContainer>
              </AnimatedContainer>
            )}
            {step === 3 && (
              <AnimatedContainer
                key="step3"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeVariants}
                transition={{ duration: 0.3 }}
                onAnimationComplete={() => handleAnimationComplete(3)}
              >
                <OrganizationContainer>
                  <Subtitle>Create your organization</Subtitle>
                  <Input
                    ref={organizationNameInputRef}
                    type="text"
                    placeholder="Organization name"
                    value={organizationName}
                    onChange={(e) => {
                      setOrganizationName(e.target.value);
                      if (formErrors.organizationName) {
                        setFormErrors({});
                      }
                    }}
                    onKeyPress={(e) => handleKeyPress(e, 3)}
                    aria-invalid={!!formErrors.organizationName}
                    hasError={!!formErrors.organizationName}
                    autoFocus
                  />
                  {formErrors.organizationName && (
                    <ErrorMessage>{formErrors.organizationName}</ErrorMessage>
                  )}
                  <ButtonContainer>
                    <BackButton onClick={handlePreviousStep} disabled={isCreatingOrganization}>
                      Back
                    </BackButton>
                    <Button 
                      buttonType="primary"
                      onClick={handleCreateOrganization} 
                      disabled={isCreatingOrganization}
                    >
                      {isCreatingOrganization ? 'Creating Organization...' : 'Create Organization'}
                    </Button>
                  </ButtonContainer>
                </OrganizationContainer>
              </AnimatedContainer>
            )}
            {step === 4 && (
              <AnimatedContainer
                key="step4"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeVariants}
                transition={{ duration: 0.3 }}
              >
                <OrganizationContainer>
                  <Subtitle>Choose your plan</Subtitle>
                  <TrialMessage>You won't be charged until your 30 day free trial has ended.</TrialMessage>
                  <PlanCard
                    title="Pro"
                    price={selectedInterval === 'month' 
                      ? STRIPE_CONFIG.MONTHLY_PRICE
                      : Math.round(STRIPE_CONFIG.ANNUAL_PRICE / 12)
                    }
                    description="Unlock all of Due for one simple price."
                    showIntervalToggle={true}
                    selectedInterval={selectedInterval}
                    onIntervalChange={setSelectedInterval}
                    showDiscount={selectedInterval === 'year'}
                    discountPercentage={17}
                  >
                    <div style={{ 
                      marginTop: '24px',
                      display: 'flex',
                      gap: '12px'
                    }}>
                      <Button
                        onClick={() => handleSubscriptionSelection(false)}
                        disabled={isProcessingSubscription || isProcessingTrial}
                        buttonType="primary"
                      >
                        {isProcessingSubscription ? 'Starting Subscription...' : 'Upgrade Now'}
                      </Button>
                      <Button
                        onClick={() => handleSubscriptionSelection(true)}
                        disabled={isProcessingTrial || isProcessingSubscription}
                        buttonType="secondary"
                      >
                        {isProcessingTrial ? 'Starting Trial...' : 'Start Free Trial'}
                      </Button>
                    </div>
                  </PlanCard>
                </OrganizationContainer>
              </AnimatedContainer>
            )}
          </AnimatePresence>
        </OnboardingContent>
        <OnboardingFooter>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          {userData && userData.email && (
            <EmailDisplay>Logged in as: {userData.email}</EmailDisplay>
          )}
        </OnboardingFooter>
      </OnboardingContainer>
    </PageWrapper>
  );
};

export default OnboardingPage;