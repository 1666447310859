import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams, useNavigate } from '@tanstack/react-router';
import { projectRoute } from '../../../router';
import { supabase } from "../../../supabaseClient";
import { Project, Invoice, Expense, Task, TimeEntry, InvoiceData } from "../../../types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import EntityPicker from "../../../shared/components/EntityPicker";
import { Client12, Calendar12, Tasks32, Status12, More12, Time32 } from "../../../shared/components/Icon";
import ExpenseDrawer from "../../expenses/components/ExpenseDrawer";
import Tabs from "../../../shared/components/Tabs";
import DatePicker from "../../../components/DatePicker";
import TaskDrawer from "../../../features/tasks/components/TaskDrawer";
import { 
  useProjects,
  useOrganization,
  useClients,
  useCreateInvoiceMutation,
  useExpenseMutations,
  useOrganizationUsers,
  useUser,
  useTaskMutations,
  useTasks,
  useInvoices,
  useTaskStatuses,
  useTimeTracking,
  usePageContext,
  useEmailTrackingData,
  useDeleteProjectMutation,
  usePayments,
  useBulkDeleteInvoicesMutation
} from '../../../hooks';
import NotesEditor from "../../../components/NotesEditor";
import TaskTable from "../../../features/tasks/components/TaskTable";
import TimeEntryDrawer from "../../time/components/TimeEntryDrawer";
import { debounce } from "lodash";
import InvoiceList from '../../../components/InvoiceList';
import ExpenseList from '../../expenses/components/ExpenseList';
import InvoiceDrawer from "../../../features/invoices/components/InvoiceDrawer";
import InvoiceDetails from '../../../features/invoices/components/InvoiceDetails';
import SummaryCards from '../../../shared/components/SummaryCards';
import { formatDuration } from '../../../utils/timeUtils';
import TimeEntryTable from '../../../features/time/components/TimeEntryTable';
import type { GroupedTimeEntry } from '../../../types';
import { Link } from '@tanstack/react-router';
import { formatCurrency } from '../../../utils/formatCurrency';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import type { Row } from '@tanstack/react-table';
import SelectedTimeEntriesOverlay from '../../../features/time/components/SelectedTimeEntriesOverlay';
import { isThisYear, format, differenceInCalendarDays } from 'date-fns';
import type { ExpenseInvoiceItem } from '../../../types';
import { exportExpensesToCSV, downloadCSV } from '../../../utils/csvExport';
import SelectedExpensesOverlay from '../../../features/expenses/components/SelectedExpensesOverlay';
import { projectSchema } from '../../../schemas/project';
import { z } from 'zod';
import SplitButton from '../../../shared/components/SplitButton';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { StatusBadge } from "../../../shared/components/StatusBadge";
import SelectedInvoicesOverlay from '../../../features/invoices/components/SelectedInvoicesOverlay';
import { exportInvoicesToCSV } from '../../../utils/csvExport';

const ProjectPageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const PageContent = styled.div`
  height: 100%;
`;

const ProjectMetaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 16px 32px 8px;
  gap: 4px;
`;

const TabContent = styled.div`
  height: calc(100% - 48px); // Adjust this value based on your tabs height
`;

const EditableTitleWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 24px;
  max-width: 100%;
  margin-left: -8px;
  cursor: text;
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const OverviewContent = styled.div`
  margin: 0 auto;
  max-width: 960px;
`;

const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const ProjectPage: React.FC = () => {
  const { id } = useParams({ from: projectRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: projects } = useProjects();
  const { data: clients = [] } = useClients();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationUsers } = useOrganizationUsers();
  const { data: user } = useUser();
  const { taskStatuses, getDefaultStatus } = useTaskStatuses();
  const { stopTimer, pauseTimer, resumeTimer, currentTimeEntryId } = useTimeTracking();
  const { updateTaskMutation, createTaskMutation, deleteTaskMutation } = useTaskMutations();
  const { setPageHeaderProps } = usePageContext();
  const deleteProjectMutation = useDeleteProjectMutation();
  const { updateExpenseMutation, createExpenseMutation, deleteExpenseMutation } = useExpenseMutations();
  const [isExpenseDrawerOpen, setIsExpenseDrawerOpen] = useState(false);
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [localProject, setLocalProject] = useState<Project | null>(null);
  const [localDescription, setLocalDescription] = useState<string>('');
  const [activeTab, setActiveTab] = useState<'overview' | 'invoices' | 'expenses' | 'tasks' | 'time-entries'>('overview');
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [, setIsDatePickerOpen] = useState(false);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoiceId);

  const isEditingRef = useRef(false);

  const projectStatuses = [
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  const { data: project  } = useQuery<Project>({
    queryKey: ["project", id, organizationId],
    queryFn: async () => {
      if (!id || !organizationId) throw new Error("Project ID or Organization ID not found");
      
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("id", id)
        .eq("organization_id", organizationId)
        .single();
      if (error) throw error;
      return data as Project;
    },
    enabled: !!id && !!organizationId,
  });

  const { data: payments } = usePayments();

  // Add effect to check organization access and redirect if needed
  useEffect(() => {
    // If there's no organization ID, redirect to home
    if (!organizationId) {
      console.log('No organization ID found');
      navigate({ to: '/' });
      return;
    }

    // If project is loaded and doesn't belong to user's organization, redirect
    if (project && project.organization_id !== organizationId) {
      console.log('User does not have access to this project');
      navigate({ to: '/projects' });
      return;
    }
  }, [project, organizationId, navigate]);

  useEffect(() => {
    if (project) {
      setLocalProject(project);
      setLocalDescription(project.description || '');
    }
  }, [project]);

  const handleCreateInvoice = useCallback(async () => {
    if (project && organizationId) {
      try {
        const invoiceData = {
          project_id: project.id,
          client_id: project.client_id || undefined,
          organization_id: organizationId,
        };

        console.log("Creating invoice with data:", invoiceData);

        const newInvoice = await createInvoiceMutation.mutateAsync(invoiceData);

        console.log("New invoice created:", newInvoice);

        if (newInvoice) {
          queryClient.invalidateQueries({ queryKey: ["projectInvoices", id, organizationId] });
          navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
        }
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  }, [project, organizationId, createInvoiceMutation, queryClient, id, navigate]);

  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();

  const projectInvoices = useMemo(() => {
    return invoices?.filter(invoice => invoice.project_id === id).map(invoice => ({
      ...invoice,
      items: [], // You might need to fetch invoice items separately
      invoice_template: 'default', // Set a default value or fetch from somewhere
      header_color: '#ffffff', // Set a default value or fetch from somewhere
      header_text_color: '#000000', // Set a default value or fetch from somewhere
      font: 'Inter', // Set a default value or fetch from somewhere
      due_days: 30, // Set a default value or calculate based on invoice_date and due_date
      currency: invoice.currency || 'USD', // Assuming currency exists in Invoice type, otherwise set a default
      payments: [], // Add an empty array for payments
    } as unknown as InvoiceData)) || [];
  }, [invoices, id]);


  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["projectExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Project ID not found");
      
      // Check for prefetched data
      const cachedData = queryClient.getQueryData(["projectExpenses", id, organizationId]);
      if (cachedData) return cachedData as Expense[];

      // If not in cache, fetch from Supabase
      const { data, error } = await supabase
        .from("expenses")
        .select("*")
        .eq("project_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
  });

  const { data: tasks, isLoading: isLoadingTasks, localUpdatesRef } = useTasks(id);

  // First, move sortTasks definition before its usage
  const sortTasks = useCallback((tasks: Task[]) => {
    return [...tasks].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, []);

  // Add this ref to track if we're in the initial mount
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);
  const [localTasks, setLocalTasks] = useState<Task[]>(() => {
    const cachedTasks = queryClient.getQueryData(['tasks', organizationId]) as Task[] | undefined;
    if (cachedTasks) {
      initializedFromCache.current = true;
      return sortTasks(cachedTasks.filter(task => task.project_id === id));
    }
    return [];
  });

  // Update the tasks effect to handle initial mount
  useEffect(() => {
    if (tasks) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalTasks(sortTasks(tasks.filter(task => task.project_id === id)));
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [tasks, sortTasks, id]);

  const updateLocalTask = useCallback((updatedTask: Partial<Task>) => {
    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );
  }, []);

  const updateTask = useCallback((updatedTask: Partial<Task>, isEditing: boolean = false) => {
    if (!updatedTask.id) return;

    // Update localUpdatesRef with the editing state
    localUpdatesRef.current.set(updatedTask.id, isEditing);

    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );

    updateTaskMutation.mutate(updatedTask, {
      onSettled: () => {
        // Remove the task ID from the localUpdatesRef if not editing
        if (!isEditing) {
          localUpdatesRef.current.delete(updatedTask.id!);
        }
      },
    });
  }, [updateTaskMutation, localUpdatesRef]);

  const handleCreateTask = useCallback(() => {
    if (!organizationId || !user || !id) {
      console.error('Organization, user, or project not found');
      return;
    }

    const defaultStatus = getDefaultStatus();

    if (!defaultStatus) {
      console.error('No default status found');
      return;
    }

    // Get the highest position from existing tasks
    const maxPosition = localTasks.reduce((max, task) => 
      task.position > max ? task.position : max, 
      0
    );

    const newTask = {
      description: '',
      is_completed: false,
      due_date: null,
      notes: '',
      client_id: localProject?.client_id || null,
      project_id: id,
      user_id: user.id,
      organization_id: organizationId,
      status_id: defaultStatus.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      position: maxPosition + 65536, // Add a large increment to allow for future insertions
    };

    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        setLocalTasks(prev => sortTasks([...prev, createdTask]));
        setSelectedTaskId(createdTask.id);
        setIsTaskDrawerOpen(true);
      },
      onError: (error) => {
        console.error('Error creating task:', error);
      }
    });
  }, [
    createTaskMutation, 
    organizationId, 
    user, 
    id, 
    getDefaultStatus, 
    localProject, 
    sortTasks,
    localTasks // Add localTasks to dependencies
  ]);

  const handleDeleteTask = useCallback((taskId: string) => {
    deleteTaskMutation.mutate(taskId, {
      onSuccess: () => {
        setLocalTasks(prev => prev.filter(task => task.id !== taskId));
        setSelectedTaskId(null);
        setIsTaskDrawerOpen(false);
      },
      onError: (error) => {
        console.error('Failed to delete task:', error);
        alert('Failed to delete task. Please try again.');
      },
    });
  }, [deleteTaskMutation]);

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'start_time', desc: true }
  ]);

  const handleSortingChange = useCallback((updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => {
    setSorting(updaterOrValue);
  }, []);

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['projectTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select(`
          *,
          project:projects(id, name),
          time_entry_invoice_items(
            id,
            invoice_item:invoice_items(
              id,
              invoice:invoices(id)
            )
          )
        `)
        .eq('project_id', id)
        .eq('organization_id', organizationId)
        .order('start_time', { ascending: !sorting[0]?.desc });
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
  });

  const updateProjectMutation = useMutation<Project, Error, Partial<Project> & { id: string }>({
    mutationFn: async (updatedProject) => {
      const { id, ...updateData } = updatedProject;
      const { data, error } = await supabase
        .from("projects")
        .update(updateData)
        .eq("id", id)
        .select()
        .single();
      if (error) throw error;
      return data as Project;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["project", data.id, organizationId], data);
      queryClient.invalidateQueries({ queryKey: ["projects", organizationId] });
    },
  });

  const debouncedUpdateProject = useRef(
    debounce(
      (id: string, changes: Partial<Project>, isEditing: boolean) => {
        updateProjectMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const [errors, setErrors] = useState<Record<string, string>>({});

  // Add validateProject function
  const validateProject = (projectData: Partial<Project>) => {
    try {
      projectSchema.parse(projectData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  // Update handleInputChange to include validation
  const handleInputChange = (field: keyof Project, value: string | boolean | null) => {
    isEditingRef.current = true;
    const updatedProject = localProject ? { ...localProject, [field]: value } : null;
    setLocalProject(updatedProject);

    if (localProject?.id && updatedProject) {
      // Validate before updating
      if (validateProject(updatedProject)) {
        debouncedUpdateProject(localProject.id, { [field]: value }, isEditingRef.current);
      }
    }
  };

  const handleStatusChange = (statusId: string | null) => {
    handleInputChange('status', statusId);
  };

  const handleClientChange = (clientId: string | null) => {
    handleInputChange('client_id', clientId);
  };

  const handleDueDateChange = (date: Date | null) => {
    handleInputChange('due_date', date ? date.toISOString().split('T')[0] : null);
  };

  // Update the handleDescriptionChange function
  const handleDescriptionChange = useCallback((content: string) => {
    isEditingRef.current = true;
    setLocalDescription(content);

    if (localProject?.id) {
      debouncedUpdateProject(localProject.id, { description: content }, isEditingRef.current);
    }
  }, [localProject, debouncedUpdateProject]);

  useEffect(() => {
    if (id && organizationId) {
      // Prefetch project data
      queryClient.prefetchQuery({
        queryKey: ["project", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("projects")
            .select("*")
            .eq("id", id)
            .eq("organization_id", organizationId)
            .single();
          if (error) throw error;
          return data as Project;
        },
      });

      // Prefetch invoices
      queryClient.prefetchQuery({
        queryKey: ["projectInvoices", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("invoices")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Invoice[];
        },
      });

      // Prefetch expenses
      queryClient.prefetchQuery({
        queryKey: ["projectExpenses", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("expenses")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Expense[];
        },
      });

      // Prefetch tasks
      queryClient.prefetchQuery({
        queryKey: ["projectTasks", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("tasks")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Task[];
        },
      });
    }
  }, [id, queryClient, organizationId]);

  useEffect(() => {
    if (project && project.name === "") {
      setIsTitleFocused(true);
    }
  }, [project]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localProject]);

  const handleTaskClick = useCallback((task: Task) => {
    setSelectedTaskId(task.id);
    setIsTaskDrawerOpen(true);
  }, []);

  const handleDatePickerOpenChange = useCallback((isOpen: boolean) => {
    setIsDatePickerOpen(isOpen);
  }, []);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current && localProject?.id) {
      const newTitle = titleRef.current.textContent || '';
      const updatedProject = { ...localProject, name: newTitle };
      
      if (validateProject(updatedProject)) {
        setLocalProject(prev => prev ? { ...prev, name: newTitle } : null);
        debouncedUpdateProject(localProject.id, { name: newTitle }, isEditingRef.current);
      }
    }
  }, [localProject, debouncedUpdateProject]);

  useEffect(() => {
    if (titleRef.current && localProject) {
      titleRef.current.textContent = localProject.name;
    }
  }, [localProject]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    if (!organizationId) {
      throw new Error("No organization ID found");
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("No user found. Please log in and try again.");
        throw new Error("No user found");
      }

      const { data, error } = await supabase
        .from('time_entries')
        .insert({
          ...timeEntryData,
          project_id: id,
          organization_id: organizationId,
          user_id: user.id
        })
        .select(`
          *,
          project:projects(id, name),
          time_entry_invoice_items(
            id,
            invoice_item:invoice_items(
              id,
              invoice:invoices(id)
            )
          )
        `)
        .single();

      if (error) throw error;

      // Update the local state with the new entry while maintaining sort order
      if (timeEntries) {
        const newEntries = [...timeEntries];
        const newEntryTime = new Date(data.start_time).getTime();
        
        const insertIndex = newEntries.findIndex(entry => {
          const entryTime = new Date(entry.start_time).getTime();
          if (sorting[0]?.desc) {
            return newEntryTime > entryTime;
          } else {
            return newEntryTime < entryTime;
          }
        });

        if (insertIndex === -1) {
          if (sorting[0]?.desc) {
            newEntries.unshift(data);
          } else {
            newEntries.push(data);
          }
        } else {
          newEntries.splice(insertIndex, 0, data);
        }

        queryClient.setQueryData(['projectTimeEntries', id, organizationId], newEntries);
      }

      return data;
    } catch (error) {
      console.error('Error creating time entry:', error);
      throw error;
    }
  }, [id, organizationId, queryClient, timeEntries, sorting]);

  const handleExpenseSelectionChange = useCallback((selectedIds: string[]) => {
    setSelectedExpenses(selectedIds);
  }, []);

  const handleEditExpense = useCallback((expense: Expense) => {
    setEditingExpense(expense);
    setIsExpenseDrawerOpen(true);
  }, []);

  const handleCloseExpenseDrawer = useCallback(() => {
    setIsExpenseDrawerOpen(false);
    setEditingExpense(null);
  }, []);

  const handleOpenExpenseDrawer = useCallback(() => {
    setEditingExpense(null);
    setIsExpenseDrawerOpen(true);
  }, []);

  const [localExpenses, setLocalExpenses] = useState<Expense[]>(() => {
    const cachedExpenses = queryClient.getQueryData(['projectExpenses', id, organizationId]) as Expense[] | undefined;
    if (cachedExpenses) {
      return cachedExpenses;
    }
    return [];
  });

  useEffect(() => {
    if (expenses) {
      setLocalExpenses(expenses);
    }
  }, [expenses]);

  const handleCreateExpense = useCallback(async (expenseData: Partial<Expense>): Promise<Expense> => {
    if (!organizationId) {
      throw new Error("No organization selected. Please select an organization and try again.");
    }

    const newExpense = await createExpenseMutation.mutateAsync({
      ...expenseData,
      organization_id: organizationId,
      project_id: id,
    } as Omit<Expense, 'id'>);

    setLocalExpenses((prev: Expense[]) => [...prev, newExpense]);
    return newExpense;
  }, [organizationId, createExpenseMutation, id]);

  const handleDeleteExpense = useCallback((expenseId: string) => {
    deleteExpenseMutation.mutate(expenseId, {
      onSuccess: () => {
        setLocalExpenses((prev: Expense[]) => prev.filter((expense: Expense) => expense.id !== expenseId));
        setIsExpenseDrawerOpen(false);
        setEditingExpense(null);
      },
      onError: (error) => {
        console.error('Failed to delete expense:', error);
        alert('Failed to delete expense. Please try again.');
      },
    });
  }, [deleteExpenseMutation]);

  const handleSaveExpense = useCallback((updatedExpense: Expense) => {
    if (!organizationId) {
      console.error('No organization ID found');
      return;
    }

    updateExpenseMutation.mutate(updatedExpense, {
      onSuccess: (savedExpense) => {
        setLocalExpenses((prev: Expense[]) =>
          prev.map((expense: Expense) =>
            expense.id === savedExpense.id ? savedExpense : expense
          )
        );
      },
      onError: (error) => {
        console.error('Failed to save expense:', error);
        alert('Failed to save expense. Please try again.');
      },
    });
  }, [updateExpenseMutation, organizationId]);

  const updateLocalExpense = useCallback((updatedExpense: Partial<Expense>) => {
    setLocalExpenses((prev: Expense[]) =>
      prev.map((expense: Expense) =>
        expense.id === updatedExpense.id ? { ...expense, ...updatedExpense } : expense
      )
    );
  }, []);

  const taskColumns: ColumnDef<Task>[] = useMemo(
    () => [
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'status_id',
        header: 'Status',
        cell: ({ row }: { row: Row<Task> }) => {
          const status = taskStatuses?.find(s => s.id === row.original.status_id);
          return status ? status.name : 'Unknown';
        },
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue }: { getValue: () => unknown }) => 
          getValue() ? new Date(getValue() as string).toLocaleDateString() : 'No due date',
      },
      {
        accessorKey: 'user_id',
        header: 'Assignee',
        cell: ({ row }: { row: Row<Task> }) => {
          const user = organizationUsers?.find(u => u.id === row.original.user_id);
          return user ? user.full_name || user.email : 'Unassigned';
        },
      },
    ],
    [taskStatuses, organizationUsers]
  );

  const taskEmptyState = useMemo(() => ({
    icon: <Tasks32 />,
    message: "No tasks for this project",
    subMessage: "Create your first task to get started",
    action: {
      label: "New Task",
      onClick: handleCreateTask
    }
  }), [handleCreateTask]);

  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoiceId(invoice.id);
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteProject = useCallback(() => {
    if (!localProject) return;
    setIsDeleteModalOpen(true);
  }, [localProject]);

  const handleConfirmDelete = useCallback(() => {
    if (!id) return;
    deleteProjectMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["projects"] });
        navigate({ to: "/projects" });
      },
      onError: (error) => {
        console.error("Error deleting project:", error);
        alert("Failed to delete project. Please try again.");
      },
    });
    setIsDeleteModalOpen(false);
  }, [deleteProjectMutation, navigate, queryClient, id]);

  const moreOptions = useMemo(() => [
    { id: 'delete', name: 'Delete Project' },
  ], []);

  const handleMoreOptionSelect = useCallback((optionId: string | null) => {
    if (optionId === 'delete') {
      handleDeleteProject();
    }
  }, [handleDeleteProject]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/projects",
      parentName: "Projects",
      title: (
        <EditableTitleWrapper>
          <EditableTitle
            ref={titleRef}
            contentEditable
            suppressContentEditableWarning
            onInput={handleTitleChange}
            onBlur={handleTitleChange}
            data-placeholder="Enter project name"
            autoFocus={isTitleFocused}
            onFocus={() => setIsTitleFocused(true)}
            style={{ borderColor: errors.name ? '#ff4d4f' : undefined }}
          />
          {errors.name && (
            <span style={{ 
              color: '#ff4d4f', 
              fontSize: '12px', 
              position: 'absolute', 
              bottom: '-20px', 
              left: '8px' 
            }}>
              {errors.name}
            </span>
          )}
        </EditableTitleWrapper>
      ),
      right: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <SplitButton
            primaryOption={{
              id: 'new-invoice',
              name: 'New Invoice'
            }}
            options={[
              { id: 'new-time-entry', name: 'New Time Entry' },
              { id: 'new-expense', name: 'New Expense' },
              { id: 'new-task', name: 'New Task' }
            ]}
            onOptionSelect={(optionId) => {
              switch (optionId) {
                case 'new-invoice':
                  handleCreateInvoice();
                  break;
                case 'new-time-entry':
                  handleAddNewTimeEntry();
                  break;
                case 'new-expense':
                  handleOpenExpenseDrawer();
                  break;
                case 'new-task':
                  handleCreateTask();
                  break;
              }
            }}
          />
          <EntityPicker
            selectedId={null}
            onChange={handleMoreOptionSelect}
            entities={moreOptions}
            label="More"
            icon={<More12 />}
            placement="bottom-end"
            iconOnly
            enableSearch={false}
          />
        </div>
      ),
    });
  }, [setPageHeaderProps, localProject, isTitleFocused, handleTitleChange, moreOptions]);

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [selectedEntriesCount, setSelectedEntriesCount] = useState(0);
  const [currentDurations, setCurrentDurations] = useState<{ [id: string]: number }>({});

  // Add this effect to handle running timers
  useEffect(() => {
    const timers: { [id: string]: NodeJS.Timeout } = {};

    timeEntries?.forEach(entry => {
      if (entry.start_time && !entry.end_time) {
        if (!timers[entry.id]) {
          timers[entry.id] = setInterval(() => {
            setCurrentDurations(prev => ({
              ...prev,
              [entry.id]: prev[entry.id] ? prev[entry.id] + 1 : Math.floor((Date.now() - new Date(entry.start_time).getTime()) / 1000),
            }));
          }, 1000);
        }
      } else {
        if (timers[entry.id]) {
          clearInterval(timers[entry.id]);
          delete timers[entry.id];
        }
      }
    });

    return () => {
      Object.values(timers).forEach((timer) => clearInterval(timer));
    };
  }, [timeEntries]);

  // Add these handlers
  const handleSelectionChange = useCallback((selectedIds: string[], count: number) => {
    setSelectedEntries(selectedIds);
    setSelectedEntriesCount(count);
    
    // Actually use the selected entries
    if (count > 0) {
      console.log(`Selected ${count} entries:`, selectedIds);
      // Add logic here to handle selected entries
      // For example, enable bulk actions or update UI state
    }
  }, []);

  const getRowKey = useCallback((entry: TimeEntry) => entry.id, []);

  const updateLocalTimeEntry = useCallback((updatedEntry: Partial<TimeEntry>) => {
    if (!timeEntries) return;
    
    const newEntries = timeEntries.map(entry =>
      entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
    );
    
    // Update the query cache
    queryClient.setQueryData(['projectTimeEntries', id, organizationId], newEntries);
  }, [timeEntries, queryClient, id, organizationId]);

  const timeEntryColumns = useMemo<ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }>[]>(() => [
    {
      id: 'start_time',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return row.id;
        }
        return row.start_time;
      },
      header: 'Date',
      cell: ({ getValue }) => {
        const date = new Date(getValue() as string);
        const today = new Date();
        const diffDays = differenceInCalendarDays(date, today);
        const formattedDate = format(date, isThisYear(date) ? 'MMM d' : 'MMM d, yyyy');
        
        let statusText = '';
        if (diffDays < 0) {
          statusText = `(${Math.abs(diffDays)} day${Math.abs(diffDays) !== 1 ? 's' : ''} ago)`;
        } else if (diffDays === 0) {
          statusText = '(today)';
        } else if (diffDays === 1) {
          statusText = '(tomorrow)';
        } else {
          statusText = `(in ${diffDays} day${diffDays !== 1 ? 's' : ''})`;
        }
        
        return (
          <span>
            {formattedDate}{' '}
            {statusText && <span style={{ color: 'rgba(0,0,0,0.5)' }}>{statusText}</span>}
          </span>
        );
      },
    },
    {
      id: 'duration',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return row.totalDuration;
        }
        return row.duration;
      },
      header: 'Duration',
      cell: ({ getValue }) => {
        const value = getValue() as number;
        return formatDuration(value);
      },
    },
    {
      id: 'description',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.description;
      },
      header: 'Description',
    },
    {
      id: 'client',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.client?.full_name || '';
      },
      header: 'Client',
    },
    {
      id: 'user',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.user_full_name || '';
      },
      header: 'User',
    },
    {
      id: 'status',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.end_time ? 'completed' : 'running';
      },
      header: 'Status',
      cell: ({ getValue }) => {
        const status = getValue() as string;
        return status ? <StatusBadge $status={status}>{status}</StatusBadge> : null;
      },
    },
    {
      id: 'invoice',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null;
      },
      header: 'Invoice',
      cell: ({ getValue }) => {
        const invoiceId = getValue() as string | null;
        if (!invoiceId) return 'Not Invoiced';
        return (
          <Link 
            to="/invoice/$id" 
            params={{ id: invoiceId }}
            search={{ from: '/projects' }}
          >
            View Invoice
          </Link>
        );
      },
    },
  ], []);

  // Add this function to group time entries by date
  const groupTimeEntries = useMemo(() => (entries: TimeEntry[]): (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[] => {
    const grouped: { [key: string]: GroupedTimeEntry[] } = {};
    
    entries?.forEach(entry => {
      const groupDate = new Date(entry.start_time).toISOString().split('T')[0];
      if (!grouped[groupDate]) {
        grouped[groupDate] = [];
      }
      grouped[groupDate].push({
        ...entry,
        groupDate,
        user_full_name: organizationUsers?.find(u => u.id === entry.user_id)?.full_name || 'Unknown',
        invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null
      });
    });

    return Object.entries(grouped).flatMap(([date, entries]) => [
      { 
        id: date, 
        isGroupHeader: true, 
        totalDuration: entries.reduce((sum, entry) => sum + (entry.duration || 0), 0)
      },
      ...entries
    ]);
  }, [organizationUsers]);

  // Update the timeEntryTableContent to use the new wrapper
  const timeEntryTableContent = (
    <TableWrapper>
      <TimeEntryTable
        timeEntries={timeEntries ? groupTimeEntries(timeEntries) : []}
        columns={timeEntryColumns}
        isLoading={isLoadingTimeEntries}
        isInitialLoading={isLoadingTimeEntries}
        onRowClick={(entry) => {
          setSelectedTimeEntryId(entry.id);
          setIsTimeEntryDrawerOpen(true);
        }}
        onImmediateUpdate={updateLocalTimeEntry}
        getRowKey={getRowKey}
        clients={clients || []}
        projects={projects || []}
        organizationUsers={organizationUsers || []}
        onEntriesUpdate={(entries) => {
          queryClient.setQueryData(['projectTimeEntries', id, organizationId], entries);
        }}
        containerRef={containerRef}
        emptyState={{
          icon: <Time32 />,
          message: "No time entries",
          subMessage: "Track time for this project to get started",
          action: {
            label: "Add Time Entry",
            onClick: handleAddNewTimeEntry
          }
        }}
        onDeleteEntry={handleDeleteTimeEntry}
        onSelectionChange={handleSelectionChange}
        onInvoiceClick={(invoiceId) => navigate({ to: '/invoice/$id', params: { id: invoiceId } })}
        hasNextPage={false}
        isFetchingNextPage={false}
        fetchNextPage={() => {}}
        timeFormat="decimal"
        currentDurations={currentDurations}
        sorting={sorting}
        onSortingChange={handleSortingChange}
      />
    </TableWrapper>
  );

  // Replace the existing time entries tab content with:
  {activeTab === "time-entries" && timeEntryTableContent}

  const summaryCards = [
    {
      title: 'Total Invoiced',
      amount: !isLoadingTimeEntries && !isLoadingExpenses && !isLoadingInvoices
        ? formatCurrency(projectInvoices.reduce((total, invoice) => total + (invoice.total || 0), 0))
        : formatCurrency(0),
      onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
      isSelected: activeTab === 'invoices',
      selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
      selectedForegroundColor: '#FFFFFF'
    },
    {
      title: 'Outstanding Balance',
      amount: !isLoadingTimeEntries && !isLoadingExpenses && !isLoadingInvoices
        ? formatCurrency(projectInvoices.reduce((total, invoice) => total + ((invoice.total || 0) - (invoice.amount_due || 0)), 0))
        : formatCurrency(0),
      onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
      isSelected: activeTab === 'invoices',
      selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
      selectedForegroundColor: '#FFFFFF'
    },
    {
      title: 'Total Expenses',
      amount: !isLoadingTimeEntries && !isLoadingExpenses && !isLoadingInvoices
        ? formatCurrency((expenses || []).reduce((total, expense) => total + (expense.amount || 0), 0))
        : formatCurrency(0),
      onClick: () => setActiveTab(prevTab => prevTab === 'expenses' ? 'overview' : 'expenses'),
      isSelected: activeTab === 'expenses',
      selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
      selectedForegroundColor: '#FFFFFF'
    },
    {
      title: 'Total Time',
      amount: !isLoadingTimeEntries && !isLoadingExpenses && !isLoadingInvoices
        ? formatDuration((timeEntries || []).reduce((total, entry) => total + (entry.duration || 0), 0))
        : formatDuration(0),
      onClick: () => setActiveTab(prevTab => prevTab === 'time-entries' ? 'overview' : 'time-entries'),
      isSelected: activeTab === 'time-entries',
      selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
      selectedForegroundColor: '#FFFFFF'
    }
  ];

  const renderSummaryCards = useCallback(() => {
    return <SummaryCards 
      cards={summaryCards} 
      isLoading={isLoadingTimeEntries || isLoadingExpenses || isLoadingInvoices} 
    />;
  }, [
    projectInvoices, 
    expenses, 
    timeEntries, 
    activeTab, 
    setActiveTab, 
    isLoadingTimeEntries, 
    isLoadingExpenses, 
    isLoadingInvoices
  ]);

  const handleTasksUpdate = useCallback((updatedTasks: Task[]) => {
    const projectTasks = updatedTasks.filter(task => task.project_id === id);
    setLocalTasks(projectTasks);
  }, [id]);

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, client_id: clientId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, project_id: projectId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkDelete = useCallback(() => {
    if (selectedEntries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedEntries.length} time entry(ies)?`)) {
      selectedEntries.forEach(id => handleDeleteTimeEntry(id));
      setSelectedEntries([]);
      setSelectedEntriesCount(0);
    }
  }, [selectedEntries, handleDeleteTimeEntry]);

  const expenseColumns: ColumnDef<Expense>[] = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ({ getValue }) => {
        const expenseDate = new Date(getValue() as string);
        return format(expenseDate, isThisYear(expenseDate) ? 'MMM d' : 'MMM d, yyyy');
      },
    },
    {
      accessorKey: 'merchant',
      header: 'Merchant',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => formatCurrency(getValue() as number),
    },
    {
      accessorKey: 'client',
      header: 'Client',
      cell: ({ getValue }) => {
        const client = getValue() as { full_name: string } | null;
        return client ? client.full_name : '-';
      },
    },
    {
      accessorKey: 'expense_invoice_items',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const items = getValue() as ExpenseInvoiceItem[] | null | undefined;
        if (items && items.length > 0 && items[0].invoice_item?.invoice_id) {
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: items[0].invoice_item.invoice_id }}
              search={{ from: '/projects' }}
            >
              View Invoice
            </Link>
          );
        }
        return 'Not Invoiced';
      },
    },
  ], []);

  const handleBulkDeleteExpenses = useCallback(async () => {
    if (selectedExpenses.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedExpenses.length} expense(s)?`)) {
      try {
        await Promise.all(selectedExpenses.map((expenseId: string) => handleDeleteExpense(expenseId)));
        setSelectedExpenses([]);
        setLocalExpenses(prev => prev.filter(expense => !selectedExpenses.includes(expense.id)));
      } catch (error) {
        console.error('Failed to delete expenses:', error);
        alert('Failed to delete expenses. Please try again.');
      }
    }
  }, [selectedExpenses, handleDeleteExpense]);

  const handleExportExpenses = useCallback(() => {
    if (!selectedExpenses.length || !localExpenses) return;
    
    const selectedExpenseData = localExpenses.filter(expense => 
      selectedExpenses.includes(expense.id)
    );
    
    const csvContent = exportExpensesToCSV(selectedExpenseData);
    downloadCSV(csvContent, `expenses-${format(new Date(), 'yyyy-MM-dd')}.csv`);
  }, [selectedExpenses, localExpenses]);

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [isDeleteInvoicesModalOpen, setIsDeleteInvoicesModalOpen] = useState(false);
  const bulkDeleteInvoicesMutation = useBulkDeleteInvoicesMutation();

  const handleBulkDeleteInvoices = useCallback(() => {
    setIsDeleteInvoicesModalOpen(true);
  }, []);

  const handleConfirmDeleteInvoices = useCallback(async () => {
    try {
      await bulkDeleteInvoicesMutation.mutateAsync(selectedInvoices);
      setSelectedInvoices([]);
      setIsDeleteInvoicesModalOpen(false);
      queryClient.invalidateQueries({ queryKey: ["projectInvoices", id, organizationId] });
    } catch (error) {
      console.error('Failed to delete invoices:', error);
      alert('Failed to delete invoices. Please try again.');
    }
  }, [selectedInvoices, bulkDeleteInvoicesMutation, queryClient, id, organizationId]);

  const handleInvoiceSelectionChange = useCallback((selectedIds: string[]) => {
    setSelectedInvoices(selectedIds);
  }, []);

  const handleExportInvoicesCSV = useCallback(() => {
    if (!selectedInvoices.length || !projectInvoices) return;
    
    // Convert InvoiceData to Invoice type and ensure required fields
    const selectedInvoiceData = projectInvoices
      .filter(invoice => selectedInvoices.includes(invoice.id))
      .map(invoice => {
        // Map status to allowed values for Invoice type
        let mappedStatus: 'draft' | 'unpaid' | 'paid';
        if (invoice.status === 'paid') {
          mappedStatus = 'paid';
        } else if (invoice.status === 'draft') {
          mappedStatus = 'draft';
        } else {
          // Both 'unpaid' and 'overdue' map to 'unpaid' for Invoice type
          mappedStatus = 'unpaid';
        }

        // Ensure invoice_date is never null
        const invoice_date = invoice.invoice_date || new Date().toISOString();

        return {
          ...invoice,
          client_id: invoice.client_id || '',
          id: invoice.id,
          status: mappedStatus,
          invoice_date,
          due_date: invoice.due_date,
          amount_due: invoice.amount_due || 0,
          total: invoice.total || 0,
          invoice_number: invoice.invoice_number || '',
          subtotal: invoice.subtotal || 0,
          tax_rate: invoice.tax_rate || 0,
          public_id: invoice.public_id || '',
          invoice_created_at: invoice.invoice_created_at || new Date().toISOString(),
        } as Invoice; // Type assertion to Invoice
      });
    
    const csvContent = exportInvoicesToCSV(selectedInvoiceData, clients || [], payments || []);
    downloadCSV(csvContent, `invoices-${format(new Date(), 'yyyy-MM-dd')}.csv`);
  }, [selectedInvoices, projectInvoices, clients, payments]);

  return (
    <ProjectPageWrapper ref={containerRef}>
      <ProjectMetaWrapper>
        <div>
          <EntityPicker
            selectedId={localProject?.client_id || null}
            onChange={handleClientChange}
            entities={clients.map(client => ({ id: client.id, name: client.full_name || client.email || 'Unknown Client' }))}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
            placement="bottom-start"
          />
          {errors.client_id && <span style={{ color: '#ff4d4f', fontSize: '12px', marginTop: '4px', display: 'block' }}>{errors.client_id}</span>}
        </div>
        <div>
          <EntityPicker
            selectedId={localProject?.status || null}
            onChange={handleStatusChange}
            entities={projectStatuses}
            label="Status"
            allowUnassigned={true}
            icon={<Status12 />}
            placement="bottom-start"
          />
          {errors.status && <span style={{ color: '#ff4d4f', fontSize: '12px', marginTop: '4px', display: 'block' }}>{errors.status}</span>}
        </div>
        <div>
          <DatePicker
            selectedDate={localProject?.due_date ? new Date(localProject.due_date) : null}
            onChange={handleDueDateChange}
            label="Due Date"
            id="project-due-date"
            onOpenChange={handleDatePickerOpenChange}
            icon={<Calendar12 />}
            placement="bottom-start"
            showClearDate={true}
          />
          {errors.due_date && <span style={{ color: '#ff4d4f', fontSize: '12px', marginTop: '4px', display: 'block' }}>{errors.due_date}</span>}
        </div>
      </ProjectMetaWrapper>
      {renderSummaryCards()}
      <PageContent>
        <Section>
          <Tabs
            tabs={[
              { id: "overview", label: "Overview" },
              { id: "invoices", label: "Invoices" },
              { id: "expenses", label: "Expenses" },
              { id: "tasks", label: "Tasks" },
              { id: "time-entries", label: "Time" },
            ]}
            activeTab={activeTab}
            onTabChange={(tabId) => setActiveTab(tabId as typeof activeTab)}
          />

          <TabContent>
            {activeTab === "overview" && (
              <OverviewContent>
                <NotesEditor
                  key={localProject?.id}
                  initialContent={localDescription}
                  onUpdate={handleDescriptionChange}
                  padding="32px 76px 320px 76px"
                  minHeight="400px"
                />
              </OverviewContent>
            )}

            {activeTab === "invoices" && (
              <>
                <InvoiceList
                  invoices={projectInvoices}
                  isLoading={isLoadingInvoices}
                  onCreateInvoice={handleCreateInvoice}
                  containerRef={containerRef}
                  onSelectionChange={handleInvoiceSelectionChange}
                  onRowClick={handleInvoiceRowClick}
                  selectedInvoiceId={selectedInvoices.length === 1 ? selectedInvoices[0] : null}
                  sorting={[]}
                  onSortingChange={() => {}}
                />
                <SelectedInvoicesOverlay
                  selectedCount={selectedInvoices.length}
                  onDelete={handleBulkDeleteInvoices}
                  onClearSelection={() => setSelectedInvoices([])}
                  onExportCSV={handleExportInvoicesCSV}
                />
              </>
            )}

            {activeTab === "expenses" && (
              <ExpenseList
                expenses={localExpenses}
                isLoading={isLoadingExpenses}
                onCreateExpense={() => setIsExpenseDrawerOpen(true)}
                onEditExpense={handleEditExpense}
                containerRef={containerRef}
                onSelectionChange={handleExpenseSelectionChange}
                sorting={[]}
                onSortingChange={() => {}}
                columns={expenseColumns}
              />
            )}

            {activeTab === "tasks" && (
              <TaskTable
                tasks={localTasks}
                columns={taskColumns}
                isLoading={isLoadingTasks || isInitialMount.current}
                onRowClick={handleTaskClick}
                onImmediateUpdate={updateTask}
                getRowKey={(task) => task.id}
                clients={clients || []}
                projects={projects || []}
                organizationUsers={organizationUsers || []}
                taskStatuses={taskStatuses || []}
                selectedTaskId={selectedTaskId}
                onTasksUpdate={handleTasksUpdate}
                containerRef={containerRef}
                emptyState={taskEmptyState}
                onDeleteTask={handleDeleteTask}
              />
            )}

            {activeTab === "time-entries" && timeEntryTableContent}
          </TabContent>
        </Section>
      </PageContent>

      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={handleCloseExpenseDrawer}
        expense={editingExpense}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        onCreate={handleCreateExpense}
        organizationId={organizationId}
        updateLocalExpense={updateLocalExpense}
      />

      <TaskDrawer
        isOpen={isTaskDrawerOpen}
        setIsOpen={setIsTaskDrawerOpen}
        selectedTask={localTasks.find(t => t.id === selectedTaskId) || null}
        updateTask={updateTask}
        updateLocalTask={updateLocalTask}
        clients={clients || []}
        projects={projects || []}
        organizationUsers={organizationUsers || []}
        taskStatuses={taskStatuses || []}
        onDeleteTask={handleDeleteTask}
        autoFocus={true}
      />

      <TimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultProjectId={id}
        defaultClientId={localProject?.client_id || undefined}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        stopTimer={stopTimer}
        updateLocalTimeEntry={updateLocalTimeEntry}
        currentTimeEntryId={currentTimeEntryId}
        pauseTimer={pauseTimer}
        resumeTimer={resumeTimer}
      />

      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={clients || []}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]} // You might need to fetch payments for the project
            emailTrackingData={emailTrackingData}
          />
        )}
      </InvoiceDrawer>

      <SelectedTimeEntriesOverlay
        selectedCount={selectedEntriesCount}
        clients={[]}
        projects={projects || []}
        onClientChange={handleBulkClientChange}
        onProjectChange={handleBulkProjectChange}
        onDelete={handleBulkDelete}
        onClearSelection={() => {
          setSelectedEntries([]);
          setSelectedEntriesCount(0);
        }}
        onCreateInvoice={handleCreateInvoice}
      />
      <SelectedExpensesOverlay
        selectedCount={selectedExpenses.length}
        onDelete={handleBulkDeleteExpenses}
        onClearSelection={() => setSelectedExpenses([])}
        onExportCSV={handleExportExpenses}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete Project"
        message={`Are you sure you want to delete ${localProject?.name || 'this project'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        isDestructive
      />
      <ConfirmationModal
        isOpen={isDeleteInvoicesModalOpen}
        title="Delete Invoices"
        message={`Are you sure you want to delete ${selectedInvoices.length} invoice${selectedInvoices.length === 1 ? '' : 's'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDeleteInvoices}
        onCancel={() => setIsDeleteInvoicesModalOpen(false)}
        isDestructive
      />
    </ProjectPageWrapper>
  );
};

export default ProjectPage;
