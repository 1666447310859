import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { 
  useReactTable, 
  getCoreRowModel, 
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef, 
  flexRender,
  SortingState,
  RowSelectionState,
  Row,
  VisibilityState,
  OnChangeFn,
} from '@tanstack/react-table';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { Up8, Down8, Checkmark8 } from '../shared/components/Icon';
import { Filter } from '../types';
import EmptyState from './EmptyState';

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  display: flex;
  box-shadow: 0 1px 0 rgba(0,0,0,0.05);
  background: linear-gradient(to bottom, rgba(248, 247, 246, 1) 10%, rgba(248, 247, 246, 0.5) 100%);
  position: sticky;
  top: 60px;
  backdrop-filter: blur(12px);
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  user-select: none;
  z-index: 1;
`;

const HeaderSelector = styled.div`
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  &:hover {
    ${HeaderSelector} {
      opacity: 1;
    }
  }
`;

const SortIcon = styled.span<{ $align?: 'left' | 'right' | 'center' }>`
  display: inline-flex;
  align-items: center;
  margin-left: ${props => props.$align === 'right' ? '0' : '8px'};
  margin-right: ${props => props.$align === 'right' ? '8px' : '0'};
  
  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const HeaderCell = styled.div<{ $align?: 'left' | 'right' | 'center'; $isFirst?: boolean; $isLast?: boolean }>`
  flex: 1;
  padding: 10px 12px;
  margin: 4px 0;
  width: fit-content;
  text-align: ${props => props.$align || 'left'};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: ${props => props.$align === 'right' ? 'flex-end' : 'flex-start'};
  margin-left: ${props => props.$isFirst ? '28px' : '8px'};
  margin-right: ${props => props.$isLast ? '28px' : '8px'};

  &:hover {
    background-color: rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.8);

    ${SortIcon} svg path {
      fill: rgba(0,0,0,0.8);
    }
  }
`;

const TableRow = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  cursor: pointer;
  position: relative;
  opacity: ${props => props.$isDisabled ? 0.5 : 1};
  background-color: ${props => props.$isDisabled ? 'rgba(0,0,0,0.03)' : 'transparent'};
  font-size: 14px;
  &:hover {
    background-color: rgba(0,0,0,0.025);

    .row-selector {
      opacity: ${props => props.$isDisabled ? 0 : 1};
    }
  }
`;

const TableCell = styled.div<{ $align?: 'left' | 'right' | 'center'; $isFirst?: boolean; $isLast?: boolean }>`
  flex: 1;
  padding: 12px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  overflow: hidden;
  white-space: nowrap;
  font-feature-settings: 'tnum';
  text-align: ${props => props.$align || 'left'};
  padding-left: ${props => props.$isFirst ? '40px' : '20px'};
  padding-right: ${props => props.$isLast ? '40px' : '20px'};
`;

const TableCellContent = styled.div<{ $align?: 'left' | 'right' | 'center' }>`
  text-align: ${props => props.$align || 'left'};
  width: 100%;
`;

const RowSelector = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => props.$isSelected ? 1 : 0};
  z-index: 1;
`;

const GroupRow = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  align-items: center;

  &:hover {
    background-color: rgba(0,0,0,0.05);

    .group-selector {
      opacity: 1;
    }
  }
`;

const GroupCell = styled.div`
  flex: 1;
  padding: 10px 40px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const GroupSelector = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => props.$isSelected ? 1 : 0};
  z-index: 1;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 400px;
`;

const StyledCheckbox = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.$isSelected ? '#000' : 'rgba(0, 0, 0, 0.2)'};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$isSelected ? '#000' : 'transparent'};

  svg {
    display: ${props => props.$isSelected ? 'block' : 'none'};
    path {
      fill: #fff;
    }
  }
`;

const CheckboxContainer = styled.div<{ $isSelected: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover ${StyledCheckbox} {
    border: solid 1px ${props => props.$isSelected ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  }
`;

interface ColumnVisibility {
  breakpoint: number;
  hidden: string[];
}

interface ExtendedColumnDef<T> extends Omit<ColumnDef<T>, 'cell' | 'header'> {
  align?: 'left' | 'right' | 'center';
  cell?: ColumnDef<T>['cell'];
  header?: ColumnDef<T>['header'];
}

interface DataTableProps<T> {
  data: T[];
  columns: ExtendedColumnDef<T>[];
  isLoading: boolean;
  onRowClick: (item: T) => void;
  onRowHover?: (item: T) => void;
  getRowKey: (row: T) => string;
  groupBy?: keyof T;
  onSelectionChange: (selectedIds: string[]) => void;
  selectedRows?: string[];
  isRowSelectable?: (row: T) => boolean;
  rowClassName?: string;
  columnVisibility?: ColumnVisibility[];
  containerRef: React.RefObject<HTMLDivElement>;
  emptyState?: {
    icon: React.ReactNode;
    message: string;
    subMessage: string;
    action?: {
      label: string;
      onClick: () => void;
    };
  };
  filters?: Filter[];
  renderGroupFooter?: () => React.ReactNode; // Add this line
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

function DataTable<T>({
  data,
  columns,
  isLoading,
  onRowClick,
  onRowHover,
  getRowKey,
  groupBy,
  onSelectionChange,
  selectedRows,
  isRowSelectable,
  rowClassName,
  columnVisibility,
  containerRef,
  emptyState,
  renderGroupFooter, // Add this line
  sorting,
  onSortingChange,
}: DataTableProps<T>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibilityState, setColumnVisibilityState] = useState<VisibilityState>({});
  const windowWidth = useWindowWidth();

  // Update column visibility based on window width
  useEffect(() => {
    if (columnVisibility) {
      const newVisibility: VisibilityState = {};
      columnVisibility.forEach(({ breakpoint, hidden }) => {
        if (windowWidth <= breakpoint) {
          hidden.forEach(columnId => {
            newVisibility[columnId] = false;
          });
        }
      });
      setColumnVisibilityState(newVisibility);
    }
  }, [windowWidth, columnVisibility]);

  // Update internal state when selectedRows prop changes
  useEffect(() => {
    if (selectedRows) {
      const newRowSelection: RowSelectionState = {};
      selectedRows.forEach(id => {
        newRowSelection[id] = true;
      });
      setRowSelection(newRowSelection);
    }
  }, [selectedRows]);

  const table = useReactTable({
    data,
    columns: columns as ColumnDef<T, unknown>[],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: (row) => isRowSelectable ? isRowSelectable(row.original) : true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (updater) => {
      const newRowSelection = typeof updater === 'function' ? updater(rowSelection) : updater;
      setRowSelection(newRowSelection);
      const selectedIds = Object.keys(newRowSelection).filter(id => newRowSelection[id]);
      onSelectionChange(selectedIds);
    },
    state: {
      sorting: sorting || [],
      rowSelection,
      columnVisibility: columnVisibilityState,
    },
    onColumnVisibilityChange: setColumnVisibilityState,
    getRowId: getRowKey,
    onSortingChange: onSortingChange,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    estimateSize: useCallback((index) => {
      const row = rows[index];
      const showGroupHeader = groupBy && (index === 0 || row.original[groupBy] !== rows[index - 1]?.original[groupBy]);
      const showGroupFooter = groupBy && renderGroupFooter && (index === rows.length - 1 || row.original[groupBy] !== rows[index + 1]?.original[groupBy]);
      let size = 42; // Default row height
      if (showGroupHeader) size += 36; // Add group header height
      if (showGroupFooter) size += 32; // Changed from 16 to 32
      return size;
    }, [rows, groupBy, renderGroupFooter]),
    overscan: 10,
    getItemKey: useCallback((index: number) => rows[index].id, [rows]),
  });

  const getGroupRows = useCallback((groupValue: T[keyof T]): Row<T>[] => {
    return table.getRowModel().rows.filter(row => groupBy && row.original[groupBy] === groupValue);
  }, [table, groupBy]);

  const isGroupSelected = useCallback((groupValue: T[keyof T]): boolean => {
    const groupRows = getGroupRows(groupValue);
    return groupRows.every(row => row.getIsSelected());
  }, [getGroupRows]);

  const toggleGroupSelection = useCallback((groupValue: T[keyof T]) => {
    const groupRows = getGroupRows(groupValue);
    const shouldSelect = !isGroupSelected(groupValue);
    
    // Create a new selection state
    const newRowSelection = { ...rowSelection };
    
    // Update the selection state for all rows in the group
    groupRows.forEach(row => {
      newRowSelection[row.id] = shouldSelect;
    });
    
    // Update the table's row selection state
    table.setRowSelection(newRowSelection);
    
    // Notify parent component of the selection change
    const selectedIds = Object.keys(newRowSelection).filter(id => newRowSelection[id]);
    onSelectionChange(selectedIds);
  }, [getGroupRows, isGroupSelected, rowSelection, table, onSelectionChange]);

  const renderRow = useCallback((virtualRow: VirtualItem) => {
    const row = rows[virtualRow.index];
    const rowGroup = groupBy ? row.original[groupBy] : null;
    const showGroupHeader = groupBy && (virtualRow.index === 0 || row.original[groupBy] !== rows[virtualRow.index - 1].original[groupBy]);
    const showGroupFooter = groupBy && renderGroupFooter && (virtualRow.index === rows.length - 1 || row.original[groupBy] !== rows[virtualRow.index + 1]?.original[groupBy]);
    const isRowDisabled = isRowSelectable ? !isRowSelectable(row.original) : false;

    const groupHeaderHeight = 36;
    const groupFooterHeight = 32; // Changed from 16 to 32

    let offsetY = virtualRow.start;
    if (showGroupHeader) {
      offsetY += groupHeaderHeight;
    }

    return (
      <div
        key={`${row.id}-${virtualRow.index}`}
        data-index={virtualRow.index}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${offsetY}px)`,
        }}
      >
        {showGroupHeader && groupBy && rowGroup !== null && (
          <GroupRow 
            onClick={() => toggleGroupSelection(rowGroup)}
            style={{
              position: 'absolute',
              top: -groupHeaderHeight,
              left: 0,
              width: '100%',
            }}
          >
            <GroupSelector 
              className="group-selector"
              $isSelected={isGroupSelected(rowGroup)}
            >
              <CheckboxContainer
                $isSelected={isGroupSelected(rowGroup)}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleGroupSelection(rowGroup);
                }}
              >
                <StyledCheckbox $isSelected={isGroupSelected(rowGroup)}>
                  <Checkmark8 />
                </StyledCheckbox>
              </CheckboxContainer>
            </GroupSelector>
            <GroupCell>{String(rowGroup)}</GroupCell>
          </GroupRow>
        )}
        <TableRow
          className={`time-entry-item ${rowClassName || ''}`}
          onClick={(e) => {
            e.stopPropagation();
            if (onRowClick) onRowClick(row.original);
          }}
          onMouseEnter={() => onRowHover && onRowHover(row.original)}
          $isDisabled={isRowDisabled}
        >
          <RowSelector 
            className="row-selector"
            $isSelected={row.getIsSelected()}
          >
            <CheckboxContainer
              $isSelected={row.getIsSelected()}
              onClick={(e) => {
                e.stopPropagation();
                row.toggleSelected();
              }}
            >
              <StyledCheckbox $isSelected={row.getIsSelected()}>
                <Checkmark8 />
              </StyledCheckbox>
            </CheckboxContainer>
          </RowSelector>
          {row.getVisibleCells().map((cell, cellIndex) => {
            const columnDef = cell.column.columnDef as ExtendedColumnDef<T>;
            return (
              <TableCell 
                key={cell.id} 
                $align={columnDef.align}
                $isFirst={cellIndex === 0}
                $isLast={cellIndex === row.getVisibleCells().length - 1}
              >
                <TableCellContent $align={columnDef.align}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellContent>
              </TableCell>
            );
          })}
        </TableRow>
        {showGroupFooter && renderGroupFooter && (
          <div style={{ height: groupFooterHeight }}>{renderGroupFooter()}</div>
        )}
      </div>
    );
  }, [rows, groupBy, isRowSelectable, onRowClick, onRowHover, toggleGroupSelection, isGroupSelected, rowClassName, renderGroupFooter]);

  if (isLoading) {
    return (
      <TableContainer>
        <TableHeader>
          <HeaderRow>
            <HeaderSelector>
              <CheckboxContainer
                $isSelected={false}
                onClick={(e) => e.stopPropagation()}
              >
                <StyledCheckbox $isSelected={false}>
                  <Checkmark8 />
                </StyledCheckbox>
              </CheckboxContainer>
            </HeaderSelector>
            {table.getHeaderGroups().map(headerGroup => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  const columnDef = header.column.columnDef as ExtendedColumnDef<T>;
                  return (
                    <HeaderCell 
                      key={header.id} 
                      $align={columnDef.align}
                      $isFirst={index === 0}
                      $isLast={index === headerGroup.headers.length - 1}
                    >
                      {columnDef.align === 'right' && (
                        <SortIcon $align={columnDef.align}>
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                          ) : null}
                        </SortIcon>
                      )}
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {columnDef.align !== 'right' && (
                        <SortIcon $align={columnDef.align}>
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                          ) : null}
                        </SortIcon>
                      )}
                    </HeaderCell>
                  );
                })}
              </React.Fragment>
            ))}
          </HeaderRow>
        </TableHeader>
      </TableContainer>
    );
  }

  if (!data || !columns) {
    console.error('data or columns is undefined');
    return <div>Error: Unable to display data</div>;
  }

  if (data.length === 0) {
    return (
      <TableContainer>
        <TableHeader>
          <HeaderRow>
            {table.getHeaderGroups().map(headerGroup => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  const columnDef = header.column.columnDef as ExtendedColumnDef<T>;
                  return (
                    <HeaderCell 
                      key={header.id} 
                      onClick={header.column.getToggleSortingHandler()}
                      $align={columnDef.align}
                      $isFirst={index === 0}
                      $isLast={index === headerGroup.headers.length - 1}
                    >
                      {columnDef.align === 'right' && (
                        <SortIcon $align={columnDef.align}>
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                          ) : null}
                        </SortIcon>
                      )}
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {columnDef.align !== 'right' && (
                        <SortIcon $align={columnDef.align}>
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                          ) : null}
                        </SortIcon>
                      )}
                    </HeaderCell>
                  );
                })}
              </React.Fragment>
            ))}
          </HeaderRow>
        </TableHeader>
        <EmptyStateWrapper>
          {emptyState ? (
            <EmptyState
              icon={emptyState.icon}
              message={emptyState.message}
              subMessage={emptyState.subMessage}
              action={emptyState.action}
            />
          ) : (
            <div>No data available</div>
          )}
        </EmptyStateWrapper>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <TableHeader>
        <HeaderRow>
          <HeaderSelector>
            <CheckboxContainer
              $isSelected={table.getIsAllPageRowsSelected()}
              onClick={(e) => {
                e.stopPropagation();
                table.toggleAllPageRowsSelected();
              }}
            >
              <StyledCheckbox $isSelected={table.getIsAllPageRowsSelected()}>
                <Checkmark8 />
              </StyledCheckbox>
            </CheckboxContainer>
          </HeaderSelector>
          {table.getHeaderGroups().map(headerGroup => (
            <React.Fragment key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const columnDef = header.column.columnDef as ExtendedColumnDef<T>;
                return (
                  <HeaderCell 
                    key={header.id} 
                    onClick={header.column.getToggleSortingHandler()}
                    $align={columnDef.align}
                    $isFirst={index === 0}
                    $isLast={index === headerGroup.headers.length - 1}
                  >
                    {columnDef.align === 'right' && (
                      <SortIcon $align={columnDef.align}>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                        ) : null}
                      </SortIcon>
                    )}
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {columnDef.align !== 'right' && (
                      <SortIcon $align={columnDef.align}>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? <Down8 /> : <Up8 />
                        ) : null}
                      </SortIcon>
                    )}
                  </HeaderCell>
                );
              })}
            </React.Fragment>
          ))}
        </HeaderRow>
      </TableHeader>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map(renderRow)}
      </div>
    </TableContainer>
  );
}

export default React.memo(DataTable) as <T>(props: DataTableProps<T>) => React.ReactElement;