import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { 
  useReactTable, 
  getCoreRowModel, 
  getFilteredRowModel,
  ColumnDef, 
  RowSelectionState,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Task, Client, Project, OrganizationUser, TaskStatus, GroupHeader } from '../../../types';
import TaskComponent from './TaskComponent';
import SelectedTasksOverlay from './SelectedTasksOverlay';
import { useTaskMutations } from '../hooks/useTaskMutations';
import EmptyState from '../../../components/EmptyState';
import { Checkmark8 } from '../../../shared/components/Icon';

const StyledCheckbox = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.$isSelected ? '#000' : 'rgba(0, 0, 0, 0.2)'};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$isSelected ? '#000' : 'transparent'};

  svg {
    display: ${props => props.$isSelected ? 'block' : 'none'};
    path {
      fill: #fff;
    }
  }
`;

const CheckboxContainer = styled.div<{ $isSelected: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover ${StyledCheckbox} {
    border: solid 1px ${props => props.$isSelected ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  }
`;

const TableContainer = styled.div`
  width: 100%;
`;

const VirtualizedRow = styled.div<{ $top: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${props => props.$top}px);
  z-index: 1;

  &:hover {
    z-index: 2;
  }
`;

const TableRow = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  &:hover {
    .row-selector {
      opacity: 1;
    }
  }
`;

const RowSelector = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => props.$isSelected ? 1 : 0};
`;

const GroupHeaderRow = styled.div`
  padding: 16px 40px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  gap: 8px;
`;

const GroupHeaderText = styled.span``;

const TaskCount = styled.span`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  font-feature-settings: 'tnum';
  font-size: 12px;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

interface TaskTableProps {
  tasks: Task[];
  columns: ColumnDef<Task>[];
  isLoading: boolean;
  onRowClick: (task: Task) => void;
  onImmediateUpdate: (updatedTask: Partial<Task>, isEditing?: boolean) => void;
  getRowKey: (task: Task) => string;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  selectedTaskId: string | null;
  onTasksUpdate: (updatedTasks: Task[]) => void;
  taskStatuses: TaskStatus[];
  containerRef: React.RefObject<HTMLDivElement>;
  emptyState: {
    icon: React.ReactNode;
    message: string;
    subMessage: string;
    action: {
      label: string;
      onClick: () => void;
    };
  };
  onDeleteTask: (taskId: string) => void;
}

const TaskTable: React.FC<TaskTableProps> = ({ 
  tasks, 
  columns, 
  isLoading, 
  onRowClick, 
  onImmediateUpdate,
  getRowKey,
  clients,
  projects,
  organizationUsers,
  selectedTaskId,
  onTasksUpdate,
  taskStatuses,
  containerRef,
  emptyState,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const groupedTasks = useMemo(() => {
    const grouped: { [key: string]: Task[] } = {};
    taskStatuses.forEach(status => {
      grouped[status.name] = tasks.filter(task => task.status_id === status.id);
    });
    return grouped;
  }, [tasks, taskStatuses]);

  const flattenedTasks = useMemo(() => 
    Object.entries(groupedTasks).flatMap(([group, groupTasks]) => 
      groupTasks.length > 0 ? [{ id: group, isGroupHeader: true, count: groupTasks.length } as GroupHeader, ...groupTasks] : []
    ),
    [groupedTasks]
  );

  const table = useReactTable({
    data: flattenedTasks,
    columns: columns as ColumnDef<Task | GroupHeader>[],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    getRowId: (row) => 'isGroupHeader' in row ? row.id : getRowKey(row as Task),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    estimateSize: useCallback((index) => {
      const row = rows[index];
      return 'isGroupHeader' in row.original ? 50 : 44; // Increase group header height
    }, [rows]),
    overscan: 5,
  });

  const selectedTasksCount = Object.keys(rowSelection).filter(id => rowSelection[id]).length;

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    selectedIds.forEach(id => {
      onImmediateUpdate({ id, client_id: clientId });
    });
  }, [rowSelection, onImmediateUpdate]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    selectedIds.forEach(id => {
      onImmediateUpdate({ id, project_id: projectId });
    });
  }, [rowSelection, onImmediateUpdate]);

  const handleBulkAssigneeChange = useCallback((userId: string | null) => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    selectedIds.forEach(id => {
      onImmediateUpdate({ id, user_id: userId });
    });
  }, [rowSelection, onImmediateUpdate]);

  const { deleteTaskMutation } = useTaskMutations(); // Replace useDeleteTaskMutation with this

  const handleBulkDelete = useCallback(() => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    if (selectedIds.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedIds.length} task(s)?`)) {
      Promise.all(selectedIds.map(id => 
        deleteTaskMutation.mutateAsync(id)
      )).then(() => {
        const updatedTasks = tasks.filter(task => !selectedIds.includes(task.id));
        onTasksUpdate(updatedTasks);
        setRowSelection({});
      }).catch((error) => {
        console.error('Failed to delete tasks:', error);
      });
    }
  }, [rowSelection, deleteTaskMutation, tasks, onTasksUpdate]);

  const handleClearSelection = useCallback(() => {
    setRowSelection({});
  }, []);

  if (!tasks || !columns) {
    console.error('tasks or columns is undefined');
    return <div>Error: Unable to display tasks</div>;
  }

  // Only show empty state if we're not loading and actually have no tasks
  if (!isLoading && tasks.length === 0) {
    return (
      <EmptyStateWrapper>
        <EmptyState
          icon={emptyState.icon}
          message={emptyState.message}
          subMessage={emptyState.subMessage}
          action={emptyState.action}
        />
      </EmptyStateWrapper>
    );
  }

  return (
    <>
      <TableContainer>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];
            const isGroupHeader = 'isGroupHeader' in row.original;

            return (
              <VirtualizedRow
                key={row.id}
                data-index={virtualRow.index}
                $top={virtualRow.start}
              >
                {isGroupHeader ? (
                  <GroupHeaderRow>
                    <GroupHeaderText>{(row.original as GroupHeader).id}</GroupHeaderText>
                    <TaskCount>{(row.original as GroupHeader).count}</TaskCount>
                  </GroupHeaderRow>
                ) : (
                  <TableRow onClick={() => onRowClick && onRowClick(row.original as Task)}>
                    <RowSelector
                      className="row-selector"
                      $isSelected={row.getIsSelected()}
                    >
                      <CheckboxContainer
                        $isSelected={row.getIsSelected()}
                        onClick={(e) => {
                          e.stopPropagation();
                          row.toggleSelected();
                        }}
                      >
                        <StyledCheckbox $isSelected={row.getIsSelected()}>
                          <Checkmark8 />
                        </StyledCheckbox>
                      </CheckboxContainer>
                    </RowSelector>
                    <TaskComponent
                      task={row.original as Task}
                      onImmediateUpdate={onImmediateUpdate}
                      isSelected={(row.original as Task).id === selectedTaskId}
                      clients={clients}
                      projects={projects}
                      organizationUsers={organizationUsers}
                      taskStatuses={taskStatuses}
                      onClick={() => onRowClick && onRowClick(row.original as Task)}
                    />
                  </TableRow>
                )}
              </VirtualizedRow>
            );
          })}
        </div>
      </TableContainer>
      {selectedTasksCount > 0 && (
        <SelectedTasksOverlay
          selectedCount={selectedTasksCount}
          clients={clients}
          projects={projects}
          organizationUsers={organizationUsers}
          onClientChange={handleBulkClientChange}
          onProjectChange={handleBulkProjectChange}
          onAssigneeChange={handleBulkAssigneeChange}
          onDelete={handleBulkDelete}
          onClearSelection={handleClearSelection}
        />
      )}
    </>
  );
};

export default React.memo(TaskTable);
