import { useMutation } from '@tanstack/react-query';
import { useOrganization } from '../../../hooks';
import { supabase } from '../../../supabaseClient';

interface GenerateTimeEntryResponse {
  description: string;
  start_time: string;
  end_time: string;
  client?: {
    id: string;
    name: string;
  };
  project?: {
    id: string;
    name: string;
  };
  service?: {
    id: string;
    name: string;
  };
}

export function useGenerateTimeEntry() {
  const { data: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async (prompt: string) => {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.access_token) {
        throw new Error('No access token available');
      }

      const response = await fetch('/api/ai/generate-time-entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.session.access_token}`,
        },
        body: JSON.stringify({
          prompt,
          organizationId,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to generate time entry');
      }

      return response.json() as Promise<GenerateTimeEntryResponse>;
    },
  });
} 