import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Filter12, Client12, Project12, Profile12, Calendar12, Status12 } from "../shared/components/Icon";
import { FilterId } from "../types"; // Add this import

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const InputPreview = styled.div`
  padding: 4px 8px;
  display: inline-flex;
  gap: 8px;
  border-radius: 8px;
  align-items: center;
  position: relative;
  width: fit-content;
  height: auto;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }

  &:active {
    transform: scale(0.99);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;

`;

const PopoverContainer = styled.div`
  z-index: 2000;
  pointer-events: auto;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  pointer-events: auto;
`;

const Option = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  svg path {
    fill: rgba(0, 0, 0, 0.8);
  }
`;

interface FilterOption {
  id: FilterId;
  label: string;
  type: 'multipleEntity' | 'dateRange' | 'select';
  options?: string[];
}

interface FilterPickerProps {
  options: FilterOption[];
  onAddFilter: (filterId: FilterId) => void;
  activeFilters: string[];
  placement?: Placement;
}

const FilterPicker: React.FC<FilterPickerProps> = ({
  options,
  onAddFilter,
  activeFilters,
  placement = "bottom-start",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    toggle: true,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const handleOptionClick = (filterId: string) => {
    onAddFilter(filterId as FilterId);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  const availableOptions = options.filter(option => !activeFilters.includes(option.id));

  const getIconForFilter = (filterId: FilterId) => {
    switch (filterId) {
      case 'client':
        return <Client12 />;
      case 'project':
        return <Project12 />;
      case 'user':
        return <Profile12 />;
      case 'date':
        return <Calendar12 />;
      default:
        return <Status12 />;
    }
  };

  return (
    <PickerWrapper>
      <InputPreview 
        ref={refs.setReference} 
        {...getReferenceProps()} 
      >
        <IconWrapper>
          <Filter12 />
        </IconWrapper>
        Filter
      </InputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="filter-picker-popover"
          >
            <PopoverContent 
              style={styles} 
              $transformOrigin={transformOrigin}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {availableOptions.map((option) => (
                <Option
                  key={option.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(option.id);
                  }}
                >
                  <OptionIcon>{getIconForFilter(option.id)}</OptionIcon>
                  <span>{option.label}</span>
                </Option>
              ))}
              {availableOptions.length === 0 && (
                <Option as="div" style={{ cursor: 'default', opacity: 0.5 }}>
                  <span>No more filters available</span>
                </Option>
              )}
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </PickerWrapper>
  );
};

export default FilterPicker;